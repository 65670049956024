<template>
<div class="box">
<!--  <h1>新闻详情</h1>-->
  <div class="bluethinline"></div>

  <div class="newstitle">{{ newtitle }}</div>
  <div class="subtitle">{{newsubtitle}}</div>
  <div class="newauthor" v-if="newsauthor">作者：{{newsauthor}}</div>
  <div class="newdate">{{newdate}}</div>


<!--  <template v-for="text in newdetails" >-->
<!--    <p >  {{text}}</p>-->
<!--  </template>-->
  <p   v-html="newsdetails"> </p>

  <backtop v-show="backtopflag">  </backtop>
</div>

  <!-- ------------------- v-model sync v-model-------------:default-current-page="queryPages.pagenumber"------------------------ hide-on-single-page total, total small-->
  <el-row :gutter="20 "  type="flex">
    <el-col :span="5" :offset="10">
      <el-pagination  background layout="  prev, pager, next,"
                      v-model:current-page.sync="DisplayPages.Currpagenumber"
                      v-model:page-size="DisplayPages.PageCount"
                     :page-sizes="[50]"
                     :total="DisplayPages.TotalPags"
                      hide-on-single-page
                     @size-change="handleSizePagesChange"
                     @current-change="handleCurrentPageChange"
                      v-if="DisplayPages.TotalPags!=0"
      />

    </el-col>
    <el-col :span="2" :offset="1">
    <el-button           @click="PrevpageKey()"  >返回新闻中心</el-button>
    </el-col>
  </el-row>
  <!-- size="small" type="primary"-------------------------------------------------------- -->

<!--  <el-button size="small"     type="primary"     @click="PrevpageKey()"  >测试</el-button>-->
</template>

<script>
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";
import {onMounted,reactive, ref} from "vue";

import backtop from "@/components/backtop/backtop";

export default {
  name: "newdetails",
  components: {
    backtop,
  },
  setup(){
    let backtopflag=ref(0);
    let newsdetaildata;
    let newtitle=ref();
    let newsubtitle=ref();
    let newdate=ref();
    let newsauthor=ref();
    let newsdetails=ref();

    const getqueryroute=useRoute();
    const goPrevpag=useRouter();

    const DisplayPages=reactive({
      Currpagenumber:'',  //1
      PageCount:'',    //5
      TotalPags:'',
    });

    const queryPages=reactive({
      newstype:'1',
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });

    let Newtotal=ref(0);

    const gsnewsimformation=reactive({

      newstitle:  [],     //标题
      newsauthor: [],       //作者
      newdate:    [],      //日期
      newsubtitle:[],       //副标题
      newdetails: [],       //新闻内容
    });

    onMounted(()=>{

      // console.log(getqueryroute.query.detail.picimgs[getqueryroute.query.page]);
      // console.log(typeof(getqueryroute.query.detail));
      // console.log(JSON.parse(decodeURIComponent(getqueryroute.query.detail)));
      // console.log(typeof(detailData));
      newsdetaildata=JSON.parse(decodeURIComponent(getqueryroute.query.newdetail));

      // console.log(newsdetaildata);
      gsnewsimformation.newstitle=newsdetaildata.newstitle;
      gsnewsimformation.newsauthor=newsdetaildata.newsauthor;
      gsnewsimformation.newdate=newsdetaildata.newdate;
      gsnewsimformation.newsubtitle=newsdetaildata.newsubtitle;
      gsnewsimformation.newdetails=newsdetaildata.newdetails;

      queryPages.pagesize=parseInt(getqueryroute.query.currpage);
      // console.log(newsdetaildata.newstitle.length,'------------2222222');

      queryPages.pagesize=parseInt(getqueryroute.query.page);
      queryPages.pagenumber=parseInt(getqueryroute.query.item);
      queryPages.newstype=getqueryroute.query.newstype;

      Newtotal.value=parseInt(getqueryroute.query.page);
      DisplayPages.PageCount=1;     //5 每页的条数是5 条
      DisplayPages.TotalPags=newsdetaildata.newstitle.length;//queryPages.pagesize;//Newtotal.value;    //10 一共是10条数量据  Newtotal.value*10;
      DisplayPages.Currpagenumber=queryPages.pagenumber+1;//2  当前显示为第二页面

      // console.log(DisplayPages.Currpagenumber,'1111111');
      // console.log(DisplayPages.PageCount,'2222222');
      // console.log(DisplayPages.TotalPags,'33333333');
      // console.log(queryPages.pagenumber,'1111111');
      // console.log(queryPages.pagesize,'2222222');
      // console.log(queryPages.pagenumber,'33333333');

      initdisplay((queryPages.pagenumber));

      // console.log(gsnewsimformation.newstitle);queryPages.pagenumber
      //  console.log(queryPages.pagenumber,'onMounted   ======>   ');

      // newtitle.value=newsdetaildata.newstitle[getqueryroute.query.page][getqueryroute.query.item];
      // newsubtitle.value=newsdetaildata.newsubtitle[getqueryroute.query.page][getqueryroute.query.item];
      // newdate.value=newsdetaildata.newdate[getqueryroute.query.page][getqueryroute.query.item];

      // newdetails.value=newsdetaildata.newdetails[getqueryroute.query.page][getqueryroute.query.item];
      //找到换行符将字符切成几段字符串数组  '\n'
      // newdetails.value=newsdetaildata.newdetails[getqueryroute.query.page][getqueryroute.query.item].split('\n');
      // newdetails.value=newsdetaildata.newdetails[getqueryroute.query.page][getqueryroute.query.item];
      // console.log(newdetails.value);
    });

    function initdisplay(ArrarCount){
      //queryPages.pagenumbe
      newtitle.value=gsnewsimformation.newstitle[ArrarCount];
      newsubtitle.value=gsnewsimformation.newsubtitle[ArrarCount];
      newdate.value=gsnewsimformation.newdate[ArrarCount];
      newsdetails.value=gsnewsimformation.newdetails[ArrarCount];
      newsauthor.value=gsnewsimformation.newsauthor[ArrarCount];

      //console.log(queryPages.pagesize,queryPages.pagenumber,Newtotal.value);
      // console.log(newtitle.value);
      // console.log(gsnewsimformation.newstitle[queryPages.pagenumber],'==========================>');
    }
    //==================================================================
    //监听滚动屏像素
    window.addEventListener('scroll',()=>{
      // console.log('   ---');
      // 页面滚动距顶部距离
      let scrollTop =
          // window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      // console.log(scrollTop)

      if(scrollTop>300){   //大于380个像素时出现返回顶部按键
        backtopflag.value=true;//backtop enable
      }else {
        backtopflag.value=false;
      }

    }),true
    //======================================================================================
     function handleSizePagesChange(){
       // console.log(queryPages.pagenumber,'=====================',queryPages.pagesize);
     }
     function handleCurrentPageChange(){
         // console.log(queryPages.pagenumber,'------------------',queryPages.pagesize);
       queryPages.pagenumber=DisplayPages.Currpagenumber-1;
       initdisplay((queryPages.pagenumber));
     }
     //返回上一页
     function PrevpageKey(){
       // handleCurrentPageChange();
       // console.log(queryPages.pagenumber,'------------------',queryPages.pagesize);
       // Newtotal.value=4*10;
       // getqueryroute.push({ path:'/computed'})
       goPrevpag.push({ path:'/news'});
     }

    return {
      backtopflag,
      newtitle,newsubtitle,newdate,newsdetails,newsauthor,DisplayPages,
      Newtotal,queryPages,handleSizePagesChange,handleCurrentPageChange,
      PrevpageKey,
    }
  }
}
</script>

<style scoped>

/deep/ .el-pagination__total{
  color: #0527af !important;
}

.box{
  margin: 0 auto;
  margin-top: 0.1rem;
}
.bluethinline{
  margin: 0 auto;
  width: 60%;
  height: 0.03rem;
  line-height: 0.04rem;
  background-color: #0527af;
  border-radius: 0.02rem;
  box-shadow:0.02rem 0.02rem 0.02rem  #aeb1b3;
}
.newstitle{
  margin: 0 auto;
  margin-top: 0.04rem;
  width: 60%;
  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:800;
  line-height: 0.3rem;
}
.subtitle{
  margin: 0 auto;
  margin-top: 0.04rem;
  width: 60%;
  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  /*font-weight:500;*/
  line-height: 0.1rem;
}
.newdate{
  margin: 0 auto;
  /*margin-top: 0.04rem;*/
  width: 60%;
  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  line-height: 0.16rem;
}
.newauthor{
  margin: 0 auto;
  margin-top: 0.03rem;
  width: 60%;
  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  line-height: 0.16rem;
}
p{
  margin: 0 auto;
  width: 60%;
  /*text-indent: 2em;*/
  /*text-align: left;*/
  /*font-size: 0.10rem;*/
  /*font-family:'阿里巴巴普惠体 B';*/
  /*font-weight:500;*/
  /*line-height: 0.2rem;*/
}
</style>
