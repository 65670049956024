<template>
  <!--   添加对话框 开始   @close="SubensureDialogClose"-->
  <el-dialog
      :model-value="ensureDialogVisible"
      :title="title"
      :width="dialogwith"

      center
  >
    <!--   内容主体区      -->
    <span > {{ context }} </span>

    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click=SubensureDialogClose>取消</el-button>
          <el-button type="danger"  @click="SubensureDialog" >确认</el-button>
        </span>
    </template>
  </el-dialog>

  <!--  添加确认对话框  结束     -->
</template>

<script >

import { getCurrentInstance, onMounted} from "vue";

export default {
  name: "tipdialog",

  props:{
    title:{
      type:String,
      default:'对话框',
    },
    context:{
      type:String,
      default:'',
    },
    dialogwith:{
      type:Number,
      default:'30%',
    },
  },
  setup(props, context){
     const {proxy} = getCurrentInstance();

    onMounted(()=>{
         // console.log(proxy);
    });
    function SubensureDialogClose(){
      proxy.$emit('ensureDialog',0);
      // console.log(props.modelValue);
    }
    function SubensureDialog(){
      proxy.$emit('ensureDialog',1);
      // ensureDialogClose();
    }
    return{
      SubensureDialogClose,SubensureDialog,
    }
  }
}
</script>

<style scoped>

</style>
