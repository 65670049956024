
export const gseditbannerpicwebaddress='http://www.goldenseal.cn/index.php/website/bannerinfo/uploadpicture';
export const gsaddbannerpicwebaddress='http://www.goldenseal.cn/index.php/website/bannerinfo/addpicture';

export const gsedithomebannerpicwebaddress='http://www.goldenseal.cn/index.php/website/homebannerinfo/edithomebannerimage';
export const gsaddhomebannerpicwebaddress='http://www.goldenseal.cn/index.php/website/homebannerinfo/addhomebannerimage';

// export const gsimagepicwebaddress='http://www.goldenseal.cn/website/productsinfo/addproductimages';
export const gsimagepicwebaddress='http://www.goldenseal.cn/index.php/website/productsinfo/addproductimages';

export const gspdfwebaddress='http://www.goldenseal.cn/index.php/website/productsinfo/addproductpdfs';
//文字内嵌入图片上传
export const gstextimagepicwebaddress='http://www.goldenseal.cn/index.php/website/newsinfo/addtextimages';
//备份文件存储路径
export const gsaddbackupfilewebaddress='http://www.goldenseal.cn/index.php/website/backupdbinfo/backupfileupload';
export const gsaddbackupzipfilewebaddress='http://www.goldenseal.cn/index.php/website/backupdbinfo/backupzipfileupload';
export const gswebnopictur='@/assets/weblogo.jpg';
