<template>
  <imagbanner :propstitle="subtitle" :propsimgurl="bannerimgurl">    </imagbanner>

  <el-row    >
    <el-col    :span="6" :offset="14" >
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 产品名称 和 产品型号 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>
  </el-row>

  <div id="productbox">
<!--  <div id="productleft">-->
  <div id="producttype">
    <div class="producttypelist">
      <ul>
        <li   v-for="(productitem,i) in producttypetitle" :key="i" @click="keyproducttypelist(i)"
              :class="{liactive:i==producttypedisplayIntex}"

        >{{productitem}}</li>
      </ul>
    </div>
<!--  </div>-->
  </div>

    <!--  =========================================================================   -->
    <div id="productright"  >
      <div class="productintrduce"  v-show="Productstotal!=0"  >
        <div  class="produts"  v-for="(picitem,index) in (gsproductimformation.picimgs)"  :key="index">
          <img   :src="picitem" @click="pickeydown(index)" >
          <p   class="projectModel"   >{{gsproductimformation.productname[index]}}</p>
        </div>
      </div>
       <div v-show="Productstotal==0">
          <div v-if="queryPages.serch!=''">
            <h2>搜索后……</h2>
          </div>
         <h2>没有产品数据 ^L^</h2>
       </div>
    </div>

  </div>

  <backtop v-show="backtopflag">  </backtop>


<!-- -----------------------------------------------------------------------  -->
  <!--   分页器  ------------------------------------->
  <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
               handleCurrentChange 页码值发生改变
               currentPage  当前显示的第页面数
               page-size   当前一个页面显示多条数据  -->
  <el-row :gutter="20">
    <el-col :span="5" :offset="10">
      <el-pagination background layout="total,  prev, pager, next,"
                     v-model:currentPage="queryPages.pagenumber"
                     :page-size="queryPages.pagesize"
                     :page-sizes="[5, 10, 20, 30]"
                     :total="Productstotal"
                     hide-on-single-page
                     @size-change="handleSizePagesChange"
                     @current-change="handleCurrentPageChange"
      />
    </el-col>
  </el-row>
<!-- ------------------------------------------------------------------------ -->
<!--  <template v-for="item in gsproductimformation.picimgs[0][1]">{{item}}</template>-->
<!--  <hr>-->
<!--  <template v-for="item1 in gsproductimformation.picimgs[1][2]">{{item1}}</template>-->
<!--  <hr>-->
<!--  <template v-for="item3 in gsproductimformation.description[0]">{{item3}}</template>-->
<!--  <hr>-->
<!--  <template v-for="item2 in gsproductimformation.description[1]">{{item2}}</template>-->
<!--  gsproductimformation.description-->
</template>

<script>
import imagbanner from '@/components/imgbanner/imgbanner.vue'
import backtop from "@/components/backtop/backtop";
// import product from "@/components/product/product.vue"

import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue';

import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {getproducimgList,getproducts} from "@/axios/webindex";

import {useStore} from 'vuex';

// import Verticalbanner from "@/components/header/verticalbanner";
export default {
  name: 'products',

  components: {
    imagbanner,

    backtop,
  },
  setup(props){
    const store=useStore();
    let subtitle=ref('产品介绍');
    let bannerimgurl=ref('');
    let backtopflag=ref(0);
    let producttypetitle=ref([]);
    let producttypedisplayIntex=ref(0);
    let emptySerchCount=ref(0);
    let queryPages=reactive({
      producttype:'1',
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let Productstotal=ref();


    const gsproductimformation=reactive({
      id:[],               //id
      description:[],     //电气描述
      picimgs:    [],      //图片描述
      pdfs:[],             //pdf 描述
      descriptionsel:[],      //产品描述选择
      productmodel:[],       //产品型号
      productname:[],       //产品名称
    });
    const gsrouter=useRouter();
    //------------------------------------------------------------------
    onMounted(()=>{
        //=================================================================
      //加载 产品介绍 图片和数据 存入缓存中
      getproducimgList("index.php/index/productcenter").then(res=>{
        // console.log(res);
        subtitle.value=res.data.data[0].title;
        bannerimgurl.value=res.data.data[0].bannerimg;
        // producttypetitle.value=res.data.data[0].productstypetitle;
      }).catch(err=>{
        console.log(err);
      });
      producttypetitle.value=store.state.gsproducttypetitlestore.producttypetitle;
      //==================================================================
      queryPages.pagenumber= window.sessionStorage.getItem('productpagenumber');
      queryPages.pagesize= window.sessionStorage.getItem('productpagesize');
      queryPages.producttype= window.sessionStorage.getItem('producttype');
      queryPages.serch= window.sessionStorage.getItem('productsearch',queryPages.serch)
      // Newstotal.value= window.sessionStorage.getItem('newstotal');
      // console.log(queryPages.newstype);
      // console.log('============',queryPages.newstype);
      // console.log('-------------',queryPages.pagesize);
      // console.log('-------------->',queryPages.pagenumber);
      if(queryPages.serch===null)queryPages.serch='';

      if(queryPages.producttype===null)
      {
        producttypedisplayIntex.value=0;
        queryPages.producttype='1';
      }else{
        producttypedisplayIntex.value=queryPages.producttype-1;
      }

      initproductsdata();
      //=================================================================

    })

    function initproductsdata(){
      //加载 产品介绍 图片和数据 存入缓存中
      getproducts(queryPages).then(res=>{
        // console.log(res);
        if(res.data.code==200){

            queryPages.pagenumber=res.data.data.current_page;
            queryPages.pagesize=res.data.data.per_page;

            let type = Object.prototype.toString.call(res.data.data.data);
            let arr = [];
            if (type === "[object Array]"){
              // console.log('是一个数组');
              // ProductstableData.value=res.data.data.data;
              arr=res.data.data.data;
            }
            if (type === "[object Object]"){
              // console.log('是一个对象');

              for (let i in res.data.data.data) {
                arr.push(res.data.data.data[i]);
              }
              // ProductstableData.value=arr;
            }

            let row,arryleth;
            // arryleth=res.data.data.length;
            arryleth=arr.length;
            gsproductimformation.description.length=0;
            gsproductimformation.picimgs.length=0;
            gsproductimformation.pdfs.length=0;
            gsproductimformation.descriptionsel.length=0;
            gsproductimformation.productmodel.length=0;
            gsproductimformation.productname.length=0;
            gsproductimformation.id.length=0;
            for (row=0;row<arryleth;row++)
            {
              gsproductimformation.description[row]=arr[row].productdetails;
              gsproductimformation.picimgs[row]=arr[row].proudctimage;
              gsproductimformation.pdfs[row]=arr[row].productpdf;
              gsproductimformation.descriptionsel[row]=arr[row].descriptiontype;
              gsproductimformation.productmodel[row]=arr[row].productmodel;
              gsproductimformation.productname[row]=arr[row].productname;
              gsproductimformation.id[row]=arr[row].id;
              // console.log(row,'-----------------------');
            }
            // console.log(arryleth,'==================');
            // console.log(gsproductimformation.picimgs.length);

            Productstotal.value=res.data.data.total;
            window.sessionStorage.setItem('productpagenumber',queryPages.pagenumber);
            window.sessionStorage.setItem('productpagesize',queryPages.pagesize);
            window.sessionStorage.setItem('producttype',queryPages.producttype);
            window.sessionStorage.setItem('productsearch',queryPages.serch);
        }
      }).catch(err=>{
        console.log(err);
      });
      //==================================================================
    }

    function keyproducttypelist(keyindex){
      producttypedisplayIntex.value=keyindex;
      queryPages.producttype=keyindex+1;
      //自动清空搜索框
      if(queryPages.serch!=''){

        if(emptySerchCount.value>producttypetitle.value.length){
          emptySerchCount.value=0;
          queryPages.serch='';
          // console.log('emptySerchCount.value');
        }
        // console.log(emptySerchCount.value);
        emptySerchCount.value++;
      }
      initproductsdata();
    }
    //跳转到产品详细页面  page:displayIntex.value,
    function pickeydown(keyindex){

      gsrouter.push({
        path:'/productdetails',
        query:{
          item:keyindex,
          detail:encodeURIComponent(JSON.stringify(gsproductimformation)),
        }

      });
    }
    function serchKey(){
      emptySerchCount.value=0;
      initproductsdata();
    }
    function serchClearKey(){
      queryPages.serch='';
      initproductsdata();
    }
    //==================================================================
    //监听滚动屏像素
    window.addEventListener('scroll',()=>{
      // console.log('   ---');
      // 页面滚动距顶部距离
      let scrollTop =
          // window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      // console.log(scrollTop)

      if(scrollTop>300){   //大于380个像素时出现返回顶部按键
        backtopflag.value=true;//backtop enable
      }else {
        backtopflag.value=false;
      }

    }),true
    //======================================================================================
    return{
      backtopflag,subtitle,bannerimgurl,gsproductimformation,
      Search,queryPages,Productstotal,producttypetitle,producttypedisplayIntex,keyproducttypelist,pickeydown,
      serchClearKey,serchKey,
    }
  }
}
</script>

<style scoped>

/deep/ .el-pagination__total{
  color: #0527af !important;
}

#productbox{
  width: 6.8rem;
  /*height: 4.2rem;*/
  margin: 0 auto;
  display: flex;

}



#producttype{
  width: 20%;
  /*height: 100%;*/
  /*background-color: #333333;*/
  /*display: flex;*/
  /*justify-content: flex-start;*/
  overflow: hidden;
  text-align: center;
}
.producttypelist{

  width: 90%;
  /*height: 100%;*/


  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:500;
  line-height: 0.12rem;
  white-space: nowrap;

}
.producttypelist ul{
  padding-left: 0rem;
}
.producttypelist li{

  margin:  0.16rem 0rem;
  padding: 0.16rem 0rem;
  /*color: white;*/
  /*background-color:#0527af ;*/
  border: 0.01rem solid #0527af;
  /*border: 0.002rem solid #0527af;*/
  border-radius: 0.04rem;
  cursor: pointer;
  text-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;
  /*//0.01rem 0.01rem 0.01rem #aeb1b3;*/
  box-shadow:0.01rem 0.01rem 0.01rem  #0527af;
  user-select:none;

  overflow: hidden;
  text-overflow: ellipsis;
}
.liactive{
  background-color: #0527af;
  color: white;
  border: 0.01rem solid #0527af;
  border-radius: 0.04rem;
  text-shadow:0.004rem 0.004rem 0.004rem  #0527af;
}
.producttypelist li:hover{
  background-color: #0527af;
  color: white;
  border: 0.01rem solid #0527af;
  border-radius: 0.04rem;
  text-shadow:0.01rem 0.01rem 0.01rem  #0527af;
  /*cursor: pointer;*/

}
#productright{

  width: 80%;

  /*background-color: #124bf6;*/

}
.productintrduce{
  /*background-color: #52E923;*/
}
.produts{

  /*clear: both;*/
  width: 30%;
  float: left;


  /*width: 30%;*/
  /*height: 1.6rem;*/
  /*margin: 0 auto;*/
  margin: 0.09rem;

  /*background-color: #52E923;*/

}
.produts img{


  /*background-color: #52E923;*/


  width: 100%;
  height: 1.6rem;
  border: 0.01rem solid #0527af;
  box-shadow:0.01rem 0.01rem 0.01rem  #0527af;
  border-radius: 0.04rem;
  cursor: pointer;

}
.produts   img:hover{
  /*background-color: #0527af;*/
  /*color: white;*/
  border: 0.01rem solid #0527af;
  /*border-radius: 0.05rem;*/
  box-shadow:0.03rem 0.03rem 0.03rem  #0527af;
  /*cursor: pointer;*/
  text-shadow:0.03rem 0.03rem 0.03rem  #0527af;
}
.produts .projectModel{

  width: 38%;
  margin: 0 auto;
  /*padding-top: 0.02rem;*/
  padding: 0.03rem 0rem;
  /*background-color: #aeb1b3;*/
  font-size: 0.12rem;
  text-shadow:0.006rem 0.01rem 0.01rem  #0527af;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
