// 导入要调用的函数


//  export 为导出要被外面调用的函数

// import instance from './gswebrequ'
import gswebreqhead from './gswebrequ'



//=================================
//  获取 用户详细数据
export function getusers(params){
    return gswebreqhead({
        url:'/index.php/website/users',
        method:'GET',
        params
    })
}
//     传递参数调用 /website/index index.php/website/index
export function userlogin(data){
    return gswebreqhead({
        // url:'/login',
        url:'/index.php/website/index',
        // url:'/index',
        method:'POST',
        data
    })
}

// //   获取菜单项 数据
// export function menulist(){
//     return gswebreqhead({
//         url:'/webbacklogin/menus',
//         // method:'GET',
//         method:'POST',
//     })
// }
export function menulist(){
    return gswebreqhead({
        // url:'/login',
        url:'/index.php/website/menus',
        method:'GET',

    })
}
//
//用户名查找有用的邮箱
export function usenamefindemaildb(data){
    return gswebreqhead({
        url:'/index.php/website/findpassword/usenamefindemail',
        method:'POST',
        data
    })
}
//查找用户有用的邮箱
export function findemaildb(data){
    return gswebreqhead({
        url:'/index.php/website/findpassword',
        method:'POST',
        data
    })
}
// 改变用户状态
export function Updateusersdb(data){
    return gswebreqhead({
        url:'/index.php/website/usersinfo/updateuserinfo',
        method:'POST',
        data
    })
}
// 删除用户数据
export function Delusersdb(data){
    return gswebreqhead({
        url:'/index.php/website/usersinfo/goneuserinfo',
        method:'POST',
        data
    })
}
// 编辑用户数据
export function Editusersdb(data){
    return gswebreqhead({
        url:'/index.php/website/usersinfo/edituserinfo',
        method:'POST',
        data
    })
}
// 添加用户数据
export function Addusersdb(data){
    return gswebreqhead({
        url:'/index.php/website/usersinfo/adduserinfo',
        method:'POST',
        data
    })
}
// 获取用户数据
export function usersdblist(data){
    return gswebreqhead({
        url:'/index.php/website/usersinfo',
        method:'POST',
        data
    })
}
// 备份操作
export function backupdboperate(data){
    return gswebreqhead({
        url:'/index.php/website/backupdbinfo/backupoperate',
        // url:'/website/backupdbinfo/backupoperate',
        method:'POST',
        data
    })
}
// 获取备份数据
export function backupdblist(data){
    return gswebreqhead({
        url:'/index.php/website/backupdbinfo',
        method:'POST',
        data
    })
}
//更改  消息状态
export function messagestatus(data){
    return gswebreqhead({
        url:'/index.php/website/messageinfo/updatamessagestatus',
        method:'POST',
        data
    })
}
//删除消息
export function gonemessage(data){
    return gswebreqhead({
        url:'/index.php/website/messageinfo/messagegone',
        method:'POST',
        data
    })
}
////获取消息数据
export function messageslist(data){
    return gswebreqhead({
        url:'/index.php/website/messageinfo',
        method:'POST',
        data
    })
}
//更改  新闻状态
export function newsstatus(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/updatanewsstatus',
        method:'POST',
        data
    })
}
//更改新增的图片视频 和 文本新闻关联ID 数据
export function updatarelationid(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/updatanewsrelationid',
        method:'POST',
        data
    })
}
//删除新闻数据
export function gonenews(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/gonenews',
        method:'POST',
        data
    })
}
//编辑新闻数据
export function editnewscontent(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/updatenewscontent',
        method:'POST',
        data
    })
}
//添加新闻数据
export function addnewscontent(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/addnewscontent',
        method:'POST',
        data
    })
}

//去掉新闻数据没用的视频
export function textnewsvideogone(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/textnewsvideogone',
        method:'POST',
        data
    })
}
//去掉新闻数据没用的图片
export function textnewsimagegone(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo/textnewsimagegone',
        method:'POST',
        data
    })
}
//获取新闻数据
export function newslist(data){
    return gswebreqhead({
        url:'/index.php/website/newsinfo',
        method:'POST',
        data
    })
}
//产品 详情选择开关
export function productdescriptionstatus(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/descriptiondisplaystatus',
        method:'POST',
        data
    })
}
//产品 上架选择开关
export function productstatus(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/productdisplaystatus',
        method:'POST',
        data
    })
}
//产品 名称 模型 删除
export function productsgone(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/productgone',
        method:'POST',
        data
    })
}
//产品 名称 模型 添加
export function addproducts(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/addproducts',
        method:'POST',
        data
    })
}
//产品 名称 模型 更改
export function updataproducts(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/updataproducts',
        method:'POST',
        data
    })
}

//产品PDF 状态改变
export function produtspdfstatus(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/updataproductpdfstatus',
        method:'POST',
        data
    })
}
//删除产品PDF
export function produtspdfgone(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/updataproductpdfgone',
        method:'POST',
        data
    })
}
//获取产品PDF 信息
export function produtpdfslist(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/getproductpdfs',
        method:'POST',
        data
    })
}
//删除产品图片
export function produtsimagegone(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/updataproductimagegone',
        method:'POST',
        data
    })
}
//更改产品图片显示序号
export function produtsimageorder(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/updataproductimageorder',
        method:'POST',
        data
    })
}
//获取产品图片信息
export function produtsimagelist(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/getproductimage',
        method:'POST',
        data
    })
}
//获取产品信息 produtsimagelist
export function produtslist(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo',
        method:'POST',
        data
    })
}
//修改产品描述信息
export function produtdetails(data){
    return gswebreqhead({
        url:'/index.php/website/productsinfo/updataproductdetails',
        method:'POST',
        data
    })
}
//获取首页广告图片信息
export function homebannerlist(data){
    return gswebreqhead({
        url:'/index.php/website/homebannerinfo',
        method:'POST',
        data
    })
}
//保存首页广告名称 图片和 链接信息
export function homebannerupdataimagename(data){
    return gswebreqhead({
        url:'/index.php/website/homebannerinfo/updataimagename',
        method:'POST',
        data
    })
}
//保存首页广告名称 图片和 链接信息
export function homebannerupdatastatus(data){
    return gswebreqhead({
        url:'/index.php/website/homebannerinfo/updatastatus',
        method:'POST',
        data
    })
}


//删除首页广告信息
export function homebannergone(data){
    return gswebreqhead({
        url:'/index.php/website/homebannerinfo/homebanneruploadpicturegone',
        method:'POST',
        data
    })
}

//获取广告信息
export function bannerlist(data){
    return gswebreqhead({
        url:'/index.php/website/bannerinfo',
        method:'POST',
        data
    })
}
//删除广告信息
export function bannergone(data){
    return gswebreqhead({
        url:'/index.php/website/bannerinfo/uploadpicturegone',
        method:'POST',
        data
    })
}
//使能显示广告图片
export function changebannerdisplay(data){
    return gswebreqhead({
        url:'/index.php/website/bannerinfo/changebannerdisplay',
        method:'POST',
        data
    })
}
//获取公司信息
export function companylist(){
    return gswebreqhead({
        url:'/index.php/website/companyinfo',
        method:'GET',

    })
}
//     添加公司联人信息
//     传递参数调用
export function AddContacttoDb(data){
    return gswebreqhead({
        // url:'/login',
        url:'/index.php/website/companyinfo/AddContact',
        method:'POST',
        data
    })
}
//     编辑公司联人信息
//     传递参数调用
export function EditeContacttoDb(data){
    return gswebreqhead({
        // url:'/login',
        url:'/index.php/website/companyinfo/updataContact',
        method:'POST',
        data
    })
}
//     使能编辑公司联人信息
//     传递参数调用
export function EnableEditeContacttoDb(data){
    return gswebreqhead({
        url:'/index.php/website/companyinfo/updataEnableContact',
        method:'POST',
        data
    })
}
//     编辑关于我们信息
//     传递参数调用
export function EditeAboutUs(data){
    return gswebreqhead({
        // url:'/login',
        url:'/index.php/website/companyinfo/updataAboutUs',
        method:'POST',
        data
    })
}
//     编辑公司经纬度信息
//     传递参数调用
export function EditeLocation(data){
    return gswebreqhead({
        // url:'/login',
        // url:'/companyinfo/updataLocation',
        url:'/index.php/website/companyinfo/updataLocation',
        method:'POST',
        data
    })
}

//发送留言信息
export function sendemessageDB(data){
    return gswebreqhead({
        url:'index.php/index/message/messagerecode',
        method:'POST',
        data
    })
}
//发送邮箱验证码
export function sendemailcode(data){
    return gswebreqhead({
        url:'index.php/index/message/emailcode',
        method:'POST',
        data
    })
}
//获取新闻信息
export function getnews(data){
    return gswebreqhead({
        url:'index.php/index/newscenter/findnews',
        method:'POST',
        data
    })
}
//获取产品信息
export function getproducts(data){
    return gswebreqhead({
        url:'index.php/index/productcenter/findproducts',
        method:'POST',
        data
    })
}
//获取产品详细pdf信息
export function getproductpdf(data){
    return gswebreqhead({
        url:'index.php/index/productcenter/findproductpdf',
        method:'POST',
        data
    })
}
//获取产品详细图片信息
export function getproductimages(data){
    return gswebreqhead({
        url:'index.php/index/productcenter/findproductimages',
        method:'POST',
        data
    })
}
//===================================



export function getBannerList(url,param){

    // return  instance.get(url, {param});
    return  gswebreqhead.get(url, {param});
    // return instance.get(url,
    //     {param},
    // );
}
export function getadBannerList(url,param){

    // return  instance.get(url, {param});
    return  gswebreqhead.get(url, {param});
    // return instance.get(url,
    //     {param},
    // );
}

export function getaboutUsList(url,param){

    return  gswebreqhead.get(url, {param});
}
export function getconnectUsList(url,param){

    return  gswebreqhead.get(url, {param});
}
export function getproducimgList(url,param){

    return  gswebreqhead.get(url, {param});
}
export function getproductsList(url,param){

    return  gswebreqhead.get(url, {param});
}
export function getnewsList(url,param){
    return  gswebreqhead.get(url, {param});
}
export function getmessageList(url,param){
    return  gswebreqhead.get(url, {param});
}
// export function sendemailcode(url,param){
//     return  gswebreqhead.get(url, {param});
// }

export function getpic(type=1){
    return axios.get(`http://localhost:8080/banner?type=${type}`);
}
// export default {
//     getBannerList,
// }

