<template>
  <imagbanner :propstitle="subtitle" :propsimgurl="bannerimgurl">    </imagbanner>
<!--  class="mesgbox"-------------------------------------------------------  -->
  <el-form
      ref="MessageFormRef"
      :model="Messageinfo"
      :rules="Messagerules"
      label-width="40%"
  >

    <el-row    >
      <el-col :span="8" :offset="7"  >
        <el-form-item    label="姓名：" prop="username" >
          <el-input      size="large" :prefix-icon="User"  placeholder="请输入您的姓名" v-model.trim="Messageinfo.username" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row   >
      <el-col :span="8" :offset="7">
        <el-form-item   label="座机号码：" prop="tel" >
          <el-input     size="large" :prefix-icon="Phone "  placeholder="请输入您的座机号码" v-model.trim="Messageinfo.tel" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row   >
      <el-col :span="8" :offset="7">
        <el-form-item   label="手机电话号码：" prop="mobile" >
          <el-input     size="large" :prefix-icon="Cellphone"  placeholder="请输入您的手机号码" v-model.trim="Messageinfo.mobile" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row >
      <el-col :span="8" :offset="7">
        <el-form-item   label="电子邮箱：" prop="email">
          <el-input    size="large" :prefix-icon="Message " placeholder="请输入您的电子邮箱" v-model.trim="Messageinfo.email" :disabled="!isSendkeyEnable"  />
        </el-form-item>
      </el-col>
      <el-col :span="3" :offset="0">
        <el-button size="large" type="primary" @click="sendemail(MessageFormRef)"  :disabled="!isSendkeyEnable"   >{{sendKeyText}}</el-button>
      </el-col>
    </el-row>

    <el-row    :gutter=" 10"     >
      <el-col :span="8" :offset="7">
        <el-form-item   label="验证码：" prop="codeword">
          <el-input    size="large"  :prefix-icon="Orange  " placeholder="请输入验证码" v-model.trim="Messageinfo.codeword"  />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row    :gutter=" 10"     >
      <el-col :span="8" :offset="7">
        <el-form-item   label="留言内容：" prop="context">
          <el-input   type="textarea" autosize size="large"  :prefix-icon="Edit  " placeholder="请输入您的留言内容" v-model.trim="Messageinfo.context"  />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div>
    <el-button type="primary" @click.enter.native="MessageSendkey(MessageFormRef)"  size="large">点击提交</el-button>
  </div>
<!--  -------------------------------------------------------  -->
<!--  <div class="mesgbox">-->
<!--    <div class="inputclass">-->
<!--      <div class="labelclass">姓名：</div>-->
<!--      <input type="text" v-model="name" placeholder="请输入姓名">-->
<!--    </div>-->
<!--    <div class="inputclass">-->
<!--      <div class="labelclass">电话：</div>-->
<!--      <input type="text" v-model="phonenum" placeholder="请输入电话">-->
<!--    </div>-->
<!--    <div class="inputclass">-->
<!--      <div class="labelclass">电子邮箱：</div>-->
<!--      <input type="text" v-model="email" placeholder="请输入电子邮箱"> -->
<!--      <button class="labelkeyclass" @click="sendemail" :class="[isSendkeyEnable==true ? 'sendKeyenable':'sendKeydisabled']"    >{{sendKeyText}}</button>-->
<!--    </div>-->
<!--    <div class="inputclass">-->
<!--      <div class="labelclass">邮箱验证码：</div>-->
<!--      <input type="text" v-model="vercode" placeholder="请输入邮箱验证码">-->
<!--    </div>-->
<!--    <div class="inputclass">-->
<!--      <div class="labelclass">留言：</div> -->
<!--      <textarea type="text" v-model="message" placeholder="请输入给我们的留言"> </textarea>-->
<!--    </div>-->
<!--    <button class="submitkeyclass" @click="MessageSendkey">点击提交</button>-->
<!--   -->
<!--  </div>-->

  <backtop v-show="backtopflag">  </backtop>

</template>

<script>
// import backtop from "@/components/backtop/backtop";

import { Edit,Cellphone,Phone,  Picture, Upload, ChatLineSquare,Document,UserFilled,User,Message,Orange,CircleCheck       } from '@element-plus/icons-vue';

import imagbanner from '@/components/imgbanner/imgbanner.vue'
import backtop from "@/components/backtop/backtop";
import {onMounted,reactive, ref} from "vue";
import {getmessageList, sendemailcode,sendemessageDB} from "@/axios/webindex";
import { ElMessage } from 'element-plus';

export default {
  name: 'messages',

  components: {
    imagbanner,
    backtop,
  },

  setup(){
    let subtitle=ref('用户留言');
    let bannerimgurl=ref('');
    let backtopflag=ref(0);

    let isSendkeyEnable=ref(true);
    let sendKeyText=ref("点击发送验证码");
    let delayCount=ref(60);

    let LoginCode=ref(0);
    //let LoginCodes=ref("23456789abcdefhjkmnprstuvwxyABCDEFGHJKLMNPQRSTUVWXYZ");  //绘制的随机数
    let LoginCodes=ref("0123456789");  //绘制的随机数

    let name=ref();
    let phonenum=ref();
    let email=ref();
    let vercode=ref();
    let message=ref();

    let Messageinfo=reactive({
      username:'',
      mobile:'',
      email:'',
      tel:'',
      codeword:'',
      context:'',
    });
    const MessageFormRef = ref();
    const Messagerules = reactive({
      username: [
        { required: true, message: 'Please input your name', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      codeword:[
        { required: true, message: 'Please input Check code ',  trigger: 'blur' },
        { min: 4, max: 4, message: 'Length should be 4  ',      trigger: 'blur' },
      ],
      context:[
        { required: true, message: 'Please input you want to say ',  trigger: 'blur' },
        { min: 10, max: 10000, message: 'Length should be 10 to 10000  ',      trigger: 'blur' },
      ],
      mobile:[
        { required: true, message: 'Please input mobile number', trigger: 'blur' },
        { min: 11, max: 20, message: 'Length should be 11 to 20', trigger: 'blur' },
        { validator:checkMobile, trigger: 'blur'  }
      ],
      email:[
        { required: true, message: 'Please input email ', trigger: 'blur' },
        { min: 3, max: 20, message: 'Length should be 3 to 20', trigger: 'blur' },
        { validator:checkEmial, trigger: 'blur'  }
      ]
    })
//  自定义规则  校验邮箱
    function checkEmial (rule,value,callback) {
      //邮箱验证的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {  //合法的邮箱
        return callback();
      } else {
        callback(new Error('请输入合法的电子邮箱'));
      }
    }
    //  自定义规则  校验手机号
    function checkMobile (rule,value,callback){
      //手机验证的正则表达式
      const regMobile= /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(value)){  //合法的邮箱
        return callback();
      }else {
        callback(new Error('请输入合法的手机号码！'));
      }
    }



    function sendemail(MessageFormRefTemp){
      let SendData=reactive({
        email:'',
        codeword:'',
      });
      // console.log("==========22222====",);


      // isSendkeyEnable.value=!isSendkeyEnable.value;
      if(isSendkeyEnable.value==true){
        //验证邮箱格式
        MessageFormRefTemp.validateField(['email'],(Error)=>{
          // console.log(Error);

          if(Error){
            //验证通过
            refreshLoginCode();
            // console.log(LoginCode.value,'==============');
            // window.sessionStorage.setItem('emailcode',LoginCode.value);
            // Messageinfo.codeword=LoginCode.value;
            SendData.codeword=LoginCode.value;
            SendData.email=Messageinfo.email;
            // console.log("==========33333333333333333====",);
            delayCount.value=40;//60;
            isSendkeyEnable.value=false;
            sendKeyText.value=delayCount.value.toString()+' S';

            //延时60秒处理
            let timer=setInterval(()=>{

              if(delayCount.value>0){
                delayCount.value--;
                sendKeyText.value=delayCount.value.toString()+' S';
              }else
              {
                //注销定时器
                clearInterval(timer);
                isSendkeyEnable.value=true;
                sendKeyText.value="点击发送验证码";
              }

            },1000);

            sendemailcode(SendData).then(res=>{
              // console.log(res);
              if(res.data.code==200){
                ElMessage({
                  showClose: true,
                  message: '邮箱验证码已经发送->'+res.data.msg,
                  type: 'success',
                });
              }else{
                ElMessage({
                  showClose: true,
                  message: '邮箱验证码错误, 请重新试一下 ^L^',
                  type: 'error',
                });
              }
              // subtitle.value=res.data.data[0].title;
              // bannerimgurl.value=res.data.data[0].bannerimg;
            }).catch(err=>{
              console.log(err);
            });



          }else{
            //验证不通过
            // console.log("==========验证不通过====",);
            ElMessage({
              showClose: true,
              message: '邮箱格式输入错误, 请重新输入',
              type: 'error',
            });
          }
        })
      }else{
        ElMessage({
          showClose: true,
          message: '正在发送邮件，请稍后……',
          type: 'error',
        });
      }

    }
    //=============================================================
    function refreshLoginCode(){
      LoginCode.value = "";
      makeCode(LoginCodes.value, 4);
      // console.log(LoginCode.value+'========================================');
    }

    function  randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    };
    // 随机生成验证码字符串
    function makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        LoginCode.value += data[randomNum(0, data.length - 1)]
      }
    }
//==================================================================
    function MessageSendkey(MessageFormRefTemp){
      // console.log("=====================");


      if (!MessageFormRefTemp) return;

      MessageFormRefTemp.validate(async valid=> {

        if (valid) {

          if(LoginCode.value!=Messageinfo.codeword)
          {
            ElMessage({
              showClose: true,
              message: '验证码输入错误',
              type: 'error',
            });
            // refreshLoginCode();
            return;
          }

          // console.log(userinfor.username);
          // console.log(userinfor.email);

          const {data: res} = await sendemessageDB(Messageinfo);
          // console.log(res);
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: '留言成功 : ' + res.msg,
              type: 'success',
            });
            //从服务中取出数据
            Messageinfo.codeword='';
            refreshLoginCode();
            // Messageinfo.codeword=LoginCode.value='';
          } else {
            ElMessage({
              showClose: true,
              message: '留言失败 : ' + res.msg,
              type: 'error',
            });
          }

        }
      })

    }

    onMounted(()=>{
      //=================================================================
      //加载 产品介绍 图片和数据 存入缓存中
      getmessageList("index.php/index/message").then(res=>{
        // console.log(res);
        subtitle.value=res.data.data[0].title;
        bannerimgurl.value=res.data.data[0].bannerimg;
      }).catch(err=>{
        console.log(err);
      });
      //==================================================================
    });


    //==================================================================
    //监听滚动屏像素
    window.addEventListener('scroll',()=>{
      // console.log('   ---');
      // 页面滚动距顶部距离
      let scrollTop =
          // window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      // console.log(scrollTop)

      if(scrollTop>300){   //大于380个像素时出现返回顶部按键
        backtopflag.value=true;//backtop enable
      }else {
        backtopflag.value=false;
      }

    }),true

    //======================================================================================
    return{
      backtopflag,subtitle,bannerimgurl,
      name,email,phonenum,vercode,message,
      sendemail,MessageSendkey,isSendkeyEnable,sendKeyText,
      Messageinfo,Messagerules,MessageFormRef,
      User,Message,Orange,Edit,Cellphone,Phone,

    }
  }

}
</script>

<style scoped>
.mesgbox{
  width: 6.8rem;
  /*height: 3.06rem;*/
  margin: 0 auto;
  /*display: flex;*/
  align-items: center;
  /*user-select: none;*/
}
.inputclass{
  display: flex;
  margin-top: 0.1rem;


}
 /deep/ .el-form-item__label {
  color: #0527af !important;
  font-size: 0.10rem !important;
  font-family:'阿里巴巴普惠体 B' !important;
   font-weight: bold;
}
/*//提示框的字体颜色*/
  ::v-deep input::-webkit-input-placeholder {
    color: #0527af;
  }
/deep/ .el-input__inner {
  /*background-color: #333333;*/

  color: #0527af !important;

}


.inputclass .labelclass {
  margin: auto 0;
  width: 28%;
  text-align: right;
  /*background-color: #0F4B00;*/
  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  /*font-weight:800;*/
  line-height: 0.1rem;

  /*display: inline-block;*/
  /*vertical-align: middle;*/

}
.inputclass input{
  width: 40%;
  color: #0527af;
  /*border-color: #0527af;*/
  border: solid 0.02rem #0527af;
/*// 去除未选中状态边框*/
/*  border: 0;*/
/*// 去除选中状态边框*/
/*  outline: none;*/
  outline-color: #3437f8;
  /*background-color: #52E923;*/
  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  line-height: 0.1rem;
  border-radius: 0.03rem;
}
.inputclass .labelkeyclass{
  width: 14%;
  margin-left: 0.04rem;
  font-size: 0.1rem;line-height: 0.1rem;
  background-color: white;
  color: #0527af;;
  border-radius: 0.03rem;
  border: solid 0.02rem #0527af;
  /*cursor: pointer;*/
  /*background-color: #0F4B00;*/
}
.sendKeyenable {
  opacity: 1;
  cursor: pointer;
}
.sendKeydisabled {
  opacity: 0.6;
  cursor: not-allowed;
}
 .submitkeyclass{
   background-color: white;
   color: #0527af;;
  margin-top: 0.1rem;
   /*margin-bottom: 0.1rem;*/

  font-size: 0.14rem;line-height: 0.14rem;
   border: solid 0.02rem #0527af;
   border-radius: 0.04rem;
   cursor: pointer;
}
.inputclass textarea {
  color: #0527af;;
  border: solid 0.02rem #0527af;
  outline-color: #3437f8;

  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  line-height: 0.1rem;
  border-radius: 0.03rem;
  width: 40%;
  height: 1.6rem;
}
</style>
