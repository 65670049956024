<template>
  <rightmainhead  :fromFather =homebannerinfopath> </rightmainhead>
<!--  <h1>首页广告</h1>-->

  <!-- --------------------------------------------------  -->
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格  -->
    <el-table :data="HomebannertableData" border stripe style="width: 100%" >
      <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in homebannerinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small"     type="primary"     @click="addHomeBannerKey(row)"  >添加</el-button>
          <el-button size="small"     type="warning"     @click="editHomeBannerKey(row)"  >编辑</el-button>
          <el-button size="small"     type="danger"      @click="delHomeBannerKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>
        <template  v-slot="{ row }"  v-if="item.prop==='image'">

          <img   :src="row.imageurl" alt="" @click="Homebannertablepicpreview(row)" @error="defaultImg(a,$event)"
                 style="width: 100px;height: 60px; cursor:pointer">
        </template>

        <!--   状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='status'">
          <!--          <el-switch v-model="row.status" @change="switchStateKey(row)"></el-switch>-->
          <!--          <el-switch v-model="row.status ? false : true" @change="switchStateKey(row)"></el-switch>-->
          <el-switch
              v-model="row.status"
              active-value="1"
              inactive-value="0"
              @change="HomeBannerswitchStateKey(row)"></el-switch>

        </template>

      </el-table-column>
      <!--      <el-table-column label='联系人' prop="朱飞云">     </el-table-column>-->
    </el-table>

    <!--   分页器  ------------------------------------->
    <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
                 handleCurrentChange 页码值发生改变
                 currentPage  当前显示的第页面数
                 page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="Homebannertotal"
                       @size-change="handleSizePagesChange"
                       @current-change="handleCurrentPageChange"
        />
      </el-col>
    </el-row>
  </el-card>

  <!--  ---------------------------------------    -->
  <!--   添加 编辑 图片对话框对话框 开始  -->
  <el-dialog
      v-model="addImageDialogVisible"
      :title="addImageDialogTitle"
      width="50%"
      @close="addImageDialogCloseFun"
  >
    <!--   内容主体区 size="50%"  :rules="addImagDialogRules"   -->
    <el-form
        ref="addImageDialogFormRef"
        :model="addImageDialogForm"
        label-width="18%"
        status-icon
    >
      <template v-if="addImageDialogTitle!=='编辑图片'">
        <el-form-item label="图片名称"  prop="imagename">
          <el-input @blur="getinputValue()"  v-model="addImageDialogForm.imagename" />
        </el-form-item>
        <el-form-item label="图片链接地址"  prop="hometourl"  >
          <el-input  @blur="getinputValue()" v-model="addImageDialogForm.hometourl" />
        </el-form-item>
        <el-form-item label="图片地址"   prop="imageurl" >
          <el-input disabled v-model="addImageDialogForm.imageurl" />
        </el-form-item>
      </template>
      <template v-if="addImageDialogTitle=='编辑图片'">
        <el-form-item label="图片名称"  prop="imagename"  >
          <el-input @blur="getinputValue()"  v-model="addImageDialogForm.imagename" />
        </el-form-item>
        <el-form-item label="图片链接地址"  prop="hometourl"  >
          <el-input @blur="getinputValue()"  v-model="addImageDialogForm.hometourl" />
        </el-form-item>
        <el-form-item label="图片地址"   prop="imageurl" >
          <el-input disabled v-model="addImageDialogForm.imageurl" />
        </el-form-item>

        <!--        <span class="title"> 预览 </span>-->
        <div class="demo-fit" >
          <div class="block">
            <img :src="addImageDialogForm.imageurl" @error="defaultImg(a,$event)"   alt="" style="width: 400px;height: 60%">
          </div>
        </div>
      </template>
      <!--  :on-change="uploadchange" ================================================================= class="hide"  -->
      <el-row>
        <el-col :span="24"  >
          <el-upload
              :class="{hide:hideHomeBannerUpload }"
              ref="homebanneruploadref"
              :action="homebanneruploadURL"
              :headers="gswebsittoken"
              :before-upload="beforVerifyhomebannerUpload"
              :on-success="SuccesshomebannerUpload"
              :data="homebannerUploadDate"
              :on-preview="homebannerUploadPreview"
              :on-remove="homebannerUploadRemovePic"

              name="image"

              list-type="picture-card"
          >
            <el-button type="primary"  >点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                {{imaglocaname}}
              </div>
              <div class="el-upload__tip">
                jpeg/jpg/png/gif 文件大小低于 2Mb
              </div>

            </template>
          </el-upload>
        </el-col>
      </el-row>
      <!--  =========================================================   -->

    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetBannerFormData">清除</el-button>-->

          <el-button type="primary" @click="addImageDialogSave">保存数据</el-button>
          <el-button @click="addImageDialogVisible = false">关闭对话框</el-button>
        </span>
    </template>
  </el-dialog>

  <!--     对话框预览图片  -->
  <el-dialog
      v-model="PreViewImgDialogVisible"
      :title="PreViewImgDialogTitle"
      width="80%"
  >
    <!--    <span>图片 </span>-->
    <img class="imgpreview" :src="PreViewImgUrl" alt="">
  </el-dialog>



  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDialogVisible"
             context="确认将图片删除 ？ ^L^"
             title="删除图片数据"
             @ensureDialog="ensureDelDialog"
  ></tipdialog>



</template>

<script>
import Rightmainhead from "@/components/rightareahead/rightareahead";
import {onMounted, ref,reactive} from "vue";
import { Delete, Download, Plus, ZoomIn } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

import {homebannerlist,homebannergone,homebannerupdataimagename,homebannerupdatastatus} from "@/axios/webindex";
import Tipdialog from "@/components/rightareahead/tipdialog";
import {homebannerinfotableoptions} from './homebannertable'

import {gsedithomebannerpicwebaddress,gsaddhomebannerpicwebaddress,} from '../websiteglobalvar'

export default {
  name: "homebanner",

  components: {Tipdialog,Rightmainhead},

  setup() {
    let homebannerinfopath = [
      {name: '首页', path: 'AdminHome'},
      {  name: '广告管理',  path: 'homebanner'    },
      {  name: '首页广告',  path: 'homebanner'    }
    ];
    //表格参数
    let HomebannertableData=ref([]);

    //表格弹出对话框参数
    let addImageDialogVisible=ref(false);
    let addImageDialogTitle=ref(null);
    let addImageDialogFormRef=ref();
    let addImageDialogForm=reactive({
      id:'',
      imagename:'',
      hometourl:'',
      imageurl:'',
      status:'',
    });


    //上传组件参数
    let hideHomeBannerUpload=ref(false);
    let homebanneruploadref=ref();
    let homebanneruploadURL=ref();
    let upLoadimageArray=ref([]);
    let gswebsittoken= { Authorization:  window.localStorage.getItem('gstoken'),};
    let homebannerUploadDate=reactive({
      id:'',
      imagename:'',
      hometourl:'',
    });
    let imaglocaname=ref('等您上传图片喔！');
    //分页器变量
    let Homebannertotal=ref(1);
    let queryPages=reactive({
      pagenumber:'1',
      pagesize:'5',
    })

    //图片预览对话框参数
    let PreViewImgDialogVisible=ref(false);
    let PreViewImgDialogTitle=ref("首页图片预览");
    let PreViewImgUrl=ref();

    //删除图片对话框按键显示变量
    let ensureDialogVisible=ref(false);

    //表格添加按键处理函数
    function addHomeBannerKey(tableRow){
      addImageDialogVisible.value = true;
      addImageDialogTitle.value='添加图片';

      addImageDialogForm.imagename='';
      addImageDialogForm.imageurl='';
      addImageDialogForm.hometourl='';
      homebannerUploadDate.id='';
      homebanneruploadURL.value=gsaddhomebannerpicwebaddress;

    }
    //表格编辑按键处理函数
    function editHomeBannerKey(tableRow){
      addImageDialogTitle.value='编辑图片';
      addImageDialogVisible.value = true;
      addImageDialogForm.imagename=tableRow.imagename;
      addImageDialogForm.imageurl=tableRow.imageurl;
      addImageDialogForm.hometourl=tableRow.hometourl;
      addImageDialogForm.id=tableRow.id;
      homebannerUploadDate.id=tableRow.id;
      homebanneruploadURL.value=gsedithomebannerpicwebaddress;
    }
    //表格删除按键处理函数
    function delHomeBannerKey(tableRow){
      ensureDialogVisible.value=true;
      homebannerUploadDate.id=tableRow.id;
      // console.log('===========================>');
    }
    //表格图片预览按键处理函数
    function Homebannertablepicpreview(tableRow){
      PreViewImgUrl.value=tableRow.imageurl;
      // let temp=PreViewImgDialogTitle.value.length;
      // console.log(temp);
      if((tableRow.imagename)==null){
        PreViewImgDialogTitle.value='图片预览';
      }else{
      PreViewImgDialogTitle.value=tableRow.imagename+' ->图片预览';
      }
      PreViewImgDialogVisible.value=true;
    }
    //表格图片状态按键处理函数
    async function HomeBannerswitchStateKey(tableRow) {

      // console.log('======================>');

      const {data: res} = await homebannerupdatastatus(tableRow);
      // console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        initHomeBannerData();
      }

    }
    function getinputValue(){
      // console.log('---------------------------->');
      homebannerUploadDate.imagename=addImageDialogForm.imagename;
      homebannerUploadDate.hometourl=addImageDialogForm.hometourl;

    }
    //分页器总页数改变回调函数
    function handleSizePagesChange(){

    }
    //分页器当前页数改变回调函数
    function handleCurrentPageChange(){
      initHomeBannerData();
    }
    //确认对话框回调函数
    function ensureDelDialog(callbackstatus){
      ensureDialogVisible.value=false;


        if(callbackstatus==1){ //进行数据 服务器删除

          Homebannergonefun(homebannerUploadDate.id,'');

          // console.log('  ==================>');
        }else
        {
          //取消保存

        }
    }
    //图片载入出错调用函数
    function defaultImg(a,event){
      let img = event.srcElement;
      // img.src =require('../../../assets/weblogo.jpg');
      img.src =require('@/assets/weblogo.jpg');
      img.onerror = null
    }
    ////关闭添加图片 保存按键
    async function addImageDialogSave() {
      homebannerUploadDate.imagename = addImageDialogForm.imagename;
      homebannerUploadDate.hometourl = addImageDialogForm.hometourl;
      homebannerUploadDate.id = addImageDialogForm.id;
      // console.log('==============>');
      // console.log(homebannerUploadDate.imagename);

      const {data: res} = await homebannerupdataimagename(homebannerUploadDate);
      // console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });

      }
    }
    //关闭添加图片 对话框回调函数
    function addImageDialogCloseFun(){
      if( upLoadimageArray.value.length !=0){
        homebanneruploadref.value.clearFiles();
        // console.log('文件不为空   干 ==================>');
      }else {
        //console.log('文件为空==================>');
      }
      // console.log('closeUpload==================>'+pics.value.length);

      imaglocaname.value='等您上传文件喔 ^L^';
      upLoadimageArray.value.length = '';


      hideHomeBannerUpload.value=false;
      // console.log(pics.value);
      initHomeBannerData();
    }
    //上传图片对话框处理函数 =======================
    //上传图片前校验函数
    function beforVerifyhomebannerUpload(file){
      //判断文件格式
      if(file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/PNG' || file.type == 'image/gif' || file.type == 'image/GIF') {
        // console.log(_imgType);
      }else{
        ElMessage({
          showClose: true,
          message: '上传图片文件格式不对 ！',
          type: 'error',
        });
        return false;
      }


      if((file.size/1024/1024)>2){
        ElMessage({
          showClose: true,
          message: '上传图片大于2M ！',
          type: 'error',
        });
        return false;
      }
    }
    //上传成功后回调函数
    function SuccesshomebannerUpload(response,file,fileList){

      if(response.code==200){
        imaglocaname.value='您上传的文件名： '+file.name;
        upLoadimageArray.value.push(response.data);

        ElMessage({
          showClose: true,
          message: '成功上传图片：'+response.msg+"  "+upLoadimageArray.value,
          type: 'success',
        });
        // console.log( "----->>"+pics.value);// console.log(listFile.value);

        hideHomeBannerUpload.value=true;
      }else
      {
        ElMessage({
          showClose: true,
          message: '上传图片：'+response.msg,
          type: 'error',
        });
      }
      // imaglocaname.value='您上传的文件名： '+file.name;
    }
    //上传完后图片预览函数
    function homebannerUploadPreview(file){
      PreViewImgUrl.value=file.response.data;
      PreViewImgDialogTitle.value=imaglocaname.value+'  图片预览';
      PreViewImgDialogVisible.value=true;
    }
    //图片删除后回调函数
    function homebannerUploadRemovePic(file){

      Homebannergonefun('',file.response.data);
      addImageDialogCloseFun();

    }
    //图片删除函数
    async function Homebannergonefun(id, url) {
      let goneData = reactive({
        id: id,
        imageurl: url,
      });

      const {data: res} = await homebannergone(goneData);

      // console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '删除数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '删除数据成功 : ' + res.msg,
          type: 'success',
        });
        initHomeBannerData();
      }

    }
    //
    onMounted(()=>{
      initHomeBannerData();

    })
    async function initHomeBannerData() {

      let res = await homebannerlist(queryPages)
      {
        // console.log(res);

        if (res.data.code == 200) {
          HomebannertableData.value=res.data.data.data;
          Homebannertotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;

        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取广告信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }

    }

    return {
      homebannerinfopath,getinputValue,
      homebannerinfotableoptions,HomebannertableData,defaultImg,
      addHomeBannerKey,editHomeBannerKey,delHomeBannerKey,Homebannertablepicpreview,HomeBannerswitchStateKey,handleSizePagesChange,handleCurrentPageChange,
      addImageDialogCloseFun,addImageDialogVisible,
      PreViewImgDialogVisible,PreViewImgDialogTitle,PreViewImgUrl,
      addImageDialogFormRef,addImageDialogForm,addImageDialogTitle,
      ensureDialogVisible,ensureDelDialog,addImageDialogSave,
      queryPages,Homebannertotal,imaglocaname,upLoadimageArray,
      hideHomeBannerUpload,homebanneruploadref,homebanneruploadURL,gswebsittoken,beforVerifyhomebannerUpload,SuccesshomebannerUpload,homebannerUploadDate,homebannerUploadPreview,homebannerUploadRemovePic,
    };
  }
}
</script>

<style scoped >

.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}
.demo-fit {
  display: flex;
  text-align: left;
  justify-content: space-around;
  /*space-between;*/
}
.demo-fit .block {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}
.demo-fit .title {
  /*margin-bottom: 10px;*/
  font-size: 14px;
  color: var(--el-text-color-secondary);
}
/*  上传完图片后出现预览和删除图标 */
/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {
  margin-left: 0.4rem !important;
}

.imgpreview{
  width: 100%;
  height: 100%;
}

.hide >>> .el-upload--picture-card {
  display: none;
}

</style>
