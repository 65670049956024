import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//导入 element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

//导入自定义字体
import '@/assets/font/font.css'

import VueParticles from 'vue-particles'


//导入axios 自己封装的函数
// import '@/axios/gswebrequ'
import '@/axios/webindex'

//mock 导入
// import '@/mock/mock'
// import '@/mock/products'
// import '@/mock/news'
// import '@/mock/messages'

//滚轮组件导入
import CScrollbar from 'c-scrollbar';

//路由离开时调用
router.afterEach((to, from)=>{

    //console.log(to.path+'---afterEach====>to  ');
    //console.log(from.path+'---afterEach====>form  ');
    // console.log(next +'---afterEach====>next  ');
    let gswebbackflag =window.sessionStorage.getItem('gswebto');
    // console.log(gswebbackflag+'       gswebbackflag');
    if(to.path=='/website'){
        {
            window.sessionStorage.setItem('gswebto','1');
            // console.log( '---gswebbackflag  ');
        }
        // if(gswebbackflag==null)
        if(gswebbackflag!=1)
        {
            router.push('/website');
            router.go(0);
        }

    }
    if(to.path=='/mculogin')
    {
        window.sessionStorage.setItem('gswebto','2');

        // console.log(gswebbackflag+'gswebbackflag');

        if(gswebbackflag!=2){
            // console.log(gswebbackflag+'gswebbackflag 222222222222222222222222222222222');
            router.push('/mculogin');
            router.go(0);
            // gswebbackflag=2;

        }

    }
    if(to.path=='/')
    {
        if(gswebbackflag==1){
            router.push('/website');
        }
        if(gswebbackflag==2){
            router.push('/mculogin');
        }


    }
})

//路由进去时调用
//  路由守卫  =======================================================================================
// to   (object)    是去一个页面 将要访问的路径
// from (object)    代表从哪个路径跳转而来
// next (function)  是一个函数  表示放行   next() 直接放行  next('/login') 强制跳转
router.beforeEach((to,form,next)=>{

//===========================================================================
    //获取 Login 状态

    //console.log('beforeEach',to.path+'====>to  ');
    //console.log('beforeEach',form.path+'====>form  ');
    // console.log(next +'====>next  ');
    // if(to.path!=form.path)
    //next();

    //let loginflag =JSON.parse(window.sessionStorage.getItem('gslogin'));   //会话存储空间  true
    let username=window.sessionStorage.getItem('username');
    let gswebto =window.sessionStorage.getItem('gswebto');    //web 跳到哪里去  gswebto=0 网页  gswebto=1  网页后台  gswebto=2 mcu管理
    //退出登录
    if(to.path=='/AdminLoginout'){
        //清除状态
        // window.sessionStorage.clear();
        window.sessionStorage.removeItem('gslogin');
        //跳转登录页面
        next('/website');
    }else if(to.path=='/website'){
        //如果登录了


    }

    if(gswebto==1){
        if(to.path=='/'){next('/website')}
    }

    if(username==null){
        if(to.path=='/companyinfo'){ next('/website')};
        if(to.path=='/homebanner'){ next('/website')};
        if(to.path=='/bannerinfo'){ next('/website')};
        if(to.path=='/bluetoolsalarm'){ next('/website')};
        if(to.path=='/bluetoolslight'){ next('/website')};
        if(to.path=='/wifialarm'){ next('/website')};
        if(to.path=='/industrynews'){ next('/website')};
        if(to.path=='/companynews'){ next('/website')};
        if(to.path=='/productnews'){ next('/website')};
        if(to.path=='/nationalnews'){ next('/website')};
        if(to.path=='/messageinfo'){ next('/website')};
        if(to.path=='/userinfo'){ next('/website')};
        if(to.path=='/webdatainfo'){ next('/website')};
        //if(to.path=='/productnews'){ next('/website')};
        //if(to.path=='/productnews'){ next('/website')};
        //if(to.path=='/productnews'){ next('/website')};
    }
    if(to.path=='/'){window.sessionStorage.setItem('gsnavcount','0');};
    if(to.path=='/aboutus'){window.sessionStorage.setItem('gsnavcount','1');};
    if(to.path=='/products'){window.sessionStorage.setItem('gsnavcount','2');};
    if(to.path=='/news'){window.sessionStorage.setItem('gsnavcount','3');};
    if(to.path=='/connect'){window.sessionStorage.setItem('gsnavcount','4');};
    if(to.path=='/message'){window.sessionStorage.setItem('gsnavcount','5');};
    next();
//===========================================================================


})




createApp(App).use(store).use(ElementPlus).use(VueParticles).use(router).use(CScrollbar).mount('#app')
