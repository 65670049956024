<template>
  <imagbanner :propstitle="subtitle" :propsimgurl="bannerimgurl">    </imagbanner>
  <div class="imformation">
    <div id="baidumap">

    </div>
    <div class="companyimformation">

        <!-- <p>  {{text}}</p>  -->
	  	<p>  {{comphone     }}</p>
      <p>  {{comcellphone }}</p>

		  <p>  {{comemail     }}</p>
	  	<p>  {{comqq       }}</p>
      <p>  {{comcontacts  }}</p>
      <p>  {{comaddr      }}</p>
    </div>
  </div>

  <backtop v-show="backtopflag">  </backtop>
</template>

<script>
import imagbanner from '@/components/imgbanner/imgbanner.vue'
import backtop from "@/components/backtop/backtop";

import {onMounted, onUpdated, ref} from "vue";
import {getadBannerList, getconnectUsList} from "@/axios/webindex";

import {useStore} from 'vuex';

export default {

  components: {
    imagbanner,
    backtop,
  },

  setup(props){
    let subtitle=ref('公司简介');
    let bannerimgurl=ref('');
    let comaddr=ref("地址：");
    let comcellphone=ref("手机：");
    let comphone=ref("电话：");
    let comcontacts=ref("联系人：");
    let comemail=ref("邮箱：");
    let comqq=ref("QQ：");

    let addr='';
    let cellphone='';
    let phone='';
    let contacts='';
    let email='';
    let qq='';

    let longitude=ref('113.889417');
    let latitude=ref('22.782757');
    let backtopflag=ref(0);
    let map;
    let point;
    const store=useStore();
    onUpdated(()=>{

      if(store.state.displaylanguage==1){
        //中文
        comaddr.value="地址："+addr;
        comcellphone.value="手机："+cellphone;
        comphone.value="电话："+phone;
        comcontacts.value="联系人："+contacts;
        comemail.value="邮箱："+email;
        comqq.value="QQ："+qq;
      }else{
        comaddr.value="Address："+addr;
        comcellphone.value="Cellphone："+cellphone;
        comphone.value="Phone："+phone;
        comcontacts.value="Contacts："+contacts;
        comemail.value="Email："+email;
        comqq.value="QQ："+qq;
      }

    })

    onMounted(()=>{

      //==============================================================
      //加载 关于公司 图片和数据 存入缓存中
      getadBannerList("index.php/index/connectus").then(res=>{
        // console.log(res);

        subtitle.value=res.data.data[0].title;
        bannerimgurl.value=res.data.data[0].bannerimg;

        addr=res.data.data[0].address;
        cellphone=res.data.data[0].cellphone;
        phone=res.data.data[0].phone;
        contacts=res.data.data[0].contacts;
        email=res.data.data[0].email;
        qq=res.data.data[0].qq;
        // console.log(store.state.displaylanguage);

        if(store.state.displaylanguage==1){
          //中文
          comaddr.value="地址："+addr;
          comcellphone.value="手机："+cellphone;
          comphone.value="电话："+phone;
          comcontacts.value="联系人："+contacts;
          comemail.value="邮箱："+email;
          comqq.value="QQ："+qq;
        }else{
          comaddr.value="Address："+addr;
          comcellphone.value="Cellphone："+cellphone;
          comphone.value="Phone："+ phone;
          comcontacts.value="Contacts："+contacts;
          comemail.value="Email："+email;
          comqq.value="QQ："+qq;
        }


		longitude.value=res.data.data[0].longitude;
		latitude.value=res.data.data[0].latitude;
        // console.log(companytext.value.length);
        // console.log(subtitle.value);
        // console.log(companyimg.value);
        //======================================================================================
        //创建地图实例
        map=new window.BMapGL.Map("baidumap"); //window.BMapGL.Map("baidumap");
        //设置中心点
        // point=new window.BMapGL.Point(113.889417,22.782757);
        point=new window.BMapGL.Point(longitude.value,latitude.value);

        //地图初始化，同时设置地图展示级别
        map.centerAndZoom(point,15);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放

        var marker = new window.BMapGL.Marker(point);        // 创建标注
        map.addOverlay(marker);                     // 将标注添加到地图中

        map.enableScrollWheelZoom(true);
        var scaleCtrl = new window.BMapGL.ScaleControl(); // 添加比例尺控件
        map.addControl(scaleCtrl);
        marker.addEventListener("click", function(){
//    alert("您点击了标注");
          var opts = {
            width: 250,     // 信息窗口宽度
            height: 100,    // 信息窗口高度
            title: "欢迎光临"  // 信息窗口标题
          }
          var infoWindow = new window.BMapGL.InfoWindow("深圳市光明新区公明街道马山头社区永春街道一巷四号", opts);  // 创建信息窗口对象
//map.openInfoWindow(infoWindow, map.getCenter());        // 打开信息窗口
          map.openInfoWindow(infoWindow,point);        // 打开信息窗口
        });
        //======================================================================================
        // console.log(companyimformation.value);
      }).catch(err=>{
        console.log(err);
      });
      //==================================================================
    })

    //==================================================================
    //监听滚动屏像素
    window.addEventListener('scroll',()=>{
      // console.log('   ---');
      // 页面滚动距顶部距离
      let scrollTop =
          // window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      // console.log(scrollTop)

      if(scrollTop>300){   //大于380个像素时出现返回顶部按键
        backtopflag.value=true;//backtop enable
      }else {
        backtopflag.value=false;
      }

    }),true
    //======================================================================================
    return{backtopflag,subtitle,bannerimgurl,
	comaddr,
	comcellphone,
	comphone,
	comcontacts,
	comemail,
	comqq,
	}
  }


}

</script>

<style scoped>
.imformation{
  width: 6.8rem;
  height: 3.06rem;
  margin: 0 auto;
  display: flex;
  /*user-select: none;*/
}
#baidumap{
  /*flex: 1;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*display: flex;*/
  width: 4rem;
  height: 3rem;
  /*border:1px solid #999;*/
  margin: 0 auto;
  align-items :center;
  border-radius: 0.06rem;
  box-shadow:0.01rem 0.01rem 0.01rem  #0527af;
  /*background-color: #52E923;*/
}

.companyimformation {
  width: 2.8rem;
  font-size: 0.12rem;
  /*margin-top: 0.1rem;*/
  /*font-family:'阿里巴巴普惠体 ';*/

  line-height: 0.18rem;
  text-indent: 2em;
  padding: 0.06rem 0.08rem;

  text-align:left;
  /*display:inline-block;*/
  /*word-break :break-all ;*/
  /*word-wrap:break-word;*/
}
.companyimformation p {
  text-shadow:0.003rem 0.003rem 0.003rem #0527af;
}
</style>
