<template>
  <meta name="Keywords" content="蓝牙闹钟，蓝牙音响，蓝牙灯光"/>
  <meta name="Description" content="蓝牙闹钟方案提供商，电子产品方案提供商，消费类电子产品方案提供商。"/>


	<div id="app">


	<!-- <ledtimer></ledtimer> -->
     <!--  <nav>
     <router-link to="/">Home</router-link> |
     <router-link to="/about">About</router-link>
     </nav> -->

    <div v-if="gswebto==1">
     <!--   页面后台   -->
      <router-view></router-view>
    </div>
    <div v-if="gswebto==2">
      <!--   MCU 页面后台   -->
      <router-view></router-view>
    </div>
    <div v-if="gswebto==0">
<!--      网页   -->
            <webheader></webheader>
            <navheader></navheader>
<!--            <router-view></router-view>-->
           <bottom>  </bottom>
    </div>
<!--    <div v-else>-->
<!--      <router-view></router-view>-->
<!--    </div>-->

<!--{{gswebto}}-->

<!--     <router-link to="/">Home</router-link> |-->
<!--     <router-link to="/aboutus">About</router-link> |-->
<!--     <router-view/>-->




   </div>
</template>

<script >
	// import Home from 'views/home/HomeView.vue'
	 // import  'components/header/ledtimer.vue'

	 import webheader from '@/components/header/gshead.vue'
   import navheader from  '@/components/header/navigation.vue'
   import swipercar from '@/components/header/swipercarousel.vue'
   import bottom    from "@/components/bottom/bottom";

  import {onMounted, ref} from "vue";
  import {getBannerList} from "@/axios/webindex";
  import {getadBannerList} from '@/axios/webindex'

  import {useStore} from 'vuex';


	export default {
	  name: 'app',
	  components: {
      webheader,
      navheader,
      swipercar,
      bottom,

	  },

     setup(){
	    let loop=0;
	    let gswebto=ref(0);
	    let gswebtoBuffer=ref(null);
       const store=useStore();

	     onMounted(()=>{

	       //加载 Banner 图片和数据 存入缓存中  index.php/index/index/homebanner
         //getBannerList("index.php/index").then(res=>{

         //   getBannerList("index.php/index/index/homebanner").then(res=>{
         //   //console.log(res);
         //   // console.log(res.data.code);
         //   if(res.data.code==200)
         //   {
         //       store.state.gswebbannerstore.bannerimgs.length=0;
         //       store.state.gswebbannerstore.bannerimgurl.length=0;
         //       for (loop=0;loop<res.data.data.length;loop++)
         //       {
         //         store.state.gswebbannerstore.bannerimgs.push(res.data.data[loop].imageurl);    // banner 图片数据
         //         store.state.gswebbannerstore.bannerimgurl.push(res.data.data[loop].hometourl);  // banner url
         //         // bannerurl.value.push(res.data.data[loop].url);
         //         //  console.log(store.state.gswebbannerstore.bannerimgs);
         //       }
         //   }
         //   // console.log(gsdata.picList);
         // }).catch(err=>{
         //   console.log(err);
         // });

         //---------------------------------------------------------------
         //加载 adBanner 图片和数据 存入缓存中
         // getadBannerList("adbanner.html").then(res=>{
         //   console.log(res);
         //   let row,col;
         //   for (row=0;row<res.data.data.length;row++)
         //   {
         //     store.state.gswebadbannerstore.adbannertitle.push(res.data.data[row][0].title);    // banner 标题
         //     store.state.gswebadbannerstore.adbannertitleurl.push(res.data.data[row][0].url);  // banner url
         //     console.log('----------->'+res.data.data[row].length);
         //     for (col=1;col<res.data.data[row].length;col++){
         //
         //          store.state.gswebadbannerstore.adbannerimgs.push(res.data.data[row][col].img);    // banner 图片数据
         //         store.state.gswebadbannerstore.adbannerimgurl.push(res.data.data[row][col].url);  // banner url
         //
         //     }
         //     //  console.log(store.state.gswebbannerstore.bannerimgs);
         //     // console.log('----------->');
         //   }
         //   console.log(store.state.gswebadbannerstore.adbannerimgs);
         //   console.log(store.state.gswebadbannerstore.adbannerimgurl);
         // }).catch(err=>{
         //   console.log(err);
         // });
         //==============================================================
        // if(  window.sessionStorage.getItem('gswebbackflag')=="1")
        // {
        //   gswebbackflag.value=1;
        // }

         gswebtoBuffer.value=window.sessionStorage.getItem('gswebto');
         // console.log( parseInt(gswebtoBuffer.value));


         if(gswebtoBuffer.value==="0")
         {
           gswebto.value=0;
           // console.log('--------------------------------->');
         }
         if(gswebtoBuffer.value==="1")
         {
           gswebto.value=1;
           // console.log('111111111111111111>');
         }
         if(gswebtoBuffer.value==="2")
         {
           gswebto.value=2;
           // console.log('22222222222222222222222>');
         }
         // gswebto.value=parseInt(window.sessionStorage.getItem('gswebto'));
         // console.log('=======>');
         //   console.log(gswebto.value);

           // window.addEventListener('hashchange', onHashChange);
           // onHashChange();
           // console.log('=88888888888=====>');


         // window.onhashchange(()=>{
         //   console.log('===================>',window.location.hash);
         //   switch (window.location.hash){
         //     case '/aboutus':{
         //       console.log('===================>');
         //       break;}
         //   }
         // });




       })

       // function onHashChange()  {
       //   // 获取hash 值
       //   const hash = window.location.href.replace('#/', '');
       //   console.log('====0000000000000000=====>');
       //   // type.value = hash
       //   if (hash === 'all') {
       //     // 此处处理 all 的逻辑
       //   } else if (hash === 'active') {
       //     // 此处处理 active 的逻辑
       //   } else if (hash === 'completed') {
       //     // 此处处理 completed 的逻辑
       //   }
       // }




         return  {
        gswebto,
      }
     }

	  }

</script>

<!-- lang="less" -->
<style  >
/*             */
@import "../public/commcss/normalize.css";
@import "../public/commcss/gsbase.css";
@import '../src/assets/font/font.css';

::-webkit-scrollbar {
  width: 0 !important;
}

body{
  overflow-x: hidden;
  /*overflow-y: hidden;*/
}


</style>
