
export const productpdfoptions=[

    // {
    //     label: 'imageorder',
    //     prop : 'imageorder',
    //     // width:50,
    // },
    {
        label: 'productname',
        prop : 'productname',
        // width:100,
    },
    {
        label: 'productmodel',
        prop : 'productmodel',
        // width:100,
    },
    {
        label: 'productpdf',
        prop : 'productpdf',
        width:260,
    },
    {
        label: 'status',
        prop : 'status',
        width:88,
    },
    {
        label: 'actions',
        prop : 'actions',
        // width:130,
    },

]
