import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/home/HomeView.vue'
import AboutUs  from '@/views/aboutus/AboutView.vue'
import Products from '@/views/products/products.vue'
import News     from '@/views/news/news.vue'
import Connect  from '@/views/connect/connect.vue'
import Message  from '@/views/messages/messages.vue'

import Webblank from '@/views/products/webbank.vue'

import Nofound from '@/views/nofound/nofoundpage.vue'

import SendValue from '@/views/sendvalue/sendvalue.vue'

import Productdetail from '@/views/products/productdetails.vue'

import Newdetails  from '@/views/news/newdetails.vue'

import Mculogin   from '@/views/webmucmange/muclogin/mculogin.vue'

// import WebBackLogin from '../views/webmanage/webbacklogin/webbacklogin.vue'
import Website from '@/views/webmanage/webbacklogin/webbacklogin.vue'

import AdminHome from '@/views/webmanage/adminhome/adminhome.vue'
import Welcome from "@/views/webmanage/adminhome/welcome.vue";
import Companyinfo from "@/views/webmanage/rightarea/companyinfo.vue"
import Bannerinfo from "@/views/webmanage/rightarea/bannerinfo.vue"
import Homebanner from '@/views/webmanage/rightarea/homebanner.vue'
import FindPassword from '@/views/webmanage/rightarea/findpassword.vue'


//  产品管理
import Bluetoolsalarm from '@/views/webmanage/rightarea/products/bluetoolsalarm.vue'
import Bluetoolslight from '@/views/webmanage/rightarea/products/bluetoolslight.vue'
import Wifialarm      from '@/views/webmanage/rightarea/products/wifialarm.vue'

//新闻管理
import Industrynews from '@/views/webmanage/rightarea/news/industrynews.vue'
import Companynews from "@/views/webmanage/rightarea/news/companynews.vue"
import Productnews from '@/views/webmanage/rightarea/news/productnews.vue'
import Nationalnews from "@/views/webmanage/rightarea/news/nationalnews.vue"

//留言信息
import Messageinfo from '@/views/webmanage/rightarea/messageinfo.vue'
import Userinfo    from '@/views/webmanage/rightarea/userinfo.vue'
import Webdatainfo from '@/views/webmanage/rightarea/webdatainfo.vue'


const routes = [
  {
    path: '/',
    // redirect: '/home'
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView
  // },
  {
      path:"/(.*)",
      name: 'nofoundpage',
      component: Nofound,
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')1
	component: AboutUs,
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/webblank',
    name: 'webblank',
    component: Webblank
  },
  {
    path: '/productdetails',
    name: 'productdetails',
    props:true,
    component: Productdetail
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/newdetails',
    name: 'newdetails',
    component: Newdetails
  },
  {
    path: '/connect',
    name: 'connect',
    component: Connect
  },
  {
    path: '/message',
    name: 'message',
    query:{
      name:'false'
    },
    component: Message
  },
  //=================================================================== Mculogin
  {
    path: '/mculogin',
    name: 'mculogin',
    component: Mculogin
  },
  //==================================================================== WebBackLogin
  {
    path: '/website',
    name: 'website',
    // meta : {                      //加一个自定义obj
    //   requireAuth:true      //这个参数 true 代表需要登录才能进入A
    // },
    // query:{
    //   name:'true'
    // },
    // props({query:{name}}){
    //   console.log(name);
    //   return {name}
    // },
    component: Website
  },
  {
    path: '/findpassword',
    name: 'findpassword',
    // meta : {                      //加一个自定义obj
    //   requireAuth:true      //这个参数 true 代表需要登录才能进入A
    // },
    // query:{
    //   name:'true'
    // },
    // props({query:{name}}){
    //   console.log(name);
    //   return {name}
    // },
    component: FindPassword
  },

  {
    path: '/adminhome',
    name: 'adminhome',
    redirect:'/welcome',
    component: AdminHome,
    children:[
      {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome,
      },
      {
        path: '/companyinfo',
        name: 'Companyinfo',
        component: Companyinfo,
      },
      {
        path: '/homebanner',
        name: 'Homebanner',
        component: Homebanner,
      },
      {
        path: '/bannerinfo',
        name: 'Bannerinfo',
        component: Bannerinfo,
      },
      //========================================产品管理
      {
        path: '/bluetoolsalarm',
        name: 'Bluetoolsalarm',
        component: Bluetoolsalarm,
      },
      {
        path: '/bluetoolslight',
        name: 'Bluetoolslight',
        component: Bluetoolslight,
      },
      {
        path: '/wifialarm',
        name: 'Wifialarm',
        component: Wifialarm,
      },
        //=================================  新闻管理
      {
        path: '/industrynews',
        name: 'Industrynews',
        component: Industrynews,
      },
      {
        path: '/companynews',
        name: 'Companynews',
        component: Companynews,
      },
      {
        path: '/productnews',
        name: 'Productnews',
        component: Productnews,
      },
      {
        path: '/nationalnews',
        name: 'Nationalnews',
        component: Nationalnews,
      },
        //=====================   messageinfo
      {
        path: '/messageinfo',
        name: 'Messageinfo',
        component: Messageinfo,
      },
      //========================  Userinfo
      {
        path: '/userinfo',
        name: 'Userinfo',
        component: Userinfo,
      },
        //=================   webdatainfo
      {
        path: '/webdatainfo',
        name: 'Webdatainfo',
        component: Webdatainfo,
      },
    ]
  },




    //数字转义  传递数字  d 为传递数字
    //http://localhost:8080/article/123
  {
    path: "/article/:id(\\d+)",
    component: SendValue,
  },
    //http://localhost:8080/article/123/456
    //+为最少1 个  *号为有没有都可以
  {
    path: "/article/:id+",
    component: SendValue,
  },


    //子路由  根据 type 来适配子路由   /user/hengban     /user/shuban
  // {
  //   path: '/user/:type',
  //   component: User,
  //   children:[
  //     {
  //       path:'hengban',
  //       component:'Hengban'
  //     },
  //     {
  //       path: 'shuban',
  //       component: 'Shuban'
  //     }
  //   ]
  //
  // }
]

const router = createRouter({
  //通过 history 属性指定路由的工作模式
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(),

  //通过 routes 数组 指定路由规则
  routes
})

export default router














