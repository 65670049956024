<template>
<!--  <div class="common-layout">-->
    <el-container >
          <!--      头部   -->
        <el-header>
          <div class="logoarea">
            <div class="headlogo">
<!--             <img src="../../../assets/headlogo.jpg" >-->
              <img src="@/assets/headlogo.jpg" >
              <span>金钤科技 后台管理</span>
            </div>

          </div>
          <div class="userinfo"  @click="quitkey()">
              <el-icon size="30px"><UserFilled /></el-icon>
              <span>{{ username }}</span>

            <span></span>
          </div>
        </el-header>

        <el-container class="containerbox" >
             <!--        左侧导航   -->


               <navmenu>   </navmenu>

             <!--        主体   -->
              <el-main >
                  <router-view>
                  </router-view>
              </el-main>
        </el-container>
    </el-container>
<!-- ======================================================== -->
<!--   退出  弹出 窗口 -->
<!--  <el-dialog v-model="outerVisible" title="退出登录" center>-->
<!--    <template #default >-->
<!--       <el-icon size="30px"><UserFilled /></el-icon>-->
<!--       <span>{{ username }}</span>-->
<!--        你确定退出当前登录？-->
<!--    </template>-->
<!--    <template #footer>-->
<!--      <div class="dialog-footer">-->
<!--        <el-button type="info" @click="outerVisible = false">取消</el-button>-->
<!--        <el-button type="warning" @click="quitenterkey()" >确认</el-button>-->
<!--      </div>-->
<!--    </template>-->
<!--  </el-dialog>-->
<!-- <el-icon><Remove /></el-icon> </div>-->
  <!--  对话框组件  -->
  <tipdialog v-model="outerVisible"
             context="你确定退出当前登录 ？ ^L^"
             title="退出登录"
             dialogwith="30%"
             @ensureDialog="quitenterkey"
  ></tipdialog>

</template>

<script>

import Navmenu from "@/views/webmanage/leftmenumain/navmenu.vue";

import { UserFilled,RemoveFilled,Remove } from '@element-plus/icons-vue'
import {onMounted, ref} from "vue";
import Tipdialog from "@/components/rightareahead/tipdialog";


import { ElMessage, ElMessageBox } from 'element-plus'
import   { Action } from 'element-plus'
import router from "@/router";


export default {
  name: "adminhome",
  components: {
    Navmenu,
    UserFilled,RemoveFilled,Remove,
    Tipdialog,
  },
  setup(){
    let username=ref(0);
    let outerVisible=ref(false);
    onMounted(()=>{

      // username.value=window.localStorage.getItem('gsloginusername');
      username.value=window.sessionStorage.getItem('username');//登录用户名进行传值
      // console.log('======>  '+username.value);
    });
    function quitkey(){
      // console.log('==============================');
             outerVisible.value=true;
    }
    function quitenterkey(subData){
      outerVisible.value=false;
      //0 是取消
      if(subData==1){  //确认退出
        window.sessionStorage.removeItem('gslogin');
        router.push('/website');   //跳转到登录状态

      }
      // console.log('=========  跳转到登录页面  ==============');
    }
    return{
      username,
      quitkey,quitenterkey,
      outerVisible,
    }

  }
}
</script>

<style scoped>
.containerbox{
    height: 100%;
}
.el-header{
  background-color: #0527af;
  color: white;
  display: flex;
  justify-content: space-between;
}
.logoarea{
  display: flex;
  justify-content: space-between;
}
.headlogo{
  display: flex;
  align-items: center;

}
.headlogo img{
  width:  60px;
  height: 45px;
}
.headlogo span{
  font-size: 22px;
  margin-left: 8px;
  text-shadow:1px 1px #888888;
  user-select: none
}
.userinfo{
  display: flex;
  align-items: center;
  text-shadow:1px 1px #666666;

  user-select: none;
  cursor: pointer;
}
.userinfo span{
  margin-left: 8px;
  margin-right: 4px;
}
.el-aside{
  /*background-color: #0527af;*/
}
</style>
