export const homebannerinfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '图片名称',
        prop : 'imagename',
        width:108,
    },
    {
        label: '图片主页链接',
        prop : 'hometourl',
        width:180,
    },
    {
        label: '图片链接',
        prop : 'imageurl',
        width:180,
    },
    {
        label: '图片',
        prop : 'image',
        // width:130,
    },
    {
        label: '图片显示',
        prop : 'status',
        width:88,
    },
    {
        label: '操作',
        prop : 'actions',
        // width:130,
    },

]
