
export const productimageoptions=[

    {
        label: 'imageorder',
        prop : 'imageorder',
        // width:50,
    },
    {
        label: 'productname',
        prop : 'productname',
        // width:100,
    },
    {
        label: 'productmodel',
        prop : 'productmodel',
        // width:100,
    },
    {
        label: 'productimage',
        prop : 'productimage',
        width:130,
    },
    {
        label: 'actions',
        prop : 'actions',
        // width:130,
    },

]
