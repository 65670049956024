<template>
  <rightmainhead  :fromFather =companypath> </rightmainhead>
  <el-card class="box-card">
<!--    <h1>公司信息</h1>-->
    <!--   表格  -->
    <el-table :data="tableData" border stripe style="width: 100%" >
       <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in companyinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small" :icon="Add"    type="primary"     @click="addcontactKey(row)"  >添加</el-button>
          <el-button size="small" :icon="Edit"   type="warning"     @click="editcontactKey(row)"  >编辑</el-button>
<!--          <el-button size="small" :icon="Delete" type="danger"   @click="delKey(row)"   >Del</el-button>-->
<!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>

        <!--   状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='status'">
<!--          <el-switch v-model="row.status" @change="switchStateKey(row)"></el-switch>-->
<!--          <el-switch v-model="row.status ? false : true" @change="switchStateKey(row)"></el-switch>-->
          <el-switch
                       v-model="row.status"
                       active-value="1"
                       inactive-value="0"
                       @change="switchStateKey(row)"></el-switch>

        </template>

      </el-table-column>
<!--      <el-table-column label='联系人' prop="朱飞云">     </el-table-column>-->
    </el-table>

<!--       经度  纬度 输入   -->
    <div>

        <el-form
            ref="locationRef"
            :model="location"
            :rules="locationRules"
            label-width="20%"
            status-icon
        >
          <el-row :gutter="20">

              <el-col :span="11">
                  <el-form-item label="经度"  prop="longitude">
                    <el-input  v-model="location.longitude" />
                  </el-form-item>
              </el-col>
              <el-col :span="11">
                  <el-form-item label="纬度"  prop="latitude">
                    <el-input  v-model="location.latitude" />
                  </el-form-item>
              </el-col>
              <el-col :span="2">
                    <el-button  type="danger"   @click="setlocationKey()" >提交</el-button>
              </el-col>
          </el-row>
        </el-form>

    </div>

<!--    <div  class="text item">{{aboutus}}</div>-->
    <!--       关于我们         -->
<!--    <el-row >-->
<!--      <el-col :span="24">-->
<!--          <div class="aboutus">-->
<!--            <el-input-->
<!--                v-model="aboutus"-->
<!--                autosize-->
<!--                type="textarea"-->
<!--            />-->
<!--          </div>-->
<!--    </el-col>-->
<!--    </el-row>-->

<!--    <el-row>-->

<!--    </el-row> -->

      <el-row>
        <el-col :span="24" >
          <el-divider content-position="left" >关于我们</el-divider>
        </el-col>

                  <el-col :span="4" >
<!--                    <el-button   type="danger"  @click="setKey()" >Enter</el-button>-->
                  </el-col>
      </el-row>
    <el-row>
      <el-col :span="24" class="aboutusdiv" >

<!--        <template v-for="text in aboutus"  class="aboutus"  >-->
<!--          <p>  {{text}}</p>-->
<!--          <p  v-html="text" >  </p>-->
<!--        </template>-->
        <p class="aboutusdiv" v-html="aboutus"> </p>

<!--          <div class="aboutusdiv" v-html="aboutus" ><p></p></div>-->
<!--          <p>{{ aboutus }}</p>-->

      </el-col>
    </el-row>

    <el-row :gutter="20" style="margin-top: 13px;">
      <el-col :span="22" >
        <el-divider content-position="right" ></el-divider>
      </el-col>

      <el-col :span="2" >
<!--        {{aboutus.length}}-->
           <el-button    type="primary"  @click="EditAboutus()" >编辑</el-button>
      </el-col>
    </el-row>

  </el-card>


  <!--    slot-scope="scope"  color: #0527af;   placeholder="Please input"-->

<!-- -----------------------------------------------------------------------------------------------------------     -->
  <!--   添加用户对话框 开始  -->
  <el-dialog
      v-model="addUserdialogVisible"
      :title="DialogTitle"
      width="60%"
      @close="handleClose"
  >
    <!--   内容主体区      -->
    <el-form
        ref="addUserFormRef"
        :model="addUserForm"
        :rules="addUserFormRules"
        label-width="80px"
        :size="formSize"
        status-icon
    >
      <template v-if="DialogTitle!=='编辑联系人'">
        <el-form-item label="联系人"  prop="contact">
          <el-input v-model="addUserForm.contact" />
        </el-form-item>
        <el-form-item label="QQ"  prop="qq">
          <el-input v-model="addUserForm.qq" />
        </el-form-item>
        <el-form-item label="邮箱"  prop="email">
          <el-input v-model="addUserForm.email" />
        </el-form-item>
        <el-form-item label="电话"   prop="tel" >
          <el-input v-model="addUserForm.tel" />
        </el-form-item>
        <el-form-item label="手机"   prop="mobile" >
          <el-input v-model="addUserForm.mobile" />
        </el-form-item>
        <el-form-item label="地址"   prop="address" >
          <el-input v-model="addUserForm.address" />
        </el-form-item>
      </template>
      <template v-if="DialogTitle=='编辑联系人'">
        <el-form-item label="联系人"  prop="contact">
          <el-input v-model="addUserForm.contact" />
        </el-form-item>
        <el-form-item label="QQ"  prop="qq">
          <el-input v-model="addUserForm.qq" />
        </el-form-item>
        <el-form-item label="邮箱"  prop="email">
          <el-input v-model="addUserForm.email" />
        </el-form-item>
        <el-form-item label="电话"   prop="tel" >
          <el-input v-model="addUserForm.tel" />
        </el-form-item>
        <el-form-item label="手机"   prop="mobile" >
          <el-input v-model="addUserForm.mobile" />
        </el-form-item>
        <el-form-item label="地址"   prop="address" >
          <el-input v-model="addUserForm.address" />
        </el-form-item>
      </template>

      <!--        <el-form-item label="创建时间" prop="create_time">-->
      <!--          <el-input v-model="addUserForm.create_time" />-->
      <!--        </el-form-item>-->
    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
          <el-button type="warning" @click="ResetAddUserFormData">清除</el-button>
          <el-button @click="addUserdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addContactCheck">提交</el-button
          >
        </span>
    </template>
  </el-dialog>

  <!--   添加用户对话框   结束   -->
<!-- -------------------------------------------------------------------------------------------------------------- -->
  <!--   添加对话框 开始  -->
<!--  <el-dialog-->
<!--      v-model="ensureDialogVisible"-->
<!--      title="提交经纬度数据"-->
<!--      width="28%"-->

<!--      @close="ensureDialogClose"-->
<!--      center-->
<!--  >-->
<!--    &lt;!&ndash;   内容主体区      &ndash;&gt;-->
<!--    <span  > 确认将经纬度数据提交到服务器 ？ ^L^ </span>-->

<!--    &lt;!&ndash;   对话框底部区   &ndash;&gt;-->
<!--    <template #footer>-->
<!--        <span class="dialog-footer">-->
<!--          <el-button @click="ensureDialogVisible = false">取消</el-button>-->
<!--          <el-button type="warning" @click="ensureDialog" >确认</el-button>-->
<!--        </span>-->
<!--    </template>-->
<!--  </el-dialog>-->
<!--  添加确认对话框  结束     -->

<!--  对话框组件  -->
  <tipdialog v-model="ensureDialogVisible"
             context="确认将经纬度数据提交到服务器 ？ ^L^"
             title="提交经纬度数据"
             @ensureDialog="ensureDialog"
             ></tipdialog>

  <!--  =============================================================== aboutusEdit -->
  <wandeditordialog v-model="enAboutusDialogVisible"
                    :context="aboutus"
                    title="编辑关于我们"
                    @ensureDialog="ensureAboutusDialog">

  </wandeditordialog>

  <!--   添加富文本对话框 开始  -->
<!--  <el-dialog-->
<!--      v-model="enAboutusDialogVisible"-->
<!--      title="修改关于我们数据"-->
<!--      width="80%"-->

<!--      @close="AboutusDialogClose"-->
<!--      center-->
<!--  >-->
<!--    &lt;!&ndash;   内容主体区      &ndash;&gt;-->


<!--    &lt;!&ndash;   对话框底部区   &ndash;&gt;-->
<!--    <template #footer>-->
<!--        <span class="dialog-footer">-->
<!--          <el-button @click="enAboutusDialogVisible = false">取消</el-button>-->
<!--          <el-button type="warning" @click="ensureAboutusDialog" >确认</el-button>-->
<!--        </span>-->
<!--    </template>-->
<!--  </el-dialog> -->
  <!--  添加富文本对话框  结束     -->

</template>

<script>
import {companyinfotableoptions} from './companyinfotable'
import Rightmainhead from "@/components/rightareahead/rightareahead";

import {onMounted,reactive, ref} from "vue";
import {companylist,AddContacttoDb,EditeContacttoDb,EnableEditeContacttoDb,EditeAboutUs,EditeLocation} from "@/axios/webindex";

import { ElMessage } from 'element-plus'
import Tipdialog from "@/components/rightareahead/tipdialog";
import Wandeditordialog from "@/components/rightareahead/wandeditordialog";

export default {
  name: "companyinfo",
  components: {Wandeditordialog, Tipdialog, Rightmainhead},

  setup(){
      let companypath=[
          {
            name: '首页',   // 名称,自定
                path: 'AdminHome'  // 路由导航,自定
          },
          {
            name: '公司管理',
                path: 'companyinfo'
          },
        {
          name: '公司信息',
          path: 'companyinfo'
        }
      ];

      let formSize=ref(200);
      let tableData =ref([]);
     //  let longitude=ref(0);
     // let   latitude=ref(0);
     let aboutus=ref(0);
     let locationRef=ref();
     let location=reactive({
       longitude:'',
       latitude:'',
     });

     let enAboutusDialogVisible=ref(false);
     let ensureDialogVisible=ref(false);
    // 用户数据
    let addUserdialogVisible=ref(false);
    let DialogTitle=ref("添加联系人");
    let addUserFormRef=ref();
    let addUserForm=reactive({
      id:'',
      contact:' ',
      qq:'',
      email:' ',
      tel:'',
      mobile:'',
      address:'',
      // status:'',
    });

    let locationRules=reactive({
      longitude:[
        { required: true, message: 'Please input user longitude', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      latitude:[
        { required: true, message: 'Please input user latitude', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
    });

    let addUserFormRules=reactive({
      contact:[
        { required: true, message: 'Please input contact', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      qq:[
        { required: true, message: 'Please input qq', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      email:[
        { required: true, message: 'Please input email ', trigger: 'blur' },
        { min: 3, max: 20, message: 'Length should be 3 to 20', trigger: 'blur' },
        { validator:checkEmial, trigger: 'blur'  }
      ],
      tel:[
        { required: true, message: 'Please input telephone number', trigger: 'blur' },
        { min: 11, max: 20, message: 'Length should be 11 to 20', trigger: 'blur' },
        // { validator:checkMobile, trigger: 'blur'  }
      ],
      mobile:[
        { required: true, message: 'Please input mobile number', trigger: 'blur' },
        { min: 11, max: 20, message: 'Length should be 11 to 20', trigger: 'blur' },
        { validator:checkMobile, trigger: 'blur'  }
      ],
      address:[
        { required: true, message: 'Please input address', trigger: 'blur' },
        { min: 11, max: 100, message: 'Length should be 11 to 100', trigger: 'blur' },
        // { validator:checkMobile, trigger: 'blur'  }
      ],
      // create_time:[
      //   { required: true, message: 'Please input create time', trigger: 'blur' },
      //   { min: 10, max: 13, message: 'Length should be 2 to 20', trigger: 'blur' },
      // ],
    });

    //  自定义规则  校验邮箱
    function checkEmial (rule,value,callback) {
      //邮箱验证的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {  //合法的邮箱
        return callback();
      } else {
        callback(new Error('请输入合法的邮箱'));
      }
    }
    //  自定义规则  校验手机号
    function checkMobile (rule,value,callback){
      //手机验证的正则表达式
      const regMobile= /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(value)){  //合法的邮箱
        return callback();
      }else {
        callback(new Error('请输入合法的手机号码！'));
      }
    }

     //  刷数据库
     async function initDBData() {
       let res = await companylist();
       // console.log(res);
       if(res.data.code==200){
         tableData.value = res.data.data;
         location.longitude=res.data.data[0].longitude;
         location.latitude=res.data.data[0].latitude;
         aboutus.value=res.data.data[0].aboutus;

         // aboutus.value=aboutus.value.split('\n');
         // aboutus.value.replace(/\\r\\n/g, '<br/>');
         // console.log(res.data.data[0].longitude);
         // console.log(res.data.data[0].latitude);

       }else {  //数据不成功
         ElMessage({
           showClose: true,
           message: '获取公司信息数据失败：'+res.data.msg,
           type: 'error',
         });
       }
     }

    onMounted( () => {
      initDBData();
    })
    //修改关于我们
    function EditAboutus(){
      enAboutusDialogVisible.value=true;
    }
    //修改关于我们 确认按键
    function ensureAboutusDialog(aboutusData){
      enAboutusDialogVisible.value=false;

      // console.log(aboutusData+'---------------------------------');
      if(aboutusData!=0){
        // aboutusData=aboutusData.replace(/<p>/,'');
        // aboutusData=aboutusData.replace(/<\/p>/,'');
        //  aboutus.value=aboutusData.split('<br>');
        aboutus.value=aboutusData;
        // console.log('---------------------------------'+aboutusData);
         EditeAboutUstoDb(aboutus.value);
      }
    }

    //提交百度经纬度 校验
    function  setlocationKey(){

      locationRef.value.validate(valid=>{
        // console.log(valid);
        if(!valid) return ;  //数据不对返回
           ensureDialogVisible.value=true;
      })
    }
    //提交百度经纬度   刷数据库  对话框确定按键
function ensureDialog(subData){
  // console.log("-----------------"+location.longitude);
  // console.log("-----------------"+location.latitude);
  ensureDialogVisible.value=false;
  if(subData==1){ //进行数据 服务器保存
    // console.log("-----------------"+location.latitude);
      EditLocationtoDb(location);

  }else
  {
    //取消保存
    // console.log("----------subData-------"+subData);
  }
}

    //添加对话框数据
    //=====  增加联系人
    function addcontactKey(rowTemp){
      DialogTitle.value='添加联系人';
      addUserdialogVisible.value=true;
      addUserForm.contact='';
      addUserForm.qq='';
      addUserForm.email='';
      addUserForm.tel='';
      addUserForm.mobile='';
      addUserForm.address='';
      addUserForm.id='';



      // console.log("addcontactKey   -----------------");
    }
    //=====  编辑联系人
    function editcontactKey(editData){
      DialogTitle.value='编辑联系人';
      addUserdialogVisible.value=true;
      addUserForm.contact=editData.contact;
      addUserForm.qq=editData.qq;
      addUserForm.email=editData.email;
      addUserForm.tel=editData.tel;
      addUserForm.mobile=editData.mobile;
      addUserForm.address=editData.address;
      addUserForm.id=editData.id;
      // console.log("editcontactKey   -----------------");
    }
    //  对话框底下两个按键处理函数
    //添加用户确认提交按键进行数据预验证
    function addContactCheck(){
      addUserFormRef.value.validate( valid => {
        // console.log(valid);
        if (!valid) return;  //数据不对返回
        //  添加用户的表单请求
        if(DialogTitle.value=="添加联系人"){
          postContactData(addUserForm);
          // console.log("=============");
          // addUserdialogVisible.value=false;

        }
        if(DialogTitle.value=="编辑联系人"){
          EditeContactData(addUserForm);
          // addUserdialogVisible.value=false;
          // console.log(addUserForm);

        }
        if(DialogTitle.value=="删除用户"){
          // delUserData();
          // addUserdialogVisible.value=false;
          // initData();
        }
        addUserdialogVisible.value=false;
      })
    }
    //重置表单项内容
    function ResetAddUserFormData(){
      // addUserForm='';
      // addUserForm.clearValidate();

      // addUserForm.address='';
      addUserFormRef.value.resetFields();

      // console.log("  ResetAddUserFormData     ===============>");
    }
    //添加关于我们 刷数据库函数
    async function EditeAboutUstoDb(Aboutus) {

      let sendAboutus=reactive({
        aboutus:Aboutus,
      });

      // console.log(sendAboutus);
      const {data: res} = await EditeAboutUs(sendAboutus);

      // console.log(res);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '添加关于我们失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '添加关于我们成功 : ' + res.msg,
          type: 'success',
        });
        initDBData();
      }
    }
    //添加经纬度 刷数据库函数
    async function EditLocationtoDb(location) {

      // console.log(location);
      const {data: res} = await EditeLocation(location);


      console.log(res);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '添加经纬度失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '添加经纬度成功 : ' + res.msg,
          type: 'success',
        });
        initDBData();
      }
    }

    //使能联系人到数据库按键函数
    function switchStateKey(stateSwitch){
      let contact=reactive({
        id:stateSwitch.id,
        status:stateSwitch.status,
      });

      EnableContactData(contact);
    }
    //添加联系人 刷数据库函数
    async function EnableContactData(EnableContact) {

      const {data: res} = await EnableEditeContacttoDb(EnableContact);

      // console.log(res);
      if (res.code!=200) {
        ElMessage({
          showClose: true,
          message: '使能联系人失败 : '+res.msg,
          type: 'error',
        });
      }else {
        ElMessage({
          showClose: true,
          message: '使能联系人成功 : '+res.msg,
          type: 'success',
        });
        initDBData();
      }

    }
    //添加联系人 刷数据库函数
    async function postContactData() {

      const {data: res} = await AddContacttoDb(addUserForm);

      //  console.log('---------------------------------->');
      // console.log(addUserForm);
      //  console.log(res);

      if (res.code!=200) {
        ElMessage({
          showClose: true,
          message: '添加联系人数据失败 : '+res.msg,
          type: 'error',
        });
      }else {
        ElMessage({
          showClose: true,
          message: '添加联系人数据成功 : '+res.msg,
          type: 'success',
        });
        initDBData();
      }

    }
    //编辑联系人 刷数据库函数
    async function EditeContactData() {

      const {data: res} = await EditeContacttoDb(addUserForm);
      //  console.log('---------------------------------->');
      //  console.log(res);
      // console.log(res.code);
      if (res.code!=200) {
        ElMessage({
          showClose: true,
          message: '编辑联系人数据失败 : '+res.msg,
          type: 'error',
        });
      }else {
        ElMessage({
          showClose: true,
          message: '编辑联系人数据成功 : '+res.msg,
          type: 'success',
        });
        initDBData();
      }
    }





    return {
      //longitude,latitude,
      companyinfotableoptions,formSize,
      companypath,switchStateKey,
      tableData,aboutus,location,locationRules,locationRef,
      addcontactKey,editcontactKey,ResetAddUserFormData,addContactCheck,
      DialogTitle,addUserFormRef,addUserForm,addUserdialogVisible,addUserFormRules,
      setlocationKey,EditAboutus,
      ensureDialogVisible,ensureDialog,
      enAboutusDialogVisible,ensureAboutusDialog,

    };
  }
}
</script>

<style scoped>

.box-card{
  margin-top: 6px;
}

.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}

.el-table{
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 15px;
  color: #0527af;
}

/deep/ .el-input__inner {
  color: #0527af;
  font-size: 15px;
}
/deep/ .el-textarea__inner{
  color: #0527af;
  font-size: 15px;
}
/deep/ .el-divider__text{
  color: #0527af;
}
/deep/ .--el-border-color{
  color: #0527af;
}
.aboutusdiv p{
  color: #0527af;
  font-size: 18px;
  user-select: none;
  /*font-size: 0.12rem;*/
  font-family:'阿里巴巴普惠体 ';
  /*text-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;*/
  line-height: 26px;
  /*text-indent: 2em;*/
  /*padding: 0px 1px;*/

  text-align:left;
  margin: 0;
  padding: 0;

}
/*/deep/ .el-disabled-text-color{*/
/*  color: #0527af;*/
/*}*/
/*/deep/ .el-text-color-placeholder{*/
/*  color: #0527af;*/
/*}*/
</style>
