<template>
  <rightmainhead  :fromFather =companynewpath> </rightmainhead>
<!--<h1>公司新闻</h1>-->
<!-- +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
  <el-row :gutter="20">

    <el-col  :span="10">
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 新闻标题 副标题 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>

    <el-col :span="2" :offset="12">
      <div>
        <el-button type="primary" @click="addNewsKey">添加新闻</el-button>
      </div>
    </el-col>
  </el-row>
  <!--  新闻表格  开始  -->
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格  -->
    <el-table :data="NewstableData" border stripe style="width: 100%" @cell-click="NewstableDetailEdit">

      <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in newsinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small"     type="primary"     @click="addNewsKey(row)"  >添加</el-button>
          <el-button size="small"     type="warning"     @click="editNewsKey(row)"  >编辑</el-button>
          <el-button size="small"     type="danger"      @click="delNewsKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>


        <!--   新闻详情   v-html="row.newsdetails"  {{row.newsdetails}}  -->
        <template v-slot="{row}" v-if="item.prop==='newsdetails'">
          <div   class="divtext"  >
            {{HtmldisplayText(row.newsdetails)}}
            <!--  去除 html 标签          -->
            <!--            {{row.newsdetails.replace(/<[^>]+>/g, '')}}-->
            <!--            {{row.newsdetails.replace(/<\/?.+?\/?>/g, '')}}  . .replace(/&nbsp;/ig, "") .replace(/&nbsp;/ig, "") -->
            <!--            {{row.newsdetails.replace(/<[^>]+>|<\/?.+?\/?>|\r|\n|^\s*/g, '').replace(/&nbsp;/ig, "")}}-->
            <!--            {{row.newsdetails.replace(/<[^>]+>|<\/?.+?\/?>|\r|\n|^\s*/g, "").replace(/&nbsp;/ig, "").trim() }}-->
            <!--            {{row.newsdetails.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, "") }}-->

          </div>
        </template>
        <!--   状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='newsstatus'">
          <el-switch
              v-model="row.newsstatus"
              active-value="1"
              inactive-value="0"
              @change="NewsEnableswitchStateKey(row)"></el-switch>
        </template>


      </el-table-column>

    </el-table>

    <!--   分页器  ------------------------------------->
    <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
                 handleCurrentChange 页码值发生改变
                 currentPage  当前显示的第页面数
                 page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="Newstotal"
                       @size-change="handleSizePagesChange"
                       @current-change="handleCurrentPageChange"
        />
      </el-col>
    </el-row>
  </el-card>
  <!--  新闻表格  结束   -->

  <!--  添加新闻对话框 开始 -->
  <el-dialog
      v-model="AddNewsdialogVisible"
      :title="AddNewsDialogTitle"
      width="100%"
      @close="AddNewsDialogClose"
      destroy-on-close="true"
      :show-close="false"
  >
    <!--   内容主体区      -->
    <el-form
        ref="newsFormRef"
        :model="newsForm"
        :rules="newsFormRules"

        label-width="10%"
        :size="formSize"
        status-icon
    >
      <template v-if="AddNewsDialogTitle=='编辑新闻'">
        <el-form-item label="新闻标题"  prop="newstile">
          <el-input v-model="newsForm.newstile" />
        </el-form-item>
        <el-form-item label="新闻副标题"  prop="newssubtile">
          <el-input v-model="newsForm.newssubtile" />
        </el-form-item>
        <el-form-item label="新闻作者"  prop="newsauthor">
          <el-input v-model="newsForm.newsauthor" />
        </el-form-item>
        <!--        <el-form-item label="新闻详情"  prop="newsdetails">-->
        <!--          <el-input v-model="newsForm.newsdetails" />-->
        <!--        </el-form-item>-->

      </template>
      <template v-if="AddNewsDialogTitle!=='编辑新闻'">
        <el-form-item label="新闻标题"  prop="newstile">
          <el-input v-model="newsForm.newstile" />
        </el-form-item>
        <el-form-item label="新闻副标题"  prop="newssubtile">
          <el-input v-model="newsForm.newssubtile" />
        </el-form-item>
        <el-form-item label="新闻作者"  prop="newsauthor">
          <el-input v-model="newsForm.newsauthor" />
        </el-form-item>
      </template>
    </el-form>
    <!--  新闻详情  -------------------------------------------------------- -->
    <el-row :gutter="2" style="margin-top: 0px;">
      <el-col :span="4" >
        <el-divider  > 新闻详情 </el-divider>
      </el-col>
      <el-col :span="20" >
        <el-divider content-position="right" ></el-divider>
      </el-col>
    </el-row>
    <!--  新闻详情 编辑区 -------------------------------------------------------- -->
    <wangeditcom
        :context="newstext"
        :mainid="newsID"
        @ensureDialog="ensureNewsDetailsDialog"
        @ensureDialogCancel="ensureNewsDetailsCancelDialog"
    >
    </wangeditcom>

  </el-dialog>
  <!--  添加新闻对话框 结束 -->
  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDelDialogVisible"
             context="确认将新闻删除 ？ ^L^"
             title="删除新闻数据"
             @ensureDialog="ensureDelDialog"
  ></tipdialog>

  <!--     新闻预览  对话框  -->
  <el-dialog
      v-model="PreViewNewsDetailsDialogVisible"
      title="新闻预览"
      width="100%"
      @close="NewsPreviewDialogClose"
  >
    <div class="newstitle">{{ newsForm.newstile }}</div>
    <div class="subtitle">{{newsForm.newssubtile}}</div>
    <div class="newdate">{{newsForm.newsdate}}</div>

    <div class="newdate" v-if="newsForm.newsauthor" >作者：{{newsForm.newsauthor}}</div>

    <p class="newdetailsdiv" v-html="newsForm.newsdetails"> </p>
    <!--    <span>图片 </span>-->
    <!--    <img class="imgpreview" :src="PreViewImgUrl" alt="">-->
  </el-dialog>



  <!-- +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
</template>

<script>
import Rightmainhead from "@/components/rightareahead/rightareahead";
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import {newsinfotableoptions} from '@/views/webmanage/rightarea/news/newsinfotable'
import {newslist,addnewscontent,editnewscontent,gonenews,updatarelationid,newsstatus} from "@/axios/webindex";

import Tipdialog from "@/components/rightareahead/tipdialog";
import Wangeditcom from "@/components/rightareahead/wangeditcom";

import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue';
import {onMounted, ref,reactive} from "vue";
import { ElMessage } from 'element-plus';
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export default {
  name: "companynews",
  components: {Rightmainhead,Wangeditcom,Tipdialog},

  setup() {
    let companynewpath = [
      {  name: '首页',     path: 'AdminHome'     },
      {  name: '新闻管理',  path: 'companynews'    },
      {  name: '新闻信息',  path: 'companynews'    },
      {  name: '公司新闻',  path: 'companynews'    }
    ];
    //------------------------------------------------------------------------------------
    let queryPages=reactive({
      newstype:'2',  //============================   修改 新闻类别
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    //表格参数
    let NewstableData=ref([]);
    let Newstotal=ref();
    //添加新闻对话框参数
    let AddNewsdialogVisible=ref(false);
    let AddNewsDialogTitle=ref('添加新闻对话框');
    let newsFormRef=ref();
    let newsForm=reactive({
      newstype:'2',    //============================   修改 新闻类别
      newsidtemp:'',
      id:'',
      newstile:'',
      newssubtile:'',
      newsauthor:'',
      newsdetails:'',
      newsdate:'',
      newsstatus:'',
    });
    let newsFormRules=reactive({
      newstile:[
        { required: true, message: 'Please input newstile', trigger: 'blur' },
        { min: 1, max: 200, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      newsdetails:[
        { required: true, message: 'Please input newsdetails', trigger: 'blur' },
        { min: 1, max: 1000, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
    });
    //确认对话框参数
    let ensureDelDialogVisible=ref(false);
    let PreViewNewsDetailsDialogVisible=ref(false);

    let newstext=ref(0);
    let newsID=ref();



    onMounted(()=>{
      initNewsData();
    })
    //初始化新闻表格函数
    async function initNewsData() {

      let res = await newslist(queryPages)
      {
        // console.log(res);

        if (res.data.code == 200) {

          Newstotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;
          // console.log(ProductstableData.value);
          // console.log('=============================');

          let type = Object.prototype.toString.call(res.data.data.data);

          if (type === "[object Array]"){
            //   console.log('是一个数组');
            NewstableData.value=res.data.data.data;
          }
          if (type === "[object Object]"){
            // console.log('是一个对象');
            let arr = [];   //对象转成数组
            for (let i in res.data.data.data) {
              arr.push(res.data.data.data[i]);
              // console.log(res.data.data.data[i]);
              // ProductstableData.value
            }
            NewstableData.value=arr;
          }

        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取广告信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }

    }
    // function findVideoAddID(TextTemp){
    //   let Index;
    //   // let inputString=ref(" id=\"videoid\" ");
    //   let inputString=ref(" controlslist=\"nodownload\" ");
    //   let StringTemp=ref('');
    //   let ExistIndexArray=ref([]);
    //   let BufferArray=ref([]);
    //
    //   Index=TextTemp.indexOf("storage\/textvideos\/");
    //   if(Index!=-1) ExistIndexArray.value.push(Index);
    //   while (Index!=-1)
    //   {
    //     Index=TextTemp.indexOf("storage\/textvideos\/",Index+6);
    //     if(Index!=-1) ExistIndexArray.value.push(Index);
    //   }
    //   //进行插入ID处理
    //   for(Index=0;Index<ExistIndexArray.value.length;Index++){
    //
    //     // StringTemp.value=TextTemp.substring(0,(ExistIndexArray.value[Index]))+inputString.value+TextTemp.substring(ExistIndexArray.value[Index]+1);
    //     // StringTemp.value=TextTemp.substr(ExistIndexArray.value[Index]+5,20);
    //     // StringTemp.value=StringTemp.value.substring(0,StringTemp.value.lastIndexOf('"'));
    //     // BufferArray.value.push(StringTemp.value); //13
    //
    //     StringTemp.value=TextTemp.substr(ExistIndexArray.value[Index]+19,20);
    //     StringTemp.value=StringTemp.value.substring(0,StringTemp.value.lastIndexOf('"'));
    //     BufferArray.value.push(StringTemp.value); //13
    //
    //   }
    //   console.log(StringTemp.value);
    //   console.log(ExistIndexArray);
    // }
    //表格框内点击调用函数
    function NewstableDetailEdit(row,column,cell,event){

      //新闻详情预览
      if(column.property=='newsdetails'){

        newsForm.newstile=row.newstile;
        newsForm.newssubtile=row.newssubtile;
        newsForm.newsauthor=row.newsauthor;
        newsForm.newsdate=row.newsdate;

        newsForm.newsdetails=row.newsdetails;
        //查找文本内的视频 添加ID名称。
        // newsForm.newsdetails=
        //     findVideoAddID(row.newsdetails);
        PreViewNewsDetailsDialogVisible.value=true;
      }
      //图片对话框
      if(column.property=='newsimage'){

      }
    }
    //表格内添加按键调用函数
    function addNewsKey(RowTemp){
      AddNewsdialogVisible.value=true;
      AddNewsDialogTitle.value='添加新闻';
      newsForm.newsdetails='';
      newsForm.id='';
      newsForm.newstile='';
      newsForm.newssubtile='';
      newsForm.newsauthor='';
      newsForm.newsdetails='';
      newstext.value='';
      newsID.value='';
    }
    //表格内编辑按键调用函数
    function editNewsKey(RowTemp){
      AddNewsdialogVisible.value=true;
      AddNewsDialogTitle.value='编辑新闻';
      newsForm.newsdetails='';
      newsForm.id=RowTemp.id;
      newsForm.newstile=RowTemp.newstile;
      newsForm.newssubtile=RowTemp.newssubtile;
      newsForm.newsauthor=RowTemp.newsauthor;
      newsForm.newsdetails=RowTemp.newsdetails;
      newstext.value=newsForm.newsdetails;
      newsID.value=RowTemp.id;
    }
    //表格内删除按键调用函数
    function delNewsKey(RowTemp){
      ensureDelDialogVisible.value=true;
      newsForm.id=RowTemp.id;
    }
    ////表格内删除按键对话框回调用函数
    async function ensureDelDialog(DataTemp){

      let GonenewsID=reactive({
        id:'',
      });

      ensureDelDialogVisible.value=false;
      if(DataTemp==1){
        GonenewsID.id=newsForm.id;
        //删除新闻
        // console.log('=======================>');
        const {data: res} = await gonenews(GonenewsID);
        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '更改数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '更改数据成功 : ' + res.msg,
            type: 'success',
          });
          //从服务中取出
          initNewsData();
        }
      }
    }
    //新闻状态改变
    async function NewsEnableswitchStateKey(RowTemp) {
      // console.log('========>');
      newsForm.id = RowTemp.id;
      newsForm.newsstatus = RowTemp.newsstatus;
      const {data: res} = await newsstatus(newsForm);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        initNewsData();
      }
    }
    //新闻图片预览
    function Newspicpreview(RowTemp){

    };
    //关闭新闻添加对话框回调函数
    function AddNewsDialogClose(){
      AddNewsdialogVisible.value=false;
      newsFormRef.value.resetFields();
      // console.log('=============== AddNewsDialogClose');
    }

    //编辑新闻取消回调函数
    function ensureNewsDetailsCancelDialog(DataTemp){
      AddNewsdialogVisible.value=false;
      newsFormRef.value.resetFields();
      // console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqq');
    }
    //编辑新闻回调函数   //新闻对话框提交
    function ensureNewsDetailsDialog(TextTemp,NewsIDTemp){
      newsForm.newsdetails=TextTemp;
      newsForm.newsidtemp=NewsIDTemp;
      // console.log('666666666666666666666666');

      newsFormRef.value.validate( valid => {
        // console.log(valid);
        if (!valid) return;  //数据不对返回
        //  添加用户的表单请求
        if(AddNewsDialogTitle.value=="添加新闻"){

          // console.log(newsForm.newstile);
          AddnewsToDB(newsForm);
        }
        if(AddNewsDialogTitle.value=="编辑新闻"){
          editNewsToDb(newsForm);
        }
        AddNewsdialogVisible.value=false;
      })

      // newsFormRef.value.resetFields();
      //新闻内容存数据库

    }
    //编辑新闻内容
    async function editNewsToDb(DataTemp) {

      const {data: res} = await editnewscontent(DataTemp);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出
        initNewsData();
      }
    }
    //添加新闻到数据库
    async function AddnewsToDB(DataTemp) {
      let IDvalue=reactive({
        OriginID:'',
        NewID:'',
      });
      const {data: res} = await addnewscontent(DataTemp);
      // console.log(res);
      IDvalue.NewID = res.data;
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        // console.log('newsForm.newsidtemp',newsForm.newsidtemp);
        // console.log('newsForm.newsidtemp-----',newsForm.newsidtemp.length);
        // console.log(IDvalue.NewID);
        if(newsForm.newsidtemp.length!=0){
          //新增的图片或者视频的关联ID与新闻文本内的ID对不上。进行对图片和视频的关联ID更改
          if(res.data != newsForm.newsidtemp) {
            IDvalue.OriginID = newsForm.newsidtemp;


            const {data: res} = await updatarelationid(IDvalue);

            // console.log(res);

            if (res.code != 200) {
              ElMessage({
                showClose: true,
                message: '更改数据失败 : ' + res.msg,
                type: 'error',
              });
            } else {
              ElMessage({
                showClose: true,
                message: '更改数据成功 : ' + res.msg,
                type: 'success',
              });
            }
          }
        }

        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出
        initNewsData();


      }
    }
    //// 图片不存在显示图片 图片载入出错调用函数
    function defaultImg(a,event){
      let img = event.srcElement;
      img.src =require('@/assets/weblogo.jpg');
      img.onerror = null
    }
    //分页器总页数改变回调函数
    function handleSizePagesChange(){

    };
    //分页器当前页数改变回调函数
    function handleCurrentPageChange(){
      initNewsData();
    };
    function serchKey(){
      initNewsData();
    };

    function serchClearKey(){
      queryPages.serch='';
      initNewsData();
    }

    function HtmldisplayText(HtmlString){
      let StringTemp=ref('');

      StringTemp.value=HtmlString.replace(/<\/?.+?\/?>/g, '');
      //.replace(/<[^>]+>/g, '');
      HtmlString=StringTemp.value.replace(/\r|\n|\s/g, '');
      StringTemp=HtmlString.replace(/&nbsp;/ig, "");
      // console.log(StringTemp,'=======================',StringTemp.length);
      if(StringTemp.length==0){
        StringTemp='这是个视频...';
      }
      return StringTemp;
      // return HtmlString.replace(/&nbsp;/ig, "");

    }
    //新闻预览关闭回调函数
    function NewsPreviewDialogClose(){
      //controlslist=“nodownload nofullscreen noremoteplayback”
      // var ctx = this.selectComponent('.newdetailsdiv')
      //  // 播放过的视频的 VideoContext 对象都会被存储在 _videos 中
      //  if (ctx._videos) {
      //    for (var video of ctx._videos) {
      //      video.pause()
      //    }
      //  }
      document.getElementById('videoid');

      PreViewNewsDetailsDialogVisible.value=false;
    }
    //------------------------------------------------------------------------------------
    return {
      companynewpath,
      Search,queryPages,addNewsKey,serchKey,serchClearKey,
      newsinfotableoptions,NewstableData,Newstotal,handleSizePagesChange,handleCurrentPageChange,NewsEnableswitchStateKey,editNewsKey,delNewsKey,Newspicpreview,NewstableDetailEdit,defaultImg,
      AddNewsdialogVisible,AddNewsDialogTitle,AddNewsDialogClose,newsFormRef,newsForm,newsFormRules,
      ensureDelDialogVisible,ensureDelDialog,
      ensureNewsDetailsDialog,ensureNewsDetailsCancelDialog,newstext,newsID,
      PreViewNewsDetailsDialogVisible,
      HtmldisplayText,
      NewsPreviewDialogClose,
    };
  }
}
</script>

<style scoped>
.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}
/*  上传完图片后出现预览和删除图标 */
/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {
  margin-left: 0.4rem !important;
}

.box-card{
  margin-top: 6px;
}
.imagebox-card{
  margin-top: 6px;
}
.pdfbox-card{
  margin-top: 6px;
}
.el-upload__tip{
  margin-bottom: 6px;
}
.el-input{
  margin: 8px;
}

.imgpreview{
  width: 100%;
  height: 100%;
}

.hide >>> .el-upload--picture-card {
  display: none;
}

.newdetailsdiv p {
  /*color: #0527af;*/
  /*font-size: 18px;*/
  user-select: none;
  /*font-size: 0.12rem;*/
  font-family: '阿里巴巴普惠体 ';
  /*text-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;*/
  /*line-height: 26px;*/
  text-indent: 2em;
  /*padding: 0px 1px;*/

  text-align: left;
  margin: 0;
  padding: 0;
}


.newstitle{
  margin: 0 auto;
  width: 60%;
  font-size: 0.15rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:800;
  line-height: 0.3rem;
}
.subtitle{
  margin: 0 auto;
  width: 60%;
  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  /*font-weight:500;*/
  line-height: 0.15rem;
}
.newdate{
  margin: 0 auto;
  width: 60%;
  font-size: 0.1rem;
  font-family:'阿里巴巴普惠体 B';
  line-height: 0.15rem;
}
.divtext{
  /*width:250px;*/
  height:0.15rem;
  /*border:1px solid #345123;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor:pointer;
}

</style>
