//全局需要修改的数据   主页面 banner 图片
const gsproducttypetitlestore = {
    //数据
    state: {
        producttypetitle:[],
    },
    //计算属性
    getters: {

    },
    //修改状态的方法
    mutations: {

        // setUsername:function (state){
        //     state.userName=1;
        // },
        // setUserage(state,newage){
        //     state.age=newage;
        // },

    },
    actions: {

        // asyncSetUserinfo(context){
        //     //调用  mutations中的 setUserage
        //     context.commit('setUserage',SetNewage)
        // }
    },
};

export default gsproducttypetitlestore;
