<template>
	<div id="navcontent">
	  <ul >
<!--      <template v-if="$route.">  ,$event-->
<!--          <li class="navname"  v-for="(navlisitem,i) in webnavlist" :key="i"  @click="navbutton(i)" :class="'navID'+i"-->
<!--              :style=navtextbackcolor-->

<!--          >{{navlisitem}}</li>-->
<!--      $router.push('/')-->
<!--      $router.push({path:'/aboutus'})-->

      <router-link to="/">        <li class="navname" @click="navbutton(0)" @mouseover="mouseover(0)" :style="[textcolornav0,bgcolornav0]">{{webnavlist[0]}}  </li> </router-link>
      <router-link to="/aboutus"> <li class="navname" @click="navbutton(1)" @mouseover="mouseover(1)" :style="[textcolornav1,bgcolornav1]">{{webnavlist[1]}}  </li> </router-link>
      <router-link to="/products"><li class="navname" @click="navbutton(2)" @mouseover="mouseover(2)" :style="[textcolornav2,bgcolornav2]">{{webnavlist[2]}}  </li> </router-link>
      <router-link to="/news">    <li class="navname" @click="navbutton(3)" @mouseover="mouseover(3)" :style="[textcolornav3,bgcolornav3]">{{webnavlist[3]}}  </li> </router-link>
      <router-link to="/connect"> <li class="navname" @click="navbutton(4)" @mouseover="mouseover(4)" :style="[textcolornav4,bgcolornav4]">{{webnavlist[4]}}  </li> </router-link>
      <router-link to="/message"> <li class="navname" @click="navbutton(5)" @mouseover="mouseover(5)" :style="[textcolornav5,bgcolornav5]">{{webnavlist[5]}}  </li> </router-link>
      <!--      </template>-->
<!--		<li>首页</li>-->
<!--		<li>关于我们</li>-->
<!--		<li>产品方案</li>-->
<!--		<li>新闻中心</li>-->
<!--		<li>联系我们</li>-->
<!--		<li>用户留言</li>-->
	  </ul>
<!--    {{navcontexColor0}}-->
<!--    <div :style=navtextbackcolor>12345</div>-->
<!--    <div :class="testObj">78900</div>-->
  </div>
  <router-view ></router-view>


</template>

<script>

import {ref, reactive, onBeforeMount, onMounted, computed,} from 'vue';
import {useStore} from 'vuex';
import {useRoute,useRouter} from 'vue-router'
import {watch} from "vue";
import router from "@/router";

export default {


  setup(){
    let navlist=['首页','关于我们','产品方案','新闻中心','联系我们','用户留言'];
    let Ennavlist=['Home','About us','Products','News','Connect','Messages'];
    let webnavlist=ref(0);
    let navitemcount=0xFE;
    let navtextbackcolor=ref(0);

    let textcolornav0=ref(['color:#0527af']);
    let bgcolornav0  =ref(['backgroundColor:#FFFFFF']);
    let textcolornav1=ref(['color:#0527af']);
    let bgcolornav1=  ref(['backgroundColor:#FFFFFF']);
    let textcolornav2=ref(['color:#0527af']);
    let bgcolornav2=  ref(['backgroundColor:#FFFFFF']);
    let textcolornav3=ref(['color:#0527af']);
    let bgcolornav3=  ref(['backgroundColor:#FFFFFF']);
    let textcolornav4=ref(['color:#0527af']);
    let bgcolornav4=  ref(['backgroundColor:#FFFFFF']);
    let textcolornav5=ref(['color:#0527af']);
    let bgcolornav5=  ref(['backgroundColor:#FFFFFF']);
    let navdatatemp=1;
    let navitChangBit=0;
    let effectTemp=1;
    let gsnavcountTemp=0xff;
    // let navID0=ref(0);
    // let id=[];,
    const store=useStore();
    const gsroute=useRoute();
    const gsrouter=useRouter();

    // let id=gsroute.params.id;
    // watch(()=>gsroute.params,(newid)=>{
    //   console.log(newid.id);
    // });
    //带参数跳转
    // router.push({path:'/home/userinfor',query:{name:'Jimfore','keyword':'older man'}});




    onMounted(()=>{
      if(store.state.displaylanguage==0){
        webnavlist.value=navlist;
        // console.log(store.state.displaylanguage);
      }else
      {
        webnavlist.value=Ennavlist;
        // console.log(store.state.displaylanguage);
      }
      // console.log(id);
      navbutton(0);resetchangeObj0();
    });



    function resetchangeObj0(){textcolornav0.value=['color:#FFFFFF'];bgcolornav0.value=['backgroundColor:#0527af']; }
    function setchangeObj0()  {textcolornav0.value=['color:#0527af'];bgcolornav0.value=['backgroundColor:#FFFFFF']; }
    function resetchangeObj1(){textcolornav1.value=['color:#FFFFFF'];bgcolornav1.value=['backgroundColor:#0527af']; }
    function setchangeObj1()  {textcolornav1.value=['color:#0527af'];bgcolornav1.value=['backgroundColor:#FFFFFF']; }
    function resetchangeObj2(){textcolornav2.value=['color:#FFFFFF'];bgcolornav2.value=['backgroundColor:#0527af']; }
    function setchangeObj2()  {textcolornav2.value=['color:#0527af'];bgcolornav2.value=['backgroundColor:#FFFFFF']; }
    function resetchangeObj3(){textcolornav3.value=['color:#FFFFFF'];bgcolornav3.value=['backgroundColor:#0527af']; }
    function setchangeObj3()  {textcolornav3.value=['color:#0527af'];bgcolornav3.value=['backgroundColor:#FFFFFF']; }
    function resetchangeObj4(){textcolornav4.value=['color:#FFFFFF'];bgcolornav4.value=['backgroundColor:#0527af']; }
    function setchangeObj4()  {textcolornav4.value=['color:#0527af'];bgcolornav4.value=['backgroundColor:#FFFFFF']; }
    function resetchangeObj5(){textcolornav5.value=['color:#FFFFFF'];bgcolornav5.value=['backgroundColor:#0527af']; }
    function setchangeObj5()  {textcolornav5.value=['color:#0527af'];bgcolornav5.value=['backgroundColor:#FFFFFF']; }
      // console.log(123456789);


     function mouseover(mousetemp){
       // console.log(111+mousetemp);
     }



    //,event
    function navbutton(keytemp){
		setchangeObj0();
		setchangeObj1();
		setchangeObj2();
		setchangeObj3();
		setchangeObj4();
		setchangeObj5();


// gsrouter.push('/');
// gsrouter.push('/aboutus');
// gsrouter.push('/products');
// gsrouter.push('/news');
// gsrouter.push('/connect');
// gsrouter.push('/message');



      // switch (keytemp){
      //   case 0:{resetchangeObj0();gsrouter.push('/');  break;}
      //   case 1:{resetchangeObj1();gsrouter.push('/aboutus'); break;}
      //   case 2:{resetchangeObj2();gsrouter.push('/products');  break;}
      //   case 3:{resetchangeObj3();gsrouter.push('/news');  break;}
      //   case 4:{resetchangeObj4();gsrouter.push('/connect'); break;}
      //   case 5:{resetchangeObj5();gsrouter.push('/message'); break;}
      // }
      switch (keytemp){
        case 0:{resetchangeObj0();break;}
        case 1:{resetchangeObj1();break;}
        case 2:{resetchangeObj2();break;}
        case 3:{resetchangeObj3();break;}
        case 4:{resetchangeObj4();break;}
        case 5:{resetchangeObj5();break;}
      }
      // console.log(keytemp);
      // navitChangBit=0;
      navdatatemp=1;
      navitemcount=navdatatemp<<keytemp;//指定位为1
      navitemcount=~navitemcount;  //按位取反   不进行特效的动画为0
      navitChangBit=~navitemcount;
      // navitemcount=keytemp;
      // navtextbackcolor.value="background:yellow";
      navtextbackcolor.value="color:yellow";
      // console.log(keytemp);


    }

    setInterval(()=>{

      if(store.state.displaylanguage==1){
        webnavlist.value=navlist;
        // console.log(store.state.displaylanguage);
      }else
      {
        webnavlist.value=Ennavlist;
        // console.log(store.state.displaylanguage);
      }
      ;

      if(store.state.displaystye==0){

        if(effectTemp!=0x00){
            effectTemp=0;
          // console.log(navitemcount);
            setchangeObj0();setchangeObj1();setchangeObj2();setchangeObj3();setchangeObj4();setchangeObj5();
          effectTemp=~navitemcount; //找到当前点击的位
          if(effectTemp&0x01){resetchangeObj0();}
          if(effectTemp&0x02){resetchangeObj1();}
          if(effectTemp&0x04){resetchangeObj2();}
          if(effectTemp&0x08){resetchangeObj3();}
          if(effectTemp&0x10){resetchangeObj4();}
          if(effectTemp&0x20){resetchangeObj5();}
          // console.log(navitemcount);
          effectTemp=0;
        }
      }else {
        //有特效
        effectTemp=1;
        if(navitemcount&0x01){
          if(navitChangBit&0x01){
            setchangeObj0();
          }else {resetchangeObj0()} ;
        }
        if(navitemcount&0x02){
          if(navitChangBit&0x02){
            setchangeObj1();
          }else {resetchangeObj1()} ;
        }
        if(navitemcount&0x04){
          if(navitChangBit&0x04){
            setchangeObj2();
          }else {resetchangeObj2()} ;
        }
        if(navitemcount&0x08){
          if(navitChangBit&0x08){
            setchangeObj3();
          }else {resetchangeObj3()} ;
        }
        if(navitemcount&0x10){
          if(navitChangBit&0x10){
            setchangeObj4();
          }else {resetchangeObj4()} ;
        }
        if(navitemcount&0x20){
          if(navitChangBit&0x20){
            setchangeObj5();
          }else {resetchangeObj5()} ;
        }
        navitChangBit=0xFF^navitChangBit;
      }
      if(gsnavcountTemp!=window.sessionStorage.getItem('gsnavcount')){
        gsnavcountTemp=window.sessionStorage.getItem('gsnavcount');
        navbutton(parseInt(gsnavcountTemp));
      }
      // console.log(timerarr);
      // console.log(gstimer.value);
    },500);
    return{webnavlist,navbutton,mouseover,navtextbackcolor,textcolornav0,bgcolornav0,textcolornav1,bgcolornav1,textcolornav2,bgcolornav2,textcolornav3,bgcolornav3,textcolornav4,bgcolornav4,textcolornav5,bgcolornav5}
  }
}
</script>

<style scoped>
/*.router-link-active{*/
/*  color:white;*/
/*  background-color:  #0527af;*/
/*}*/

	#navcontent{
    width: 10rem;
    height: 0.32rem;
    margin: 0 auto;
    /* 变成弹性盒第一步	   */
    /*display: flex;*/
    /* 水平居中 space-around;      */
    /*justify-content: space-around;*/
    /*background-color: #333333;*/
    /*background-color: #52E923;*/
	}
  #navcontent ul{
    padding: 0;
    /*background-color: #52E923;*/
    /*width: 7.6rem;*/
    width: 6.6rem;
    align-items: center;
    margin: 0 auto;
    font-size: 0.12rem;
    font-family:'阿里巴巴普惠体 B';
    font-weight:500;
    white-space: nowrap;
    display: flex;
    /* 水平居中 space-around;      */
    justify-content: space-around;
    text-shadow:0.001rem 0.001rem 0.001rem #0527af;
  }
  #navcontent li {
    /*width: 0.2rem;*/
    /*height: 100%;*/
    /*background-color: #52E923;*/
    /*border: 0.01rem solid #0527af;*/
    -webkit-user-select:none; /*webkit浏览器*/
    -khtml-user-select:none; /*早期浏览器*/
    -moz-user-select:none;/*火狐*/
    -ms-user-select:none; /*IE10*/
    user-select:none;

  }
  #navcontent .navname{
    /*background-color: #52E923;*/
    /*padding: 0.06rem 0.2rem;*/
    /*line-height: 100%;*/

    /*background-color: white;*/
    /*border-radius: 0.2rem;*/
    /*text-align: center;*/

    /*text-shadow: 0 0 0.01rem #3437f8, 0 0 0.02rem #3437f8;*/

    padding: 0.06rem 0.20rem;
    line-height: 100%;

    background-color: white;
    border-radius: 0.16rem;
    text-align: center;


  }
  #navcontent .navname:hover{
    color: white;
    background-color: #0527af;
    cursor: pointer;
  }


  /*.navname::after{*/
  /*  color: white;*/
  /*  background-color: #0527af;*/
  /*  cursor: pointer;*/
  /*}*/
</style>
