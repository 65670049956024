export const usersinfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '用户名',
        prop : 'username',
        width:200,
    },
    {
        label: '密码',
        prop : 'password',
        width:200,
    },
    {
        label: '邮箱',
        prop : 'useremail',
        // width:180,
    },
    {
        label: '电话',
        prop : 'userphone',
        // width:180,
    },
    {
        label: '状态',
        prop : 'userstatus',
        // width:180,
    },
    {
        label: '操作',
        prop : 'actions',
        // width:130,
    },

]
