<template>
  <div class="vbannercontent">
<!--  :propsadbinnertitle="subtitle" :propsadbinnerpics="gsadpicimformation.picimgs" :keycount="count"-->
    <div class="spacing" >
<!--      <span>蓝牙闹钟</span>-->
      <span>{{propsadbinnertitle}}</span>
      <span class="bluethinline"></span>
    </div>

    <div class="imgsdetail" >
      <swiper
          :direction="'vertical'"
          :slidesPerView="1"
          :spaceBetween="30"

          :lazy="true"
          :mousewheel="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"


          :pagination="{
           clickable: true,
          }"
          :modules="modules"
          @swiper="onSwiper"
          @mouseover="mouseover"
          @mouseout="mouseout"
      >


<!--        <swiper-slide    v-for="(item,i) in $store.state.gswebbannerstore.bannerimgs" :key="i" >-->
<!--          <img :src="item" @click="imagKeydown(i)"  class="swiper-lazy">-->

          <swiper-slide    v-for="(item,i) in piclist" :key="i" >
            <img :src="item" @click="imagKeydown(i)"  class="swiper-lazy">
          <div  class="swiper-lazy-preloader swiper-lazy-preloader-white" ></div>

        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

// ===============================================================
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import 'swiper/css/mousewheel';
import "swiper/css/lazy";

// import required modules
import { Pagination,Mousewheel,Autoplay,Lazy } from "swiper";
import {ref, reactive, onMounted, toRefs, onUnmounted, computed} from "vue";

export default {
  name: 'verticalbanner',

  components: {
    Swiper,
    SwiperSlide,
  },
  props:{
    propsadbinnertitle:String,
    propsadbinnerpics:Array,
    propsadbinnerpicurl:Array,
    keycount:Number,
  },
  setup(props,context) {

    let piclist=ref([]);
    let picurl=ref([]);
    // let displayflag=ref(false);

    computed(()=>{

      // console.log( props.propsadbinnerpics);
    });
    //=============================================================
    const gspicdata = reactive({
      controlledSwiper: null
    });

    //-----------------------------------------------------------------
    const onSwiper = (swiper) => {
      // console.log(swiper);
      gspicdata.controlledSwiper=swiper;
    };
    //------------------------------------------------------------------------

    onMounted(()=>{
      // console.log(propsadbinnertitle);
      let loop,jump=0;
      for(loop=0;loop<props.propsadbinnerpics.length;loop++)
      {

        if(props.propsadbinnerpics[loop]=='null')
        {
          jump++;
          if(jump>props.keycount){  //数据取完
            // console.log('=================================>');
            loop=props.propsadbinnerpics.length;
            break;
          }else {
            piclist.value.length=0;
          }
        }else{
          piclist.value.push(props.propsadbinnerpics[loop]);
          picurl.value.push(props.propsadbinnerpicurl[loop]);
        }
        // console.log(piclist.value);
      }
      // console.log('+++++++++++++++++++++++++++++++++>');
      // console.log( props.propsadbinnerpics.length);
    });

    onUnmounted (()=>{

    });
    function mouseover(){
      // console.log(11111111111+'========>');
      gspicdata.controlledSwiper.autoplay.stop();
    };
    const mouseout=()=>{
      // console.log(22222222222+'========>');
      gspicdata.controlledSwiper.autoplay.start();
    }
    //跳转到指定页面
    function imagKeydown(keyIndex){
      // console.log(keyIndex+'========>'+picurl.value[keyIndex]);
      //  console.log('========>',keyIndex);

      context.emit('getpage', keyIndex);
    }

    //=========================================

    return {
      onSwiper,mouseover,mouseout,
      modules: [Mousewheel, Pagination,Mousewheel,Autoplay,Lazy],
      imagKeydown,gspicdata,piclist,picurl,
      // displayflag,
    };
  }
}
</script>

<style scoped>
.vbannercontent{

  width: 6.8rem;
  height:3.26rem;

  margin: 0 auto;
  /* 变成弹性盒第一步	   */
  /*display: flex;*/
  /*flex-direction: column; */
  /*align-items:center;*/


}
.spacing{
  width: 6.8rem;
  height: 0.26rem;

  /*margin: 0 auto;*/
  display: flex;
  justify-content: space-around;
  align-items:center;
  /*background-color: #333333;*/
}
.spacing span{
  /*font-size: 0.4em;*/
  margin-left: 0.16rem;
  display: flex;
  line-height: 0.26rem;
  /*background-color: #52E923;*/
  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:500;
  white-space: nowrap;

  text-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;
  user-select: none;
}
.bluethinline{
  width: 80%;
  height: 20%;
  display: flex;

  /*align-items:center;*/
  /*margin: 0 auto;*/
  /*height: 50%;*/
  /*line-height: 0.26rem;*/
  /*margin-left: 0.068rem;*/
  /*line-height: 0.26rem;*/
  /*padding-bottom: 0rem;*/
  background-color: #0527af;
  border-radius: 0.02rem;
  box-shadow:0.02rem 0.02rem 0.02rem  #aeb1b3;
}

/*======================================================*/
.imgsdetail .swiper {
  width: 100%;
  height: 3.0rem;;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #333333;
}
/*.swiper-lazy-preloader-white {*/
/*  --swiper-preloader-color: #0527af;*/
/*}*/
</style>
