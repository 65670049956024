<template>
<!--   <h6>底部信息</h6>-->
  <div id="bottombox">
     <div id="bottominfomation">
        <span class="copy"><a href="http://www.goldenseal.cn/">©2021 深圳市金钤科技有限公司</a></span>
        <span class="bluck1">
         <a href="http://www.beian.gov.cn/portal/recordQuery?token=a4b8e368-e367-492b-a20d-9cfb5cdcca06" target="_blank" >
<!--            <img src="../../../public/commcss/country.png">-->
            <img src="@/assets/country.png">
            粤公网安备 44031102000687号
          </a>
       </span>
       <span class="bluck2"> <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" >粤ICP备2021158581号-1  </a></span>
     </div>
  </div>
</template>

<script>
export default {
  name: "bottom"
}
</script>

<style scoped>
   #bottombox{
     height: 0.68rem;
     width: 6.8rem;
     margin: 0 auto;
     /*align-items: center;*/
     /*line-height: 100%;*/
     /*margin-top: ;*/
     /*background: #52E923;*/
   }
   #bottominfomation{
     /*margin: 0 auto;*/
     margin-top: 0.1rem;
     border-radius: 0.05rem;
     height: 0.2rem;
     align-items: center;
     /*height: 100%;*/
     /*display: flex;*/
     /*justify-content: flex-end;*/
     /* 水平居中 space-around;      */
     /*justify-content: space-around;*/

     background: #0527af;
     font-size: 0.06rem;
     color: white;
      line-height:0.2rem; text-align:center;
     box-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;
   }
   #bottominfomation  a{
     color: white;
   }

    .bluck1 .bluck2{
     cursor: pointer;
     text-align: center;
     padding-right: 0.46rem;
     /*display:inline-block;*/
   }
   .bluck1 a img{
     display: inline-block;
     position:relative ;
     margin-bottom: -0.028rem;
   }
   span{
     margin-left: 0.2rem;

     /*display: inline-block; */
     /*vertical-align: 0.2rem;*/
   }

</style>
