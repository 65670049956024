import axios from "axios";


const gswebreqhead = axios.create({
    // const instance = axios.create({
    baseURL:"http://www.goldenseal.cn/",
    // baseURL:"http://www.tpvue.cn/",

    //  baseURL:"http://localhost:8080/",
    // baseURL:"https://api.apiopen.top/",
    // baseURL:"/api",
    timeout:5000,
});

//请求拦截器   添加 token头信息
gswebreqhead.interceptors.request.use(config=>{
    // console.log(config);
    config.headers.Authorization=window.localStorage.getItem('gstoken');
    // config.headers.Authorization=config.headers.Authorization;

    return config
},(err)=>{
    return Promise.reject(new Error(error));
})


//   //请求拦截为 通过验证的时候进行拦截
//    instance.interceptors.request.use(config=>{
//       return config;  //直接放行
//    }, onRejected:err=>{
//
// })
//
// instance.interceptors.response.use(res=>{
//     return res;
// }, onRejected:error=>{
//     //错误处理
// })
//这种导出 有问题 要用下面的那个导出
// export default {
//     gswebreqhead,
// }
// export default instance;
export default gswebreqhead;
