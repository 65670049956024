<template>
  <rightmainhead  :fromFather =bannerpath> </rightmainhead>
  <!--<h1>广告信息</h1>-->
<!-- --------------------------------------------------  -->
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格  -->
    <el-table :data="bannertableData" border stripe style="width: 100%" >
      <!--      第一列    -->
            <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in bannerinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small" :icon="Add"    type="primary"     @click="addBannerKey(row)"  >添加</el-button>
          <el-button size="small" :icon="Edit"   type="warning"     @click="editBannerKey(row)"  >编辑</el-button>
          <el-button size="small" :icon="Delete" type="danger"   @click="delBannerKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>
        <template  v-slot="{ row }"  v-if="item.prop==='image'">

          <img   :src="row.bannerurl" alt="" @click="tablepicpreview(row)" @error="defaultImg(a,$event)"
                 style="width: 100px;height: 60px; cursor:pointer">
        </template>

        <!--   状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='status'">
          <!--          <el-switch v-model="row.status" @change="switchStateKey(row)"></el-switch>-->
          <!--          <el-switch v-model="row.status ? false : true" @change="switchStateKey(row)"></el-switch>-->
          <el-switch
              v-model="row.status"
              active-value="1"
              inactive-value="0"
              @change="BannerswitchStateKey(row)"></el-switch>

        </template>

      </el-table-column>
      <!--      <el-table-column label='联系人' prop="朱飞云">     </el-table-column>-->
    </el-table>

    <!--   分页器  ------------------------------------->
<!--    <el-pagination background layout="prev, pager, next" :total="bannertotal" />-->

<!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
             handleCurrentChange 页码值发生改变
             currentPage  当前显示的第页面数
             page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="bannertotal"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
        />
       </el-col>
    </el-row>






  </el-card>
<!-- --------------------------------------------------  -->



  <!--   添加添加图片对话框对话框 开始  -->
  <el-dialog
      v-model="addBannerdialogVisible"
      :title="DialogTitle"
      width="40%"
      @close="closeUpload"
  >
    <!--   内容主体区 size="50%"  :rules="addBannerFormRules"   -->
    <el-form
        ref="addBannerFormRef"
        :model="addBannerForm"

        label-width="15%"
        status-icon
    >
      <template v-if="DialogTitle!=='编辑图片'">
        <el-form-item label="图片类别"  prop="bannername">
          <el-input disabled v-model="addBannerForm.bannername" />
        </el-form-item>
        <el-form-item label="图片地址"   prop="address" >
          <el-input disabled v-model="addBannerForm.bannerurl" />
        </el-form-item>
      </template>
      <template v-if="DialogTitle=='编辑图片'">
        <el-form-item label="图片类别"  prop="bannername"  >
          <el-input disabled  v-model="addBannerForm.bannername" />
        </el-form-item>
        <el-form-item label="图片地址"   prop="address" >
          <el-input disabled v-model="addBannerForm.bannerurl" />
        </el-form-item>

<!--        <span class="title"> 预览 </span>-->
          <div class="demo-fit" >
            <div class="block">
<!--              <div class="title"> 缩略图 </div>-->
<!--              <el-avatar shape="square" size="400" fit="cover" >  </el-avatar>-->
<!--              <img src="../../../assets/weblogo.jpg" alt="" style="width: 100px;height: 60%">-->
              <img :src="addBannerForm.bannerurl" @error="defaultImg(a,$event)"   alt="" style="width: 400px;height: 60%">
            </div>
          </div>
      </template>
      <!-- ================================================================= class="hide"  -->
      <el-row>
        <el-col :span="24"  >
          <el-upload
              :class="{hide:hideUpload}"
              ref="uploadref"
              :action="uploadURL"
              :headers="websittoken"
              :before-upload="beforVerifyUpload"
              :on-success="handleSuccess"
              :on-error ='uploadfileerror'
              :data="imgDate"

              :on-change="uploadchange"
              :on-preview="handlePreview"
              :on-remove="RemovePic"

              name="image"

              list-type="picture-card"
          >
            <el-button type="primary"  >点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                  {{imaglocaname}}
              </div>
              <div class="el-upload__tip">
                jpeg/jpg/png/gif 文件大小低于 2Mb
              </div>

            </template>
          </el-upload>
        </el-col>
      </el-row>
      <!--  =========================================================   -->
      <!--        <el-form-item label="创建时间" prop="create_time">-->
      <!--          <el-input v-model="addUserForm.create_time" />-->
<!--       limit="1"-->
<!--      :on-exceed="overlimit"-->
      <!--         </el-form-item>-->
    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetBannerFormData">清除</el-button>-->
          <el-button @click="addBannerdialogVisible = false">关闭对话框</el-button>
<!--          <el-button type="primary" @click="addBannerCheck">提交</el-button>-->

        </span>
    </template>
  </el-dialog>


  <!--     对话框预览图片  -->
  <el-dialog
      v-model="picdialogVisible"
      :title="picdialogTitle"
      width="80%"
  >
<!--    <span>图片 </span>-->
    <img class="picpreview" :src="ReviewPicUrl" alt="">
  </el-dialog>



  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDialogVisible"
             context="确认将图片删除 ？ ^L^"
             title="删除图片数据"
             @ensureDialog="ensureDelDialog"
  ></tipdialog>

</template>

<script  >
import {gsaddbannerpicwebaddress,gseditbannerpicwebaddress,} from '../websiteglobalvar'

import Rightmainhead from "@/components/rightareahead/rightareahead";
import {onMounted,computed, reactive, ref} from "vue";
import Tipdialog from "@/components/rightareahead/tipdialog";

import {bannerinfotableoptions} from './bannerinfotable'
import {bannerlist,bannergone,changebannerdisplay} from "@/axios/webindex";
import { ElMessage } from 'element-plus'




export default {
  name: "bannerinfo",
  components: {Tipdialog,Rightmainhead},

  setup() {
    let bannerpath = [
      {  name: '首页',     path: 'AdminHome'     },
      {  name: '广告管理',  path: 'bannerinfo'    },
      {  name: '广告图片',  path: 'bannerinfo'    }
    ];
    //删除图片对话框按键显示变量
    let ensureDialogVisible=ref(false);
    let deletpictureID=ref(0);

    let uploadURL=ref(gsaddbannerpicwebaddress);//ref("http://www.goldenseal.cn/website/bannerinfo/uploadpicture");
    let websittoken={
      Authorization:  window.localStorage.getItem('gstoken'),
    };

    //图片上传外带数据
    let imgDate=reactive({
      id:'',
      bannername:'',
    });
    let pics=ref([]);
    //图片预览对话框参数
    let picdialogVisible=ref(false);
    let picdialogTitle=ref(null);
    let ReviewPicUrl=ref();

    //图片添加上传对话框参数
    let uploadref=ref();
    let hideUpload=ref(false);


    let bannertableData=ref([]);
    let addBannerdialogVisible=ref(false);
    let DialogTitle=ref('编辑图片');
    let imaglocaname=ref('等您上传文件喔 ^L^');
    let addBannerFormRef=ref();
    let addBannerForm=reactive({
      id:'',
      bannername:'',
      bannerurl:'',
    });

    //分页器变量
    let bannertotal=ref(1);
    let queryPages=reactive({
        pagenumber:'1',
        pagesize:'5',
    })

    function addBannerKey(rowtemp){
      DialogTitle.value='添加图片';
      addBannerdialogVisible.value=true;
      addBannerForm.bannername=rowtemp.bannername;
      addBannerForm.bannerurl='';
      addBannerForm.id='';
      imgDate.id='';
      imgDate.bannername=rowtemp.bannername;
      uploadURL.value=gsaddbannerpicwebaddress;

    }
    function editBannerKey(rowtemp){
      DialogTitle.value='编辑图片';
      addBannerdialogVisible.value=true;
      addBannerForm.bannername=rowtemp.bannername;
      addBannerForm.bannerurl=rowtemp.bannerurl;
      imgDate.id=rowtemp.id;
      uploadURL.value=gseditbannerpicwebaddress;
      // addBannerForm.bannerurl=('/gspic'+rowtemp.bannerurl);

    }
    //表格删除图片确认对话框回调函数
    function ensureDelDialog(callbackstatus){
      ensureDialogVisible.value=false;

      if(callbackstatus==1){ //进行数据 服务器删除
        // console.log("-----------------"+callbackstatus);
        // console.log("-----------------"+deletpictureID.value);
        bannergonefun(deletpictureID.value,'');
      }else
      {
        //取消保存
        // console.log("----------subData-------"+callbackstatus);
      }
    }

    async function bannergonefun(id, url) {
      let goneData = reactive({
        id: id,
        bannerurl: url,
      });

      const {data: res} = await bannergone(goneData);

      // console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '删除数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '删除数据成功 : ' + res.msg,
          type: 'success',
        });
        initBannerData();
      }

    }

    ///表格删除图片  删除按键函数
    function delBannerKey(rowtemp){
      if(rowtemp.id<6){
        ElMessage({
          showClose: true,
          message: '只能删除图片和图片数据，不能删除其它的数据 ！',
          type: 'error',
        });
      }
      {
        ensureDialogVisible.value=true;
        deletpictureID.value=rowtemp.id;
      }

    }

    //使能图片
    async function BannerswitchStateKey(rowtemp) {

      let changeData = reactive({
        id: rowtemp.id,
        bannername: rowtemp.bannername,
        status: rowtemp.status,
      });

      // console.log(changeData);

      const {data: res} = await changebannerdisplay(changeData);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改状态数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改状态数据成功 : ' + res.msg,
          type: 'success',
        });

      }
      initBannerData();
    }
    function ResetBannerFormData(){

    }
    function addBannerCheck(){

    }
    //=================================
     function uploadchange(files,fileList){
       // console.log('---------uploadchange-------------');
      // console.log(files);
      //  console.log(fileList);
     }

    //上传前调用函数
    function beforVerifyUpload(file){
         // console.log(file.type);
         //判断文件格式
      if(file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/PNG' || file.type == 'image/gif' || file.type == 'image/GIF') {
        // console.log(_imgType);
      }else{
        ElMessage({
          showClose: true,
          message: '上传图片文件格式不对 ！',
          type: 'error',
        });
        return false;
      }


         if((file.size/1024/1024)>2){
           ElMessage({
             showClose: true,
             message: '上传图片大于2M ！',
             type: 'error',
           });
           return false;
         }
    }

    //表格中图片预览 点击图片预览处理函数
    function tablepicpreview(file){
      ReviewPicUrl.value=file.bannerurl;
      picdialogTitle.value=file.bannername+' ->图片预览';
      picdialogVisible.value=true;
    }
    //上传完图片后  图片中的预览按键处理函数  图片预览函数
    function handlePreview(file){
      // console.log('=================图片预览函数=====================')
      // console.log(file);

      ReviewPicUrl.value=file.response.data;
      picdialogTitle.value='图片预览';
      picdialogVisible.value=true;
      // console.log(ReviewPicUrl.value);

    }
    //删除上传图片回调函数
    function RemovePic(file){
      // console.log(file);
      // console.log("++>>"+pics.value);
      // file.response.data

       // let filePath=file.response.data;

      // pics.value.length = 0;

      // let filePath=file.response.data;
      // let res = pics.value.filter((item) => {
      //   if(item==filePath)return true;
      // });
      // pics.value.splice(res,1);
      bannergonefun('',file.response.data);
      closeUpload();

      // console.log(pics.value);
      // let picIndex=pics.findIndex(x=>x.pics==filePath);
      //     pics.splice(picIndex,1);

      // console.log(pics.value);
    }

   //关闭对话框 回调函数
    function closeUpload(){

      if( pics.value.length !=0){
        uploadref.value.clearFiles();
        // console.log('文件不为空   干 ==================>');
      }else {
        //console.log('文件为空==================>');
      }
      // console.log('closeUpload==================>'+pics.value.length);

      imaglocaname.value='等您上传文件喔 ^L^';
      pics.value.length = '';


      hideUpload.value=false;
      // console.log(pics.value);
      initBannerData();
    }
    //失败上传图片回调函数
    function uploadfileerror(error,uploadFile){
      ElMessage({
        showClose: true,
        message: '图片上传出错 ！'+error+'文件名：'+uploadFile,
        type: 'error',
      });
    }
    //成功上传图片回调函数
    function handleSuccess(response,file,fileList){
      // response  服务器返回的信息

      // console.log(response);
      // console.log('-------- handleSuccess ------------');
      // console.log(response.data);
      // console.log(file);

      if(response.code==200){
        //放入预览
        //  URL.createObjectURL(file.raw);
        // console.log( URL.createObjectURL(file.raw));
        // pics.value.push(response.data.tmp_path);
        // addBannerForm.imgUrl= URL.createObjectURL(file.raw);

        // imaglocaname.value=file.name;
        imaglocaname.value='您上传的文件名： '+file.name;
        pics.value.push(response.data);

        ElMessage({
          showClose: true,
          message: '成功上传图片：'+response.msg+"  "+pics.value,
          type: 'success',
        });
        // console.log( "----->>"+pics.value);// console.log(listFile.value);

        hideUpload.value=true;
      }else
      {
        ElMessage({
          showClose: true,
          message: '上传图片：'+response.msg,
          type: 'error',
        });
      }
    }
    onMounted(()=>{
      initBannerData();
    });

    async function initBannerData() {
      let res = await bannerlist(queryPages)
      {
        // console.log(res);
        if (res.data.code == 200) {
          bannertableData.value=res.data.data.data;
          bannertotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;

        }else{    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取广告信息数据失败：'+res.data.msg,
            type: 'error',
          });

        }
      }
    }
    //图片载入出错调用函数
     function defaultImg(a,event){
       let img = event.srcElement;
       // img.src =require('../../../assets/weblogo.jpg');
       img.src =require('@/assets/weblogo.jpg');
       img.onerror = null
     }



    function handleSizeChange(){
      // console.log('...........handleSizeChange..................');
      initBannerData();
    };
    //改变当前页面
    function handleCurrentChange(){
      // console.log('...........handleCurrentChange..................');
      // console.log(queryPages.pagenumber);
      // console.log(queryPages.pagesize);
      initBannerData();
    }


    return {
      bannerpath,defaultImg,tablepicpreview,
      ensureDialogVisible,ensureDelDialog,
      bannerinfotableoptions,bannertableData,addBannerKey,editBannerKey,delBannerKey,BannerswitchStateKey,imaglocaname,
      addBannerdialogVisible,ResetBannerFormData,addBannerCheck,addBannerFormRef,addBannerForm,DialogTitle,
      handlePreview,RemovePic,ReviewPicUrl,uploadref,closeUpload,hideUpload,
      handleSuccess,uploadURL,beforVerifyUpload,picdialogVisible,picdialogTitle,uploadfileerror,uploadchange,
      websittoken,imgDate,pics,
      bannertotal,queryPages,handleSizeChange,handleCurrentChange,
    };
  }
}
</script>

<style scoped>

.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}

.demo-fit {
  display: flex;
  text-align: left;
  justify-content: space-around;
  /*space-between;*/
}
.demo-fit .block {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}
.demo-fit .title {
  /*margin-bottom: 10px;*/
  font-size: 14px;
  color: var(--el-text-color-secondary);
}

.picpreview{
  width: 100%;
  height: 100%;
}

.hide >>> .el-upload--picture-card {
  display: none;
}



/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {
  margin-left: 0.4rem !important;
}

</style>
