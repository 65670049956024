<template>
  <rightmainhead  :fromFather =messageinfopath> </rightmainhead>
<!--  <h1>留言信息</h1>-->
  <el-row :gutter="20">

    <el-col  :span="10">
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 消息内容 日期 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>

<!--    <el-col :span="2" :offset="12">-->
<!--      <div>-->
<!--        <el-button type="primary" @click="addNewsKey">添加新闻</el-button>-->
<!--      </div>-->
<!--    </el-col>-->
  </el-row>
  <!--  消息表格  开始  -->
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格  -->
    <el-table :data="MessagestableData" border stripe style="width: 100%" @cell-click="MessagestableDetailEdit">

      <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in messagesinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small"     type="primary"     @click="checkMessageKey(row)"  >查看</el-button>
<!--          <el-button size="small"     type="warning"     @click="editNewsKey(row)"  >编辑</el-button>-->
          <el-button size="small"     type="danger"      @click="delMessageKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>


        <!--   消息详情   v-html="row.messagedetail"  {{row.messagedetail}}  -->
        <template v-slot="{row}" v-if="item.prop==='messagedetail'">
          <div   class="divtext"  >
            {{HtmldisplayText(row.messagedetail)}}

          </div>
        </template>
        <!--   状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='messagestatus'">
          <el-switch
              v-model="row.messagestatus"
              active-value="1"
              inactive-value="0"
              @change="MessageEnableswitchStateKey(row)"></el-switch>
        </template>


      </el-table-column>

    </el-table>

    <!--   分页器  ------------------------------------->
    <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
                 handleCurrentChange 页码值发生改变
                 currentPage  当前显示的第页面数
                 page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="Messagestotal"
                       @size-change="handleSizePagesChange"
                       @current-change="handleCurrentPageChange"
        />
      </el-col>
    </el-row>
  </el-card>
  <!--  消息表格  结束   -->
  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDelDialogVisible"
             context="确认将信息删除 ？ ^L^"
             title="删除信息数据"
             @ensureDialog="ensureDelDialog"
  ></tipdialog>

  <!--     消息查看  对话框 @close="NewsPreviewDialogClose"  -->
  <el-dialog
      v-model="PreViewMessagesDetailsDialogVisible"
      title="消息查看"
      width="100%"
  >
    <div class="messageview" v-if="messageForm.email" >电子邮箱：{{messageForm.email}}</div>
    <div class="messageview" v-if="messageForm.tel" >电话号码：{{messageForm.tel}}</div>
    <div class="messageview" v-if="messageForm.cellphone" >手机号码：{{messageForm.cellphone}}</div>
    <div class="messageview" v-if="messageForm.messagetime" >时间：{{messageForm.messagetime}}</div>
    <div class="messageview" v-if="messageForm.messageauthor" >作者：{{messageForm.messageauthor}}</div>
    <!--  消息详情  -------------------------------------------------------- -->
    <el-row :gutter="2" style="margin-top: 0px;">
      <el-col :span="4" >
        <el-divider  > 消息详情 </el-divider>
      </el-col>
      <el-col :span="20" >
        <el-divider content-position="right" ></el-divider>
      </el-col>
    </el-row>

    <p class="newdetailsdiv" v-html="messageForm.messagedetail"> </p>
    <!--    <span>图片 </span>-->
    <!--    <img class="imgpreview" :src="PreViewImgUrl" alt="">-->
  </el-dialog>


</template>

<script>

import Rightmainhead from "@/components/rightareahead/rightareahead";
import Tipdialog from "@/components/rightareahead/tipdialog";
import {messagesinfotableoptions} from '@/views/webmanage/rightarea/messagestable'

import { messageslist,gonemessage,messagestatus} from "@/axios/webindex";
import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue';
import {onMounted, ref,reactive} from "vue";
import { ElMessage } from 'element-plus';

export default {
  name: "messageinfo",
  components: {Rightmainhead,Tipdialog},

  setup() {
    let messageinfopath = [
      {  name: '首页',     path: 'AdminHome'     },
      {  name: '留言管理',  path: 'messageinfo'    },
      {  name: '留言信息',  path: 'messageinfo'    }
    ];

    let queryPages=reactive({
             //============================   修改 类别
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let MessagestableData=ref();
    let Messagestotal=ref();

    let ensureDelDialogVisible=ref(false);

    let messageForm=reactive({
             //============================   修改 新闻类别
      id:'',
      messageauthor:'',
      email:'',
      tel:'',
      cellphone:'',
      messagetime:'',
      messagedetail:'',
      messagestatus:'',
    });

    let PreViewMessagesDetailsDialogVisible=ref(false);


    onMounted(()=>{
      initMessagesData();
    })
    //初始化新闻表格函数
    async function initMessagesData() {

      let res = await messageslist(queryPages)
      {
        // console.log(res);

        if (res.data.code == 200) {

          Messagestotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;
          // console.log(ProductstableData.value);
          // console.log('=============================');

          let type = Object.prototype.toString.call(res.data.data.data);

          if (type === "[object Array]"){
            //   console.log('是一个数组');
            MessagestableData.value=res.data.data.data;
          }
          if (type === "[object Object]"){
            // console.log('是一个对象');
            let arr = [];   //对象转成数组
            for (let i in res.data.data.data) {
              arr.push(res.data.data.data[i]);
              // console.log(res.data.data.data[i]);
              // ProductstableData.value
            }
            MessagestableData.value=arr;
          }

        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取广告信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }

    }
    //查看消息
    function checkMessageKey(RowTemp){
          messageForm.messageauthor=RowTemp.messageauthor;
          messageForm.email=RowTemp.email;
          messageForm.tel=RowTemp.tel;
          messageForm.cellphone=RowTemp.cellphone;
          messageForm.messagetime=RowTemp.messagetime;
          messageForm.messagedetail=RowTemp.messagedetail;
          messageForm.messagestatus=RowTemp.messagestatus;
      PreViewMessagesDetailsDialogVisible.value=true;
    }
    //删除消息回调函数
    async function ensureDelDialog(DataTemp) {

      let GonenewsID = reactive({
        id: '',
      });

      if (DataTemp == 1) {
        GonenewsID.id = messageForm.id;
        //删除新闻
        // console.log('=======================>');
        const {data: res} = await gonemessage(GonenewsID);
        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '更改数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '更改数据成功 : ' + res.msg,
            type: 'success',
          });
          //从服务中取出
          initMessagesData();
        }
      }
      ensureDelDialogVisible.value = false;
    }
    //删除消息
    function delMessageKey(RowTemp){
      messageForm.id=RowTemp.id;
      ensureDelDialogVisible.value=true;
    }
    //消息状态切换
    async function MessageEnableswitchStateKey(RowTemp) {
      messageForm.id = RowTemp.id;
      messageForm.messagestatus = RowTemp.messagestatus;
      const {data: res} = await messagestatus(messageForm);

      console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        initMessagesData();
      }
    }

    //消息表格内 每格单击处理函数
    function MessagestableDetailEdit(row,column,cell,event){
      //消息详情预览
      if(column.property=='messagedetail'){

        // newsForm.newstile=row.newstile;
        // newsForm.newssubtile=row.newssubtile;
        // newsForm.newsauthor=row.newsauthor;
        // newsForm.newsdate=row.newsdate;
        //
        // newsForm.newsdetails=row.newsdetails;
        //
        // PreViewNewsDetailsDialogVisible.value=true;
      }
    }

    function HtmldisplayText(HtmlString){
      let StringTemp=ref('');

      StringTemp.value=HtmlString.replace(/<\/?.+?\/?>/g, '');
      //.replace(/<[^>]+>/g, '');
      HtmlString=StringTemp.value.replace(/\r|\n|\s/g, '');
      StringTemp=HtmlString.replace(/&nbsp;/ig, "");
      // console.log(StringTemp,'=======================',StringTemp.length);
      if(StringTemp.length==0){
        StringTemp='没有内容...';
      }
      return StringTemp;

    }

    //分页器总页数改变回调函数
    function handleSizePagesChange(){

    };
    //分页器当前页数改变回调函数
    function handleCurrentPageChange(){
      // initNewsData();
    };
    function serchKey(){
      // initNewsData();
    };
    return {
      messageinfopath,
      Search,queryPages,MessagestableData,Messagestotal,messagesinfotableoptions,
      MessageEnableswitchStateKey,checkMessageKey,delMessageKey,MessagestableDetailEdit,HtmldisplayText,
      handleSizePagesChange,handleCurrentPageChange,serchKey,
      ensureDelDialogVisible,ensureDelDialog,messageForm,
      PreViewMessagesDetailsDialogVisible,
    };
  }
}
</script>

<style scoped>
.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}
/*  上传完图片后出现预览和删除图标 */
/*/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {*/
/*  margin-left: 0.4rem !important;*/
/*}*/

.box-card{
  margin-top: 6px;
}
.imagebox-card{
  margin-top: 6px;
}
.pdfbox-card{
  margin-top: 6px;
}
.el-upload__tip{
  margin-bottom: 6px;
}
.el-input{
  margin: 8px;
}

.imgpreview{
  width: 100%;
  height: 100%;
}

.hide >>> .el-upload--picture-card {
  display: none;
}

.divtext{
  /*width:250px;*/
  height:0.15rem;
  /*border:1px solid #345123;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor:pointer;
}
.messageview{
  margin: 0.1rem;
  height:0.15rem;
}
</style>
