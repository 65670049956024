export const companyinfotableoptions=[
    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '联系人',
        prop : 'contact',
        width:100,
    },
    {
        label: 'qq',
        prop : 'qq',
        width:128,
    },
    {
        label: '电子邮箱',
        prop : 'email',
        // width:160,
    },
    {
        label: '座机电话',
        prop : 'tel',
        width:140,
    },
    {
        label: '手机号码',
        prop : 'mobile',
        width:130,
    },
    {
        label: '地址',
        prop : 'address',
        width:300,
    },
    {
        label: '显示开关',
        prop : 'status',
        width:88,
    },
    {
        label: '操作',
        prop : 'actions',
        // width:130,
    },

]
