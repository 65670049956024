<template>
  <rightmainhead  :fromFather =bluetoolslightpath> </rightmainhead>
<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx   -->
  <!-- --------------------------------------------------  -->
  <!--  添加产品按键   @keyup.enter="serchKey()"  -->
  <el-row :gutter="20">

    <el-col  :span="10">
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 产品名称 和 产品型号 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>



    <el-col :span="2" :offset="12">
      <div>
        <el-button type="primary" @click="addProductKey">添加产品</el-button>
      </div>
    </el-col>

  </el-row>
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格  -->
    <el-table :data="ProductstableData" border stripe style="width: 100%" @cell-click="ProductstableDetailEdit">

      <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in productinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small"     type="primary"     @click="addProductKey(row)"  >添加</el-button>
          <el-button size="small"     type="warning"     @click="editProductKey(row)"  >编辑</el-button>
          <el-button size="small"     type="danger"      @click="delProductKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>
        <!--  产品描述 项 -->
        <template v-slot="{row}" v-if="item.prop==='productdetails'">
          <div   style="cursor:pointer;"   >
            {{row.productdetails}}
          </div>
        </template>

        <template  v-slot="{ row }"  v-if="item.prop==='proudctimage'">

          <template  v-if= "row.proudctimage">
            <img   :src="row.proudctimage" alt="" @click="Productstablepicpreview(row)" @error="defaultImg(a,$event)"
                   style="width: 100px;height: 60px; cursor:pointer">
          </template>
          <template  v-else>
            <img    :src="require('@/assets/weblogo.jpg')" alt=""  @click="Productstablepicpreview(row)"
                    style="width: 100px;height: 60px; cursor:pointer">
          </template>


        </template>

        <template  v-slot="{ row }"  v-if="item.prop==='productpdf'">
          <!--@click="pdfPreviewKey(row)"-->
          <!--   PDF         -->
          <template  v-if= "row.productpdf">
            <img    :src="require('@/assets/pdfjpg.jpg')" alt=""
                    style="width: 100px;height: 60px; cursor:pointer">
          </template>

        </template>

        <!--   状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='status'">
          <el-switch
              v-model="row.status"
              active-value="1"
              inactive-value="0"
              @change="productEnableswitchStateKey(row)"></el-switch>
        </template>
        <!--  详情选择 状态开关       -->
        <template v-slot="{row}" v-if="item.prop==='descriptiontype'">
          <el-switch
              v-model="row.descriptiontype"
              active-value="1"
              inactive-value="0"
              @change="productDescriptionswitchStateKey(row)"></el-switch>
        </template>
        <!--        <template  v-slot="{ row }"  v-if="item.prop==='productdetails'"  @click="ProductstableDetailEdit(row)"  style="  cursor:pointer">-->

        <!--        </template>-->
        <!--   状态开关       -->
        <!--        <template v-slot="{row}" v-if="item.prop==='status'"> -->
        <!--          <el-switch-->
        <!--              v-model="row.status"-->
        <!--              active-value="1"-->
        <!--              inactive-value="0"-->
        <!--              @change="HomeBannerswitchStateKey(row)"></el-switch>-->

        <!--        </template>-->

      </el-table-column>
      <!--      <el-table-column label='联系人' prop="朱飞云">     </el-table-column>-->
    </el-table>

    <!--   分页器  ------------------------------------->
    <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
                 handleCurrentChange 页码值发生改变
                 currentPage  当前显示的第页面数
                 page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="Productstotal"
                       @size-change="handleSizePagesChange"
                       @current-change="handleCurrentPageChange"
        />
      </el-col>
    </el-row>
  </el-card>

  <!--     产品主页  添加 编辑 对话框  开始 ---------------------------------------    -->
  <el-dialog
      v-model="productsdialogVisible"
      :title="productsDialogTitle"
      width="60%"
      @close="ProductDialogClose"
  >
    <!--   内容主体区      -->
    <el-form
        ref="productsFormRef"
        :model="productsForm"
        :rules="productsFormRules"
        label-width="30%"
        :size="formSize"
        status-icon
    >
      <template v-if="productsDialogTitle=='编辑产品'">
        <el-form-item label="产品名称"  prop="productname">
          <el-input v-model="productsForm.productname" />
        </el-form-item>
        <el-form-item label="产品型号"  prop="productmodel">
          <el-input v-model="productsForm.productmodel" />
        </el-form-item>

      </template>
      <template v-if="productsDialogTitle!=='编辑产品'">
        <el-form-item label="新产品名称"  prop="productname">
          <el-input v-model="productsForm.productname" />
        </el-form-item>
        <el-form-item label="新产品型号"  prop="productmodel">
          <el-input v-model="productsForm.productmodel" />
        </el-form-item>
      </template>

    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetAddUserFormData">清除</el-button>-->
          <el-button @click="productsdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="productsDialogCheck">提交</el-button
          >
        </span>
    </template>
  </el-dialog>
  <!--     产品主页  添加 编辑 对话框  结束 ---------------------------------------    -->
  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureProductsDialogVisible"
             context="确认将产品删除 ？ ^L^"
             title="删除产品数据"
             @ensureDialog="ensureProductsDialog"
  ></tipdialog>

  <!-- -----------------------------------------------------------------------------------------------------------     -->
  <!--   添加 产品属性 对话框 开始  @close="handleClose" -->
  <el-dialog
      v-model="addProductdetailsdialogVisible"
      :title="ProductdetailsDialogTitle"
      width="60%"
  >
    <!--   内容主体区  :rules="addUserFormRules"   label-width="80px"   -->
    <el-form
        ref="addProductdetailsFormRef"
        :model="addProductdetailsForm"
        status-icon
    >
      <!--      <template v-if="ProductdetailsDialogTitle!=='编辑联系人'">-->
      <!--      {{addProductdetailsForm}}-->
      <template v-for="(item,index) in addProductdetailsForm" :key="index"  >

        <!--        <el-input :v-model="item.addProductdetailsForm" class="el-input" />-->
        <el-input  v-model.trim="addProductdetailsForm[index]" class="el-input" />

        <!--        <el-form-item label="QQ"  prop="qq">-->
        <!--          <el-input v-model="addUserForm.qq" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="邮箱"  prop="email">-->
        <!--          <el-input v-model="addUserForm.email" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="电话"   prop="tel" >-->
        <!--          <el-input v-model="addUserForm.tel" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="手机"   prop="mobile" >-->
        <!--          <el-input v-model="addUserForm.mobile" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="地址"   prop="address" >-->
        <!--          <el-input v-model="addUserForm.address" />-->
        <!--        </el-form-item>-->
      </template>
      <div class="el-upload__tip">
        请用冒号分隔两个属性。
      </div>
      <el-button type="warning" @click="AddItem">新增加一条</el-button>

    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetAddUserFormData">清除</el-button>-->
          <el-button @click="addProductdetailsdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="ProductsDetailCheck">提交</el-button
          >
        </span>
    </template>
  </el-dialog>

  <!--   添加 产品属性 对话框   结束   -->
  <!-- -------------------------------------------------------------------------------------------------------------- -->
  <!--  图片对话框  处理   -->

  <el-dialog
      v-model="ImgEditDialogVisible"
      title="图片编辑对话框"
      width="80%"
      @close="addImageDialogCloseFun"
  >

    <!--  :on-change="uploadchange" ================================================================= class="hide"  -->
    <el-row>
      <el-col :span="24"  >
        <el-upload
            :class="{hide:hideImageUpload }"
            ref="imageuploadref"
            :action="imageuploadURL"
            :headers="gswebsittoken"
            :before-upload="beforVerifyImageUpload"
            :on-success="SuccessImageUpload"
            :data="ImageUploadDate"
            :on-preview="ImageUploadPreview"
            :on-remove="ImageUploadRemovePic"
            :on-change="ImageUploadChange"
            multiple
            name="image"

            list-type="picture-card"
        >
          <el-button type="primary"  >点击上传</el-button>
          <template #tip>
            <div class="el-upload__tip">
              <!--              {{imaglocaname}}-->
            </div>
            <div class="el-upload__tip">
              jpeg/jpg/png/gif 文件大小低于 2Mb
            </div>

          </template>
        </el-upload>
      </el-col>
    </el-row>
    <!--  =========================================================   -->

    <!--     图片编辑  对话框-->
    <el-card class="imagebox-card">
      <!--   表格  -->
      <el-table :data="ProductimagesData" border stripe style="width: 100%" >

        <!--      第一列    -->
        <el-table-column align="center" type="index"></el-table-column>
        <!--      第二列   prop为值 -->
        <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in productimageoptions" :key="index">

          <template  v-slot="{ row }"  v-if="item.prop==='actions'">

            <!--            <el-button size="small"     type="primary"     @click="addProductKey(row)"  >添加</el-button>-->
            <el-button size="small"     type="warning"     @click="imageEditKey(row)"  >编辑</el-button>
            <el-button size="small"     type="danger"      @click="imageDelKey(row)"   >删除</el-button>
            <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

          </template>
          <template  v-slot="{ row }"  v-if="item.prop==='productimage'">

            <img   :src="row.productimage" alt="" @click="ProductImagepicpreview(row)" @error="defaultImg(a,$event)"
                   style="width: 100px;height: 60px; cursor:pointer">
          </template>
          <!--        <template  v-slot="{ row }"  v-if="item.prop==='productdetails'"  @click="ProductstableDetailEdit(row)"  style="  cursor:pointer">-->

        </el-table-column>
        <!--      <el-table-column label='联系人' prop="朱飞云">     </el-table-column>-->
      </el-table>
    </el-card>

  </el-dialog>
  <!--                       -->
  <!--     编辑图片对话框 预览图片  -->
  <el-dialog
      v-model="PreViewImgDialogVisible"
      :title="PreViewImgDialogTitle"
      width="80%"
  >
    <!--    <span>图片 </span>-->
    <img class="imgpreview" :src="PreViewImgUrl" alt="">
  </el-dialog>

  <!--  图片显示序号  编辑对话框     -->
  <el-dialog
      v-model="editpicOrderdialogVisible"
      title="编辑图片显示序号"
      width="20%"
      @close="handleClose"
  >
    <!--   内容主体区      -->
    <el-form
        ref="editpicOrderFormRef"
        :model="editpicOrderForm"
        :rules="editpicOrderFormRules"
        label-width="30%"
        :size="formSize"
        status-icon
    >
      <!--      <template v-if="DialogTitle!=='编辑联系人'">-->
      <el-form-item label="显示序号"  prop="imageorder">
        <el-input v-model.trim="editpicOrderForm.imageorder" />
      </el-form-item>
      <!--        <el-form-item label="QQ"  prop="qq">-->
      <!--          <el-input v-model="addUserForm.qq" />-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="邮箱"  prop="email">-->
      <!--          <el-input v-model="addUserForm.email" />-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="电话"   prop="tel" >-->
      <!--          <el-input v-model="addUserForm.tel" />-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="手机"   prop="mobile" >-->
      <!--          <el-input v-model="addUserForm.mobile" />-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="地址"   prop="address" >-->
      <!--          <el-input v-model="addUserForm.address" />-->
      <!--        </el-form-item>-->
      <!--      </template>-->
    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetAddUserFormData">清除</el-button>-->
          <el-button @click="editpicOrderdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="editpicOrderCheck">提交</el-button
          >
        </span>
    </template>
  </el-dialog>
  <!--  图片 编辑对话框 结束 -->


  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDialogVisible"
             context="确认将图片删除 ？ ^L^"
             title="删除图片数据"
             @ensureDialog="ensureDelDialog"
  ></tipdialog>

  <!--  PDF 上传对话框 开始 -->
  <el-dialog
      v-model="UploadPDFdialogVisible"
      title="PDF 数据库对话框"
      width="80%"
      @close="closeUploadPdf"
  >
    <!--   内容主体区 size="50%"  :rules="addBannerFormRules"   -->
    <el-form
        ref="uploadpdfFormRef"
        :model="uploadpdfForm"
        label-width="15%"
        status-icon
    >
      <el-row>
        <el-col :span="24"  >
          <el-upload
              :class="{hide:hideUploadPDF}"
              ref="uploadPdfref"
              :action="uploadPdfURL"
              :headers="gswebsittoken"
              :before-upload="beforVerifyUploadPdf"
              :on-success="handleSuccessPdf"
              :on-change="uploadPdfOnChange"
              :on-error ='uploadfileerrorPdf'
              :data="PdfDate"
              :on-preview="handlePreviewPdf"
              :on-remove="RemovePdf"
              :file-list="uploadpdfForm.pdfFileList"
              name="pdf"
              list-type="picture-card"
          >
            <el-button type="primary"  >点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                {{Pdflocaname}}
              </div>
              <div class="el-upload__tip">
                pdf 文件大小低于 2Mb
              </div>

            </template>
          </el-upload>
        </el-col>
      </el-row>

      <!--  PDF 添加 删除 对话框 开始  -->
      <el-card class="pdfbox-card">
        <!--   表格  -->
        <el-table :data="ProductPdfsData" border stripe style="width: 100%" >

          <!--      第一列    -->
          <el-table-column align="center" type="index"></el-table-column>
          <!--      第二列   prop为值 -->
          <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in productpdfoptions" :key="index">


            <template  v-slot="{ row }"  v-if="item.prop==='productpdf'" >
              <!--:src="row.productpdf"-->
              <img    :src="require('@/assets/pdfjpg.jpg')" alt="" @click="pdfPreviewKey(row)"
                      style="width: 100px;height: 60px; cursor:pointer">
            </template>

            <template  v-slot="{ row }"  v-if="item.prop==='actions'">

              <!--            <el-button size="small"     type="primary"     @click="addProductKey(row)"  >添加</el-button>-->
              <!--              <el-button size="small"     type="warning"     @click="pdfEditKey(row)"  >编辑</el-button>-->
              <el-button size="small"     type="danger"      @click="pdfDelKey(row)"   >删除</el-button>
              <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

            </template>

            <!--   状态开关       -->
            <template v-slot="{row}" v-if="item.prop==='status'">
              <!--          <el-switch v-model="row.status" @change="switchStateKey(row)"></el-switch>-->
              <!--          <el-switch v-model="row.status ? false : true" @change="switchStateKey(row)"></el-switch>-->
              <el-switch
                  v-model="row.status"
                  active-value="1"
                  inactive-value="0"
                  @change="PdfEnableswitchStateKey(row)"></el-switch>

            </template>


            <!--            <template  v-slot="{ row }"  v-if="item.prop==='productimage'"> -->
            <!--              <img   :src="row.productimage" alt="" @click="ProductImagepicpreview(row)" @error="defaultImg(a,$event)"-->
            <!--                     style="width: 100px;height: 60px; cursor:pointer">-->
            <!--            </template>-->
            <!--        <template  v-slot="{ row }"  v-if="item.prop==='productdetails'"  @click="ProductstableDetailEdit(row)"  style="  cursor:pointer">-->

          </el-table-column>
          <!--      <el-table-column label='联系人' prop="朱飞云">     </el-table-column>-->
        </el-table>
      </el-card>

      <!--  PDF 添加 删除 对话框 结束  -->
    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetBannerFormData">清除</el-button>-->
          <el-button @click="UploadPDFdialogVisible = false">关闭对话框</el-button>
        </span>
    </template>
  </el-dialog>
  <!--  PDF 上传对话框 结束 -->
  <!-- PDF 提示对话框组件  -->
  <tipdialog v-model="ensureDelPdfDialogVisible"
             context="确认将 PDF 删除 ？ ^L^"
             title="删除 PDF 数据"
             @ensureDialog="ensureDelPdfDelDialog"
  ></tipdialog>

  <!--  PDF 预览对话框 开始 -->
  <el-dialog
      v-model="pdfreviewdialogVisible"
      :title="pdfReviewTitle"
      width="100%"
  >
    <pdfreview :pdfurl="PdfDate.productpdfurl" @shutwindow="emitshut">  </pdfreview>
    <!--    <span>图片 </span>-->
    <!--    <img class="picpreview" :src="ReviewPicUrl" alt="">-->
  </el-dialog>
  <!--  PDF 预览对话框 结束 -->
<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx   -->
</template>

<script>
import Rightmainhead from "@/components/rightareahead/rightareahead";
//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
import {productinfotableoptions} from '@/views/webmanage/rightarea/products/productinfotable'
import {productimageoptions} from '@/views/webmanage/rightarea/products/productimageinfortable'
import {productpdfoptions} from '@/views/webmanage/rightarea/products/productpdfinfortable'


import {onMounted, ref,reactive} from "vue";
import Tipdialog from "@/components/rightareahead/tipdialog";

import {produtslist,produtdetails,produtsimagelist,produtsimageorder,produtsimagegone,productdescriptionstatus} from "@/axios/webindex";
import {produtpdfslist,produtspdfgone,produtspdfstatus,updataproducts,addproducts,productsgone,productstatus} from "@/axios/webindex";

import {gsimagepicwebaddress,gspdfwebaddress} from '@/views/webmanage/websiteglobalvar'
import { ElMessage } from 'element-plus';
import Pdfreview from "@/components/rightareahead/pdfreview";

import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue'
//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

export default {
  name: "bluetoolslight",
  components: {Pdfreview, Tipdialog,Rightmainhead},
  setup() {
    let bluetoolslightpath = [
      {  name: '首页',     path: 'AdminHome'     },
      {  name: '产品管理',  path: 'bluetoolslight'    },
      {  name: '产品信息',  path: 'bluetoolslight'    },
      {  name: '蓝牙灯光',  path: 'bluetoolslight'    }
    ];
    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    //产品页 对话框
    let productsForm=reactive({
      mid:'2',  //============================   修改 主ID  mainid
      id:'',
      productname:'',
      productmodel:'',
      productdetails:'',
      status:'',
      descriptiontype:'',
    });
    let productsdialogVisible=ref(false);
    let productsDialogTitle=ref(null);
    let productsFormRef=ref();

    let productsFormRules=reactive({
      productname:[
        { required: true, message: 'Please input productname', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      productmodel:[
        { required: true, message: 'Please input productname', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
    });

    let ensureProductsDialogVisible=ref(false);

    //表格参数
    let ProductstableData=ref([]);
    let ProductimagesData=ref([]);
    let queryPages=reactive({
      mid:'2',  //============================   修改 主ID  mainid
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let Productstotal=ref();
    let addProductdetailsFormRef=ref();
    let addProductdetailsForm=ref([]);
    let addProductdetailsdialogVisible=ref(false);
    let ProductdetailsDialogTitle=ref('编辑属性');

    let addProductDialog=reactive({
      id:'',
      productname:'',
      productmodel:'',
      productdetails:'',
    });

    let ImgEditDialogVisible=ref(false);
    //预览图隐藏标记  为真 隐藏上传图片按键
    let hideImageUpload=ref(false);
    let imageuploadref=ref();  //清除图片用
    let imageuploadURL=ref(gsimagepicwebaddress);  //上传图片存储地址
    let gswebsittoken= { Authorization:  window.localStorage.getItem('gstoken'),};
    let upLoadimageArray=ref([]);
    let imaglocaname=ref();
    //上传图片 附带的参数
    let ImageUploadDate=reactive({
      id:'',
      productname:'',
      productmodel:'',
      imageorder:'',
    });
    //图片预览对话框参数
    let PreViewImgDialogVisible=ref(false);
    let PreViewImgDialogTitle=ref("图片预览");
    let PreViewImgUrl=ref();

    //删除图片对话框按键显示变量
    let ensureDialogVisible=ref(false);

    //编辑图片显示序号对话框
    let editpicOrderdialogVisible=ref(false);
    let editpicOrderFormRef=ref();
    let editpicOrderForm=reactive({
      id:'',
      imageorder:'',
    })
    //上传PDF文件对话框 参数
    let UploadPDFdialogVisible=ref(false);
    let uploadpdfFormRef=ref();
    let uploadpdfForm=reactive({
      id:'',
      status:'',
      productname:'',
      productmodel:'',
      pdfFileList:[],
    });
    let hideUploadPDF=ref(false);
    let uploadPdfref=ref();
    let uploadPdfURL=ref(gspdfwebaddress);  //上传图片存储地址
    //上传PDF 附带的参数
    let PdfDate=reactive({
      id:'',
      productname:'',
      productmodel:'',
      productid:'',
      productpdfurl:'',
    });
    let Pdflocaname=ref('等待上传PDF文件');
    let ProductPdfsData=ref([]);
    //删除 pdf 对话框
    let ensureDelPdfDialogVisible=ref(false);
    let pdfreviewdialogVisible=ref(false);
    let pdfReviewTitle=ref('PDF预览');

    onMounted(()=>{
      initProductsData();
    });


    async function initProductsData() {

      let res = await produtslist(queryPages)
      {
        // console.log(res);

        if (res.data.code == 200) {

          Productstotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;
          // console.log(ProductstableData.value);
          // console.log('=============================');

          let type = Object.prototype.toString.call(res.data.data.data);

          if (type === "[object Array]"){
            //   console.log('是一个数组');
            ProductstableData.value=res.data.data.data;
          }
          if (type === "[object Object]"){
            // console.log('是一个对象');
            let arr = [];
            for (let i in res.data.data.data) {
              arr.push(res.data.data.data[i]);
              // console.log(res.data.data.data[i]);
              // ProductstableData.value
            }
            ProductstableData.value=arr;
          }

        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取广告信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }

    }


    //产品主界面  添加按键
    function addProductKey(Rowtemp){
      // productsFormRef.value.resetFields();

      productsdialogVisible.value=true;
      productsDialogTitle.value="添加产品";
      productsForm.id='';
      productsForm.productname='';
      productsForm.productmodel='';
      // console.log('==========================');
    }
    //产品主界面  编辑按键
    function editProductKey(Rowtemp){
      // productsFormRef.value.resetFields();

      productsdialogVisible.value=true;
      productsDialogTitle.value="编辑产品";
      productsForm.id=Rowtemp.id;
      productsForm.productname=Rowtemp.productname;
      productsForm.productmodel=Rowtemp.productmodel;


      // console.log('==========================');
    }
    //产品主界面  删除按键
    function delProductKey(Rowtemp){
      productsForm.id=Rowtemp.id;
      ensureProductsDialogVisible.value=true;
    }
    ////产品主界面  删除按键 回调函数
    async function ensureProductsDialog(callbackstatus) {
      //删除数据
      if (callbackstatus == 1) {
        // console.log('==========================');
        const {data: res} = await productsgone(productsForm);

        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '更改数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '更改数据成功 : ' + res.msg,
            type: 'success',
          });
          //从服务中取出数据
          initProductsData();
        }
      }
      ensureProductsDialogVisible.value = false;
    }
    //对话框内提交按键处理函数
    function productsDialogCheck() {


      productsFormRef.value.validate( valid => {
        // console.log(valid);
        if (!valid) return;  //数据不对返回
        //  添加用户的表单请求
        if(productsDialogTitle.value=="添加产品"){

          // console.log(productsForm);
          AddProductsToDb(productsForm);
          // console.log("=============");
          // addUserdialogVisible.value=false;

        }
        if(productsDialogTitle.value=="编辑产品"){
          editProductsToDb(productsForm);
          // addUserdialogVisible.value=false;
          // console.log(addUserForm);

        }

        productsdialogVisible.value = false;
      })

    }
    //添加产品名称和模型到数据库
    async  function AddProductsToDb(DataTemp){
      const {data: res} = await addproducts(DataTemp);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        initProductsData();
      }
    }
    //编辑产品名称和模型到数据库
    async  function editProductsToDb(DataTemp){
      const {data: res} = await updataproducts(DataTemp);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        initProductsData();
      }
    }
//显示图片还是PDF文件 状态切换  0 显示图片， 1 显示 PDF
    async function productDescriptionswitchStateKey(RowTemp) {
      productsForm.id=RowTemp.id;
      productsForm.descriptiontype=RowTemp.descriptiontype;
      const {data: res} = await productdescriptionstatus(productsForm);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        initProductsData();
      }
    }
    //显示产品下架  0 显示下架， 1 显示 上架
    async function productEnableswitchStateKey(RowTemp) {
      productsForm.id=RowTemp.id;
      productsForm.status=RowTemp.status;
      const {data: res} = await productstatus(productsForm);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        initProductsData();
      }
    }
    //编辑图片序号处理函数
    function imageEditKey(Rowtemp){
      // console.log(Rowtemp.id);
      // console.log(Rowtemp.imageorder);
      editpicOrderdialogVisible.value=true;
      editpicOrderForm.imageorder=Rowtemp.imageorder;
      editpicOrderForm.id=Rowtemp.id;
    }
    //保存更改序号对话框回调函数
    async function editpicOrderCheck() {

      editpicOrderdialogVisible.value = false;

      const {data: res} = await produtsimageorder(editpicOrderForm);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出图片
        GetproductImage(ImageUploadDate);
      }
    }
    //删除图片 处理函数
    function imageDelKey(Rowtemp){
      ensureDialogVisible.value=true;
      editpicOrderForm.imageorder=Rowtemp.imageorder;
      editpicOrderForm.id=Rowtemp.id;
    }
    ///删除图片 确认对话框回调函数
    async function ensureDelDialog(callbackstatus) {
      ensureDialogVisible.value = false;

      if (callbackstatus == 1) { //进行数据 服务器删除

        const {data: res} = await produtsimagegone(editpicOrderForm);
        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '更改数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '更改数据成功 : ' + res.msg,
            type: 'success',
          });
          //从服务中取出图片
          GetproductImage(ImageUploadDate);
        }
        // console.log('  ==================>');
      } else {
        //取消保存

      }
    }
    //关闭图片对话框回调函数
    function addImageDialogCloseFun()
    {
      imageuploadref.value.clearFiles();
      //从服务中取出数据
      initProductsData();
    }
    //表格内单张图片预览
    function ProductImagepicpreview(RowTemp){
      // console.log(RowTemp.productimage);
      // console.log('------------------->');
      PreViewImgDialogTitle.value=RowTemp.productname+' '+RowTemp.productmodel +'  图片预览';
      {
        PreViewImgUrl.value=RowTemp.productimage;
        // if((tableRow.imagename)==null){
        //   PreViewImgDialogTitle.value='图片预览';
        // }else{
        //   PreViewImgDialogTitle.value=tableRow.imagename+' ->图片预览';
        // }
        PreViewImgDialogVisible.value=true;
      }
    }
    //主表格图片预览
    function Productstablepicpreview(RowTemp){
      //图片编辑处理函数
      ImgEditDialogVisible.value=true;

      ImageUploadDate.id=RowTemp.id;
      ImageUploadDate.productname=RowTemp.productname;
      ImageUploadDate.productmodel=RowTemp.productmodel;
      ImageUploadDate.imageorder=0;

      //从服务中取出图片
      GetproductImage(ImageUploadDate);
      // console.log('========  Productstablepicpreview  ================>');
    }
    //上传图片前校验函数
    function beforVerifyImageUpload(file){
      //判断文件格式
      if(file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/PNG' || file.type == 'image/gif' || file.type == 'image/GIF') {
        // console.log(_imgType);
      }else{
        ElMessage({
          showClose: true,
          message: '上传图片文件格式不对 ！',
          type: 'error',
        });
        return false;
      }


      if((file.size/1024/1024)>2){
        ElMessage({
          showClose: true,
          message: '上传图片大于2M ！',
          type: 'error',
        });
        return false;
      }
    }
    //图片上传成功回调处理
    function ImageUploadChange(){

    }
    //上传成功图片函数
    function SuccessImageUpload(response,file,fileList){

      // console.log(response);

      if(response.code==200){

        imaglocaname.value='您上传的文件名： '+file.name;
        upLoadimageArray.value.push(response.data.data);
        //上传成功一张图片存储一次 imageorder
        editpicOrderForm.imageorder=ImageUploadDate.imageorder;
        editpicOrderForm.id=response.data.ID;
        // console.log(editpicOrderForm.id);
        editpicOrderCheck();

        ImageUploadDate.imageorder++;
        // console.log('ImageUploadDate.imageorder');
        //  console.log(ImageUploadDate.imageorder);

        ElMessage({
          showClose: true,
          message: '成功上传图片：'+response.msg+"  "+upLoadimageArray.value,
          type: 'success',
        });
        // console.log( "----->>"+pics.value);// console.log(listFile.value);

      }else
      {
        ElMessage({
          showClose: true,
          message: '上传图片：'+response.msg,
          type: 'error',
        });
      }
    }
    //上传完后图片预览函数
    function ImageUploadPreview(file){
      // PreViewImgUrl.value=file.response.data;
      // PreViewImgDialogTitle.value=imaglocaname.value+'  图片预览';
      // PreViewImgDialogVisible.value=true;

      // PreViewImgUrl.value=file.response.data;
      // PreViewImgDialogVisible.value=true;

      ElMessage({
        showClose: true,
        message: '请点击表格内 图片 进行预览，谢谢！',
        type: 'success',
      });

    }
    //图片删除后回调函数
    function ImageUploadRemovePic(){
      // Homebannergonefun('',file.response.data);
      // addImageDialogCloseFun();
    }

    //从服务器取出图片
    async function GetproductImage(DataInfor) {

      let res = await produtsimagelist(DataInfor)
      {
        // console.log(res);
        if (res.data.code == 200) {
          ProductimagesData.value = res.data.data;
          // ImageUploadDate.imageorder=imageorder
          for(let loop=0;loop<ProductimagesData.value.length;loop++){
            if(ImageUploadDate.imageorder<ProductimagesData.value[loop].imageorder){
              ImageUploadDate.imageorder=ProductimagesData.value[loop].imageorder;
            }
          }
          ImageUploadDate.imageorder++;
          // console.log( ImageUploadDate.imageorder);
        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取广告信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }
    }

    //// 图片不存在显示图片 图片载入出错调用函数
    function defaultImg(a,event){
      let img = event.srcElement;
      // img.src =require('../../../assets/weblogo.jpg');
      img.src =require('@/assets/weblogo.jpg');
      img.onerror = null
    }
    //添加产品属性项目
    function AddItem(){
      addProductdetailsForm.value.push(' ');
      // console.log('===============================>');
    }

    //详细参数提交
    async function ProductsDetailCheck() {
      let errflag = 0;
      let Outstrtemp=[];
      addProductDialog.productdetails='';

      for (let i = 0; i < addProductdetailsForm.value.length; i++) {
        let strtemp = addProductdetailsForm.value[i].trim();

        if ((strtemp != null) && (strtemp.length != 0)) {
          // console.log(strtemp);
          if ((strtemp.includes(':'))||(strtemp.includes('：'))) {
            // addProductDialog.productdetails.push(strtemp);
            Outstrtemp.push(strtemp);
          } else {
            errflag = 1;
          }
        }
      }
      //组合成字符串
      addProductDialog.productdetails= Outstrtemp.join(",");
      // console.log(addProductDialog.productdetails);

      if (errflag == 1) {
        ElMessage({
          showClose: true,
          message: '数据缺少冒号 ： 请重新修改',
          type: 'error',
        });
      } else {
        addProductdetailsdialogVisible.value = false;
        //提交数据库进行修改
        // addProductDialog.productdetails=JSON.stringify(addProductDialog.productdetails);

        const {data: res} = await produtdetails(addProductDialog);
        // console.log(res);

        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '更改数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '更改数据成功 : ' + res.msg,
            type: 'success',
          });
          initProductsData();
        }
      }

    }
    //产品详细描述
    function ProductstableDetailEdit(row,column,cell,event){

      // if(row.productdetails)
      //产品描述对话框
      if(column.property=='productdetails'){
        addProductdetailsdialogVisible.value=true;
        addProductDialog.id=row.id;

        // let strArr = row.productdetails.split(',');
        // addProductdetailsForm.value=strArr;

        //以逗号分割成数组
        addProductdetailsForm.value= row.productdetails.split(',');

        //console.log(strArr.length);  // ['123', '1231', '23212']
        //console.log('===============================>');
        // console.log(row.id);
        // console.log(addProductdetailsForm.value);//(row.productdetails);
      }

      //图片描述对话框
      if(column.property=='productimage'){

        // console.log('===============================>');
      }
      //PDF描述对话框
      if(column.property=='productpdf'){
        UploadPDFdialogVisible.value=true;
        PdfDate.id=row.id;
        PdfDate.productname=row.productname;
        PdfDate.productmodel=row.productmodel;

        getPdfFilesData(PdfDate);
        //
        // console.log(PdfDate.productname);
        // console.log(PdfDate.productmodel);
        // console.log('============ PDF ===================>');
      }
      // console.log(row,column,cell)
      // console.log('===============================>');

    }
    async function getPdfFilesData(PdfDate) {

      // ProductPdfsData.value=
      let res = await produtpdfslist(PdfDate);
      // console.log(res);
      if (res.data.code == 200) {
        ProductPdfsData.value = res.data.data;

        // for (let loop = 0; loop < ProductimagesData.value.length; loop++) {
        //   if (ImageUploadDate.imageorder < ProductimagesData.value[loop].imageorder) {
        //     ImageUploadDate.imageorder = ProductimagesData.value[loop].imageorder;
        //   }
        // }
        // ImageUploadDate.imageorder++;
        // console.log( ImageUploadDate.imageorder);
      } else {    //数据不成功
        ElMessage({
          showClose: true,
          message: '获取广告信息数据失败：' + res.data.msg,
          type: 'error',
        });

      }
    }
    //pdf 对话框关闭回调函数
    function closeUploadPdf(){
      Pdflocaname.value='';
      uploadpdfForm.pdfFileList.lenth=0;
      uploadPdfref.value.clearFiles();
      //从服务中取出数据
      initProductsData();
    }
    //上传PDF文件前回调函数
    function beforVerifyUploadPdf(file){
      // console.log(file.type);
      //判断文件格式 application/pdf
      if(file.type == 'application/pdf' || file.type == 'application/PDF' ) {
        // console.log(_imgType);
      }else{
        ElMessage({
          showClose: true,
          message: '请上传pdf文件格式 ！',
          type: 'error',
        });
        return false;
      }


      if((file.size/1024/1024)>2){
        ElMessage({
          showClose: true,
          message: '上传文件大于2M ！',
          type: 'error',
        });
        return false;
      }
    }
    //上传 PDF文件 改变回调函数
    function uploadPdfOnChange(file,fileList){
      // if(file!=null){
      //
      // }
      file.url=require('@/assets/pdfjpg.jpg');
      file.uid='';
      Pdflocaname.value='您上传的文件名： '+file.name;
      // console.log(file);
      //  console.log('1');
      //  console.log(response);
      //  console.log(fileList);
      getPdfFilesData(PdfDate);
    }
    //上传 PDF 文件成功回调函数
    function handleSuccessPdf(response,file,fileList){
      // console.log('2');
      // console.log(response);
      // console.log(fileList);
      // file.uid='';
      // console.log(file);
      if(response.code==200){
        uploadpdfForm.pdfFileList=require('@/assets/pdfjpg.jpg');
        // uploadpdfForm.pdfFileList.push(response.data.data);
        Pdflocaname.value='您上传的文件名： '+file.name;
        ElMessage({
          showClose: true,
          message: '成功上传文件：'+response.msg+"  "+uploadpdfForm.pdfFileList,
          type: 'success',
        });

        getPdfFilesData(PdfDate);
      }
    }
    //上传 PDF 文件失败回调函数
    function uploadfileerrorPdf(){

    }
    //上传 PDF 文件预览 回调函数
    function handlePreviewPdf(){
      ElMessage({
        showClose: true,
        message: '请点击表格内 PDF 进行预览，谢谢！',
        type: 'success',
      });
    }
    //上传 PDF 文件删除 回调函数
    function RemovePdf(){

    }
    //对话框内编辑按键回调函数
    function pdfEditKey(){

    }
    //对话框内删除按键回调函数
    function pdfDelKey(RowTemp){
      uploadpdfForm.id=RowTemp.id;
      ensureDelPdfDialogVisible.value=true;
    }
    //对话框内删除按键 确认删除 回调函数
    async function ensureDelPdfDelDialog(callbackstatus) {
      ensureDelPdfDialogVisible.value = false;

      if (callbackstatus == 1) { //进行数据 服务器删除

        const {data: res} = await produtspdfgone(uploadpdfForm);

        // console.log(res);

        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '更改数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '更改数据成功 : ' + res.msg,
            type: 'success',
          });
          //从服务中取出图片
          getPdfFilesData(PdfDate);
        }
        // console.log('  ==================>');
      }
    }

    // 第二层对话框 点击pdf  预览
    function pdfPreviewKey(rowTemp){
      pdfreviewdialogVisible.value=true;
      PdfDate.productpdfurl=rowTemp.productpdf;
      pdfReviewTitle.value=rowTemp.productname+' '+rowTemp.productmodel+' 预览';
      // console.log(PdfDate.productpdfurl);
      //   console.log('===============================>');
    }
    //关闭对话框回调
    function emitshut(temp){
      // console.log(temp);
      // pdfreviewdialogVisible.value=false;
      pdfreviewdialogVisible.value=temp;
    }
    //状态开关回调函数
    async function PdfEnableswitchStateKey(rowTemp) {
      uploadpdfForm.id = rowTemp.id;
      uploadpdfForm.status = rowTemp.status;
      uploadpdfForm.productname=rowTemp.productname;
      uploadpdfForm.productmodel=rowTemp.productmodel;
      const {data: res} = await produtspdfstatus(uploadpdfForm);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });

      }
      //从服务中取出图片
      getPdfFilesData(PdfDate);
      // console.log(uploadpdfForm.status);
      // console.log('===============================>');


    }
    //分页器总页数改变回调函数
    function handleSizePagesChange(){

    };
    //分页器当前页数改变回调函数
    function handleCurrentPageChange(){
      initProductsData();
    };

    function serchKey(){
      // queryPages.pagenumber='';
      // queryPages.pagesize='';
      initProductsData();
      // console.log(queryPages);
      //    console.log('====================>');
    }
    function serchClearKey(){
      queryPages.serch='';
      initProductsData();
      // console.log('---------------------------');
    }
    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    return {
      bluetoolslightpath,
      productinfotableoptions,productimageoptions,productpdfoptions,ProductstableData,queryPages,Productstotal,handleSizePagesChange,handleCurrentPageChange,addProductKey,editProductKey,delProductKey,
      Productstablepicpreview,defaultImg,ProductstableDetailEdit,addProductdetailsdialogVisible,ProductdetailsDialogTitle,
      addProductdetailsFormRef,addProductdetailsForm,AddItem,ProductsDetailCheck,
      addProductDialog,ImgEditDialogVisible,ProductimagesData,
      hideImageUpload,imageuploadref,imageuploadURL,gswebsittoken,beforVerifyImageUpload,SuccessImageUpload,ImageUploadDate,ImageUploadPreview,ImageUploadRemovePic,upLoadimageArray,ImageUploadChange,
      ProductImagepicpreview,PreViewImgDialogVisible,PreViewImgDialogTitle,PreViewImgUrl,addImageDialogCloseFun,ensureDialogVisible,ensureDelDialog,
      imageEditKey,imageDelKey,imaglocaname,
      editpicOrderdialogVisible,editpicOrderFormRef,editpicOrderForm,editpicOrderCheck,
      UploadPDFdialogVisible,closeUploadPdf,uploadpdfFormRef,uploadpdfForm,uploadPdfref,uploadPdfURL,beforVerifyUploadPdf,uploadfileerrorPdf,PdfDate,handlePreviewPdf,RemovePdf,Pdflocaname,hideUploadPDF,handleSuccessPdf,uploadPdfOnChange,
      ProductPdfsData,pdfEditKey,pdfDelKey,PdfEnableswitchStateKey,pdfPreviewKey,ensureDelPdfDialogVisible,ensureDelPdfDelDialog,pdfreviewdialogVisible,pdfReviewTitle,
      emitshut,productEnableswitchStateKey,productDescriptionswitchStateKey,
      productsForm,productsdialogVisible,productsDialogTitle,productsFormRef,productsFormRules,productsDialogCheck,ensureProductsDialogVisible,ensureProductsDialog,
      serchKey,serchClearKey,Search,
    };
  }
}
</script>

<style scoped>
.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}
/*  上传完图片后出现预览和删除图标 */
/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {
  margin-left: 0.4rem !important;
}

.box-card{
  margin-top: 6px;
}
.imagebox-card{
  margin-top: 6px;
}
.pdfbox-card{
  margin-top: 6px;
}
.el-upload__tip{
  margin-bottom: 6px;
}
.el-input{
  margin: 8px;
}

.imgpreview{
  width: 100%;
  height: 100%;
}

.hide >>> .el-upload--picture-card {
  display: none;
}
</style>
