<template>
<!--  <el-button type="primary" @click="FindTextImage" >查看文本内的图片</el-button>-->
  <!--  =====================================================  -->
  <div style="border: 1px solid #ccc">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 400px; overflow-y: hidden;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
    />
  </div>
  <!-- =====================================================  -->

  <el-row  :gutter="4" style="margin-top: 10px;">
    <el-col :offset="20" :span="4" >
      <el-button type="warning" @click=SubensureEditorCancal>取消</el-button>
      <el-button type="primary" @click="SubensureEditorEnter" >确认</el-button>
    </el-col>

  </el-row>


  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureCanelDelDialogVisible"
             context="确定不需要保存更改过的新闻内容 ？ ^L^"
             title="不保存更改新闻数据"
             @ensureDialog="ensureCanelDelDialog"
  ></tipdialog>


</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import {onBeforeUnmount, ref,reactive, shallowRef, onMounted, onUpdated} from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { getCurrentInstance,} from "vue";
import { ElMessage } from 'element-plus'
import {textnewsimagegone,textnewsvideogone } from "@/axios/webindex";

import Tipdialog from "@/components/rightareahead/tipdialog";
export default {
  name: "wangeditcom",
  components: { Editor, Toolbar,Tipdialog },

  props:{
    title:{
      type:String,
      default:'对话框',
    },
    context:{
      type:Array,
      default:'',
    },
    mainid:{
      type:String,
      default:'',
    },

  },
  setup(props, context) {

    let loop;

    let LastExistImageArray=ref([]); //传过来的文本里面已经有文件名称
    let InputimageArray=ref([]); //一共上传的图片
    // let TempimageArray=ref([]);
    let PageImageArray=ref([]);//页面上的图片
    let DeleImage=reactive({
      ImageArray:[],

    });
    //视频参数
    let LastExistVideoArray=ref([]); //传过来的文本里面已经有视频文件名称
    let InputVideoArray=ref([]); //一共上传的视频
    let PageVideoArray=ref([]);//页面上的视频文件
    let DeleVideo=reactive({
      VideoArray:[],
    });

    let ensureCanelDelDialogVisible=ref(false);
    let NewsID=ref('');
    const {proxy} = getCurrentInstance();

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    let valueHtml = ref(props.context);   //'<p>hello</p>'

    const toolbarConfig = {}
    //const editorConfig = { placeholder: '请输入内容...' }
    //添加图片新增代码
    const editorConfig={MENU_CONF: {}};
    editorConfig.placeholder='请输入内容...'
    //上传图片的配置
    editorConfig.MENU_CONF['uploadImage']={
      //服务器地址
      server:'http://www.goldenseal.cn/website/newsinfo/addtextimages',
      allowedFileTypes: ['image/*'],
      // 将 meta 拼接到 url 参数中，默认 false
      // metaWithUrl: true,
      //文件上传字段名
      fieldName: 'image',
      //客户自已带参数
      meta: {
        mainid:props.mainid, //props.mainid, //props.mainid,  //mainid  可以带参数
        // mainid:1982,
        // token: 'xxx',
        // otherKey: 'yyy'
      },
      headers: {
        Authorization:  window.localStorage.getItem('gstoken'),
        // name:'image',
        // otherKey: 'xxx'
      },

      // 单个文件上传成功之后
      onSuccess(file, res) {          // JS 语法
        // console.log(`${file.name} 上传成功`, res);
        //  InputimageArray.value.push(file.name);
        InputimageArray.value.push(res.data.alt);
        NewsID.value=res.data.newsid;
        // console.log(res.data.alt) ;
        // console.log( res);

      },

    }

    //---------------------------------------------------------------------
    //上传视频的配置  --------------------------------------
    editorConfig.MENU_CONF['uploadVideo']={
      //服务器地址
      server:'http://www.goldenseal.cn/website/newsinfo/addtextvideos',
      allowedFileTypes: ['video/*'],
      // 单个文件的最大体积限制，默认为 10M
      maxFileSize: 100 * 1024 * 1024, // 100M
      // 将 meta 拼接到 url 参数中，默认 false
      // metaWithUrl: true,
      //文件上传字段名
      fieldName: 'video',
      //客户自已带参数
      meta: {
        mainid:props.mainid, //props.mainid, //props.mainid,  //mainid  可以带参数
        // mainid:1982,
        // token: 'xxx',
        // otherKey: 'yyy'
      },
      headers: {
        Authorization:  window.localStorage.getItem('gstoken'),
        // name:'image',
        // otherKey: 'xxx'
      },

      // 单个文件上传成功之后
      onSuccess(file, res) {          // JS 语法
        // console.log(`${file.name} 上传成功`, res);
        //  InputimageArray.value.push(file.name);
        InputVideoArray.value.push(res.data.alt);
        NewsID.value=res.data.newsid;
        // console.log(res.data.alt) ;
        // console.log( res);

      },

    }
    //---------------------------------------------------------------------

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }
// 模拟 ajax 异步获取内容
    onMounted(() => {
      // setTimeout(() => {
      //   valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
      // }, 1500)
      // valueHtml.value ='';
      valueHtml.value =props.context;
      editorConfig.MENU_CONF['uploadImage'].meta.mainid=props.mainid;
      editorConfig.MENU_CONF['uploadVideo'].meta.mainid=props.mainid;
      // console.log(props.mainid);
      //console.log(props.context);
      //查找已经存在的图片
      // LastExistImageArray.value= FindImageFromText(props.context);


      //
      // 使用两个参数
      // str1 = str1.slice(2,4);//截取第二个到第四个之间的字符 CD
      // str1 = str1.substring(2,4);//截取第二个到第四个之间的字符 CD
      // str1 = str1.substr(2,4);//截取从第3个开始往后数4位之间的字符 CDEF
      //截取前面的字符串：
      //str2 .substring(0,str2.lastIndexOf(“/”));

    })
    onUpdated(()=>{

    })
    //查找文本内的图片名称。
    function FindImageFromText(TextTemp){
      let Index;
      let StringTemp=ref('');
      let ExistIndexArray=ref([]);
      let BufferArray=ref([]);

      // console.log('text--->',TextTemp);

      Index=TextTemp.indexOf("alt=\"");
      if(Index!=-1) ExistIndexArray.value.push(Index);
      while (Index!=-1)
      {
        Index=TextTemp.indexOf("alt=\"",Index+4);
        if(Index!=-1) ExistIndexArray.value.push(Index);
      }
      //取出图片名称
      for(Index=0;Index<ExistIndexArray.value.length;Index++){
        StringTemp.value=TextTemp.substr(ExistIndexArray.value[Index]+5,20);
        StringTemp.value=StringTemp.value.substring(0,StringTemp.value.lastIndexOf('"'));
        BufferArray.value.push(StringTemp.value); //13
      }
      // console.log('=====================');
      return BufferArray.value;
    }
     //查找文本内的视频名称。
    function FindVideoFromText(TextTemp){
      let Index;
      let StringTemp=ref('');
      let ExistIndexArray=ref([]);
      let BufferArray=ref([]);

      // console.log('text--->',TextTemp);

      Index=TextTemp.indexOf("storage\/textvideos\/");
      if(Index!=-1) ExistIndexArray.value.push(Index);
      while (Index!=-1)
      {
        Index=TextTemp.indexOf("storage\/textvideos\/",Index+4);
        if(Index!=-1) ExistIndexArray.value.push(Index);
      }
      //取出视频名称
      for(Index=0;Index<ExistIndexArray.value.length;Index++){
        StringTemp.value=TextTemp.substr(ExistIndexArray.value[Index]+19,20);
        StringTemp.value=StringTemp.value.substring(0,StringTemp.value.lastIndexOf('"'));
        BufferArray.value.push(StringTemp.value); //13
      }
      // console.log('=====================');
      return BufferArray.value;
    }
    //------------------------------------------------------
    async function NewsImageGoneDB(DataTemp){
      DeleImage.ImageArray=DataTemp;
      // console.log('OnMounted',props.mainid);
      const {data: res} = await textnewsimagegone(DeleImage);
      // console.log(res);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
      }
    }
    //------------------------------------------------------
    async function NewsVoidGoneDB(DataTemp){
      DeleVideo.VoidArray=DataTemp;
      // console.log('OnMounted',props.mainid);
      const {data: res} = await textnewsvideogone(DeleVideo);
      // console.log(res);
      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
      }
    }


    //------------------------------------------------------
    //服务器图片数据删除
    function NewImageDel() {
      let DataTemp;
      // let ArrayMax;
      let indexArray = [];
      let DelArray = [];
      let InputArrayLoop;
      //console.log('Totoal image',InputimageArray.value);
      //console.log('User image',PageImageArray.value);
      //ArrayMax = PageImageArray.value.length;       //除了删除的图片，网页面还剩下的总图片。
      //InsetImageMax=InputimageArray.value.length; //一共插入多少张图片

      PageImageArray.value=FindImageFromText(valueHtml.value);
      //从 InputimageArray 中查出没被删除的图片序号  --------------------------
      if(InputimageArray.value.length>0){
            // for (InputArrayLoop=0;InputArrayLoop<InputimageArray.value.length;InputArrayLoop++){
            //   for (loop = 0; loop < ArrayMax; loop++) {
            //     DataTemp = InputimageArray.value[InputArrayLoop].indexOf(PageImageArray.value[loop]);
            //     if (DataTemp != -1) {
            //       indexArray.push(DataTemp);
            //     }
            //   }
            // }
        // for (InputArrayLoop=0;InputArrayLoop<InputimageArray.value.length;InputArrayLoop++){
        //   for (loop = 0; loop < ArrayMax; loop++) {
        //     if(InputimageArray.value[InputArrayLoop]===PageImageArray.value[loop]){
        //       indexArray.push(InputArrayLoop);
        //       InputimageArray.value[InputArrayLoop]='';
        //     }
        //   }
        // }

        for (InputArrayLoop=0;InputArrayLoop<InputimageArray.value.length;InputArrayLoop++)
        {
          // for (loop = 0; loop < ArrayMax; loop++)
          //在文本里找有没有用到的图片，有就把用到的图片名称置零，不给予删除，否则没用到的就进行删除。
          DataTemp =PageImageArray.value.indexOf(InputimageArray.value[InputArrayLoop])
          {
            // if(InputimageArray.value[InputArrayLoop]===PageImageArray.value[loop])
            if (DataTemp != -1)
            {
              indexArray.push(InputArrayLoop);
              InputimageArray.value[InputArrayLoop]='';
            }
          }
        }


            //console.log('indexArray image',indexArray);
            if (indexArray.length > 0)
            {
              //
              DelArray = InputimageArray.value.filter(function (value) {
                return value != '';
              })
              //进行数据库内的数据删除
              // NewsImageGoneDB(DelArray);
              // console.log('Delete Array',DelArray);
            }else {
              // DelArray = InputimageArray.value;
              // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
              for (loop = 0; loop < InputimageArray.value.length; loop++) {
                DelArray.push(InputimageArray.value[loop]);
              }
            }
      }

      //===========================================================
      LastExistImageArray.value.length=0;
      LastExistImageArray.value= FindImageFromText(props.context);
      //
      indexArray.length=0;
      //从 旧文本 中查出没被删除的图片序号  --------------------------
      if(LastExistImageArray.value.length>0){

        // console.log('LastExistImageArray Array',LastExistImageArray.value);
        // console.log('PageImageArray Array',PageImageArray.value);

            // for (InputArrayLoop=0;InputArrayLoop<LastExistImageArray.value.length;InputArrayLoop++){
            //   for (loop = 0; loop < ArrayMax; loop++) {
            //     //有图片说明不能删除得清掉
            //     if(LastExistImageArray.value[InputArrayLoop]===PageImageArray.value[loop]){
            //       indexArray.push(InputArrayLoop);
            //       LastExistImageArray.value[InputArrayLoop]='';
            //     }
            //   }
            // }

            for (InputArrayLoop=0;InputArrayLoop<LastExistImageArray.value.length;InputArrayLoop++){
              // for (loop = 0; loop < ArrayMax; loop++)
              DataTemp = PageImageArray.value.indexOf(LastExistImageArray.value[InputArrayLoop]);
              {
                //有图片说明不能删除得清掉
                // if(LastExistImageArray.value[InputArrayLoop]===PageImageArray.value[loop])
                if (DataTemp != -1)
                {
                  indexArray.push(InputArrayLoop);
                  LastExistImageArray.value[InputArrayLoop]='';
                }
              }
            }
        // DataTemp = LastExistImageArray.value[InputArrayLoop].indexOf(PageImageArray.value[loop]);
        // if (DataTemp != -1) {
        //   indexArray.push(DataTemp);
        // }


            // console.log('  -------------> PageImageArray image',PageImageArray.value);
            // console.log('  -------------> indexArray image',indexArray);
            // console.log('LastExistImageArray -------------> ',LastExistImageArray.value);
            // console.log('indexArray.length -------------> ',indexArray.length);
             if (indexArray.length > 0)
            {
              //把没被删除的图片数据从 总插入的图片张数删除，剩下的就是删除的图片数据了。
              // for (loop = 0; loop < LastExistImageArray.value.length; loop++) {
                // LastExistImageArray.value.splice(indexArray[loop], 1, '');
              // }
              //
              DelArray = LastExistImageArray.value.filter(function (value) {
                return value != '';
              })
              //console.log('Delete Array',DelArray);
            }else{
               // console.log('==================================================>');
               for (loop = 0; loop < LastExistImageArray.value.length; loop++) {
                 DelArray.push(LastExistImageArray.value[loop]);
               }
             }
      }

     if(DelArray.length>0){
       // console.log('Delete Array------------>',DelArray);
       //进行数据库内的数据删除
       NewsImageGoneDB(DelArray);
     }
      //===========================================================
      // console.log(InputimageArray.value);
      // console.log('Delete Array',DelArray);
    }

    //--------------------------------------------------------
    //服务器视频数据删除
    function NewVideoDel() {
      let DataTemp;
      // let ArrayMax;
      let indexArray = [];
      let DelArray = [];
      let InputArrayLoop;

      //ArrayMax = PageImageArray.value.length;       //除了删除的视频，网页面还剩下的总视频。
      //InsetImageMax=InputimageArray.value.length; //一共插入多少视频
      PageVideoArray.value=FindVideoFromText(valueHtml.value);
      //从 InputVideoArray 中查出没被删除的视频序号  --------------------------
      if(InputVideoArray.value.length>0){
        for (InputArrayLoop=0;InputArrayLoop<InputVideoArray.value.length;InputArrayLoop++)
        {
          //在文本里找有没有用到的视频，有就把用到的视频名称置零，不给予删除，否则没用到的就进行删除。
          DataTemp =PageVideoArray.value.indexOf(InputVideoArray.value[InputArrayLoop])
          {
            if (DataTemp != -1)
            {
              indexArray.push(InputArrayLoop);
              InputVideoArray.value[InputArrayLoop]='';
            }
          }
        }
        if (indexArray.length > 0)
        {
          //
          DelArray = InputVideoArray.value.filter(function (value) {
            return value != '';
          })
          //进行数据库内的数据删除
        }else {

          for (loop = 0; loop < InputVideoArray.value.length; loop++) {
            DelArray.push(InputVideoArray.value[loop]);
          }
        }
      }

      //===========================================================
      LastExistVideoArray.value.length=0;
      LastExistVideoArray.value= FindVideoFromText(props.context);
      //
      indexArray.length=0;
      //从 旧文本 中查出没被删除的视频序号  --------------------------
      if(LastExistVideoArray.value.length>0){

        for (InputArrayLoop=0;InputArrayLoop<LastExistVideoArray.value.length;InputArrayLoop++){
          // for (loop = 0; loop < ArrayMax; loop++)
          DataTemp = PageVideoArray.value.indexOf(LastExistVideoArray.value[InputArrayLoop]);
          {
            //有视频说明不能删除得清掉
            if (DataTemp != -1)
            {
              indexArray.push(InputArrayLoop);
              LastExistVideoArray.value[InputArrayLoop]='';
            }
          }
        }
        // console.log('  -------------> PageImageArray image',PageImageArray.value);
        // console.log('  -------------> indexArray image',indexArray);
        // console.log('LastExistImageArray -------------> ',LastExistImageArray.value);
        // console.log('indexArray.length -------------> ',indexArray.length);
        if (indexArray.length > 0)
        {
          //把没被删除的视频数据从 总插入的视频 数删除，剩下的就是删除的视频数据了。
          DelArray = LastExistVideoArray.value.filter(function (value) {
            return value != '';
          })
          //console.log('Delete Array',DelArray);
        }else{
          // console.log('==================================================>');
          for (loop = 0; loop < LastExistVideoArray.value.length; loop++) {
            DelArray.push(LastExistVideoArray.value[loop]);
          }
        }
      }

      if(DelArray.length>0){
        // console.log('Delete Array------------>',DelArray);
        //进行数据库内的数据删除
        NewsVoidGoneDB(DelArray);
      }
      //===========================================================
      // console.log(InputimageArray.value);
      // console.log('Delete Array',DelArray);
    }

    //取消确认回调函数
    function ensureCanelDelDialog(DataTemp){
        if(DataTemp==1){
          // TempimageArray.value=editorRef.value.getElemsByType('image');
          // for(loop=0;loop<TempimageArray.value.length;loop++){
          //   PageImageArray.value.push(TempimageArray.value[loop].alt) ;  //取出文件名字
          // }
          // console.log(TempimageArray.value.length);
          if(InputimageArray.value.length>0){
            //进行服务器图片删除
            // NewImageDel();
            NewsImageGoneDB(InputimageArray.value);
            //console.log('=======================================>');
          }
          if(InputVideoArray.value.length>0){
            //进行服务器视频删除
            // NewImageDel();
            NewsVoidGoneDB(InputVideoArray.value);
            //console.log('=======================================>');
          }
          proxy.$emit('ensureDialogCancel',false);
        }else{

        }
      ensureCanelDelDialogVisible.value=false;
    }
    //取消按键
    function  SubensureEditorCancal(){

      if(valueHtml.value !=props.context)
      {
        ensureCanelDelDialogVisible.value=true;
      }else{
        proxy.$emit('ensureDialogCancel',false);
      }

    }
    //确认按键
    function SubensureEditorEnter(){
      //
      let ArrayMax=0,InsetImageMax;
      // CloseDialogFlag.value=true;

      if(valueHtml.value.length<15000){
        //提交数据
        PageImageArray.value.length=0;
        // TempimageArray.value=editorRef.value.getElemsByType('image');
        // for(loop=0;loop<ArrayMax;loop++){
        //   PageImageArray.value.push(TempimageArray.value[loop].alt) ;  //取出文件名字
        // }


        // console.log('PageImageArray----------->',PageImageArray.value);
        // console.log('InsetImageMax----------->',InputimageArray.value);
        {
          NewImageDel();
          NewVideoDel();
          // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx>');
        }

        proxy.$emit('ensureDialog',valueHtml.value,NewsID.value);

      }else {
        ElMessage({
          showClose: true,
          message: '字数超过15000个，请重新修改！'+'当前字数为：'+valueHtml.value.length+'  个',
          type: 'error',
        });
      }
      // proxy.$emit('ensureDialog',valueHtml.value);
    }

    function FindTextImage(){
      // let ArrayMax;
      let BufferArrary=ref([]);
      console.log('OldTextGet',FindImageFromText(props.context));
      console.log('TextGet',FindImageFromText(valueHtml.value));
      //提交数据
      PageImageArray.value.length=0;
      BufferArrary.value=editorRef.value.getElemsByType('image');
      for(loop=0;loop<BufferArrary.value.length;loop++){
        PageImageArray.value.push(BufferArrary.value[loop].alt) ;  //取出文件名字
      }
      console.log('PageGet',PageImageArray.value);
      console.log('InputGet',InputimageArray.value);
      // ArrayMax=PageImageArray.value.length;
      // InsetImageMax=InputimageArray.value.length;
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      InputimageArray,
      SubensureEditorCancal,SubensureEditorEnter,
      ensureCanelDelDialogVisible,ensureCanelDelDialog,
      FindTextImage,
    };
  }
}
</script>

<style scoped>
/*.bottombutton{*/
/*  margin:10px;*/
/*}*/
</style>
