<template>

  <transition name="fade" class="fadeIn" >
<!--    <router-view></router-view>-->

    <div class="backTop " @click="backtopkeydown"> </div>
  </transition>


</template>

<script>
export default {
  name: "backtop",
  setup(){
     let timer;
    function backtopkeydown(){
      // console.log(document.documentElement.scrollTop);
      //500ms/10ms=50
      let delaycount=document.documentElement.scrollTop/50;
      if(timer==null){
        // console.log('================>');
        //返回顶部延时处理
        timer=setInterval(()=>{
          if(document.documentElement.scrollTop>delaycount)
          {
            document.documentElement.scrollTop=document.documentElement.scrollTop-delaycount;
            // console.log(document.documentElement.scrollTop);
          }else
          {
            // console.log('4444444444444444444==>');
            document.documentElement.scrollTop=0;
            //注销定时器
            clearInterval(timer);
            timer=null;
          }
        },10);
      }else {
        // console.log('==11111111111====>');
        clearInterval(timer);
        timer=null;
      }
      // document.documentElement.scrollTop=0;
    }
    return{backtopkeydown}
  }
}
</script>

<style scoped>

.backTop{
  /*background: #52E923;*/
  width: 0.3rem;
  height: 0.3rem;
  /*display: inline-block;*/
  /*text-decoration: none;*/

  /*background-image: url("../../assets/uplogo.png");*/

  background-image: url("@/assets/uplogo.png");

  background-size: 0.3rem 0.3rem;
  background-repeat: no-repeat;

  position: fixed;
  bottom: 1.6rem;

  right: 1.1rem;
  /*z-index: 0.2rem;*/
  border-radius: 0.02rem;
  /*text-shadow: 0.01rem 0.01rem 0.025rem #888888;*/
  box-shadow: 0.01rem 0.01rem 0.025rem #888888;
  cursor: pointer;
  /*display: inline-block;*/
  /*text-decoration: none;*/

/*  ---------------------------------------------------------- */
  /*动画名称*/
  /*-webkit-animation-name: fadeIn;*/
  /*动画持续时间*/
  /*-webkit-animation-duration: 1s;*/
  /*动画次数*/
  /*-webkit-animation-iteration-count: 1;*/
  /*延迟时间*/
  /*-webkit-animation-delay: 0s;*/

}
/*============================================================*/
.fade-enter {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

.fade-enter-active {
  transition: opacity 0.8s;
}

.fade-leave-active {
  opacity: 0;
  transition: opacity 0.8s;
}

/*-------------------------------------------------------------*/
/*@-webkit-keyframes fadeIn {*/
/*  0% {*/
/*    !*初始状态 透明度为0*!*/
/*    opacity: 0; */
/*  }*/
/*  50% {*/
/*    !*中间状态 透明度为0*!*/
/*    opacity: 0.5; */
/*  }*/
/*  100% {*/
/*    !*结尾状态 透明度为1*!*/
/*    opacity: 1; */
/*  }*/
/*}*/
/*.fade {*/
/*  -webkit-animation: fadeIn 1s 1s infinite;*/
/*  animation: fadeIn 1s 1s infinite;*/
/*}*/
</style>
















