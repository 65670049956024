<template>
  <div id="swipercar" >
    <div id="swiperpic"></div>
<!--    <h1>轮播图片</h1>-->

    <!--       disableOnInteraction: false,     手动切换之后继续自动轮播    -->
    <swiper

        :rewind="true"
        :spaceBetween="30"
        :centeredSlides="true"
        :loop="true"
        :lazy="true"
        :autoplay="{
           delay: 2500,
           disableOnInteraction: false,
        }"
        :pagination="{
         clickable: true,
        }"
        :navigation="true"
        :modules="modules"
        @swiper="onSwiper"
        @mouseover="mouseover"
        @mouseout="mouseout"

    >
<!--        <swiper-slide    v-for="(item,i) in $store.state.gswebbannerstore.bannerimgs" :key="i" >-->
        <swiper-slide    v-for="(item,i) in gsdata.picList" :key="i" >
           <img :src="item" @click="imagKeydown(i)" class="swiper-lazy">
           <div  class="swiper-lazy-preloader swiper-lazy-preloader-white" ></div>
        </swiper-slide>
    </swiper>


<!--  <button @click="getservedta">获取服务器数据</button>-->

<!--    <span v-for="datetemp in bannerpic">{{datetemp}}</span>-->
  </div>

</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
//
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/lazy";


// import required modules
import { Autoplay, Pagination, Navigation,Lazy } from "swiper";
import {ref, reactive, onBeforeMount, onMounted} from 'vue'
import {useStore} from 'vuex';

import axios from "axios";
import { getBannerList } from "@/axios/webindex";
import {useRouter} from "vue-router";

export default {
  name: "swipercarousel",


  components: {
    Swiper,
    SwiperSlide,
  },


  setup(){
    const store=useStore();
    const gsrouter=useRouter();
    const gsdata=reactive({
      picList:[],
      controlledSwiper:0,
    });
    // let bannerurl=ref([  ]    );
    //  let loop=0;

//=============================================
    const onSwiper = (swiper) => {
      // console.log(swiper);
      gsdata.controlledSwiper=swiper;
    };

    onMounted(()=>{

      // let api='http://localhost:8080/index.html';
      // let api='http://www.goldenseal.cn/index.html';
      // axios.get(api).then((result)=>{
      //     console.log(result);
      //   gsdata.picList=result.data.data;
      //   // console.log(imags);
      // })

      // getBannerList("index.html").then(res=>{
      //    console.log(res);
      //   gsdata.picList=res.data.data;
      //   gsdata.webbannerdata=res.data.data;
      //
      //   for (loop=0;loop<res.data.data.length;loop++)
      //   {
      //     bannerurl.value.push(res.data.data[loop].url);
      //     // console.log(bannerurl.value);
      //   }
      //
      //   // console.log(gsdata.picList);
      // }).catch(err=>{
      //   console.log(err);
      // });

      getBannerList("index.php/index/index/homebanner").then(res=>{
        // console.log(res);
        // console.log(res.data.code);
        if(res.data.code==200)
        {
          gsdata.picList.length=0;
          for (let loop=0;loop<res.data.data.length;loop++){
            gsdata.picList.push(res.data.data[loop].imageurl);    // banner 图片数据
          }



          // store.state.gswebbannerstore.bannerimgs.length=0;
          // store.state.gswebbannerstore.bannerimgurl.length=0;
          // for (let loop=0;loop<res.data.data.length;loop++)
          // {
          //   store.state.gswebbannerstore.bannerimgs.push(res.data.data[loop].imageurl);    // banner 图片数据
          //   store.state.gswebbannerstore.bannerimgurl.push(res.data.data[loop].hometourl);  // banner url
          //   // bannerurl.value.push(res.data.data[loop].url);
          //   //  console.log(store.state.gswebbannerstore.bannerimgs);
          // }

        }
        // console.log(gsdata.picList);
      }).catch(err=>{
        console.log(err);
      });







    });

    function imagKeydown(keyTemp){
      // console.log(keyTemp);
      // console.log(store.state.gswebbannerstore.bannerimgurl[keyTemp]);
      // gsrouter.replace(store.state.gswebbannerstore.bannerimgurl[keyTemp]);
      // gsrouter.replace('/aboutus');
    };
    function mouseover(){
      // console.log(11111111111+'========>');
      gsdata.controlledSwiper.autoplay.stop();
    };
    const mouseout=()=>{
      // console.log(22222222222+'========>');
      gsdata.controlledSwiper.autoplay.start();
    }
    return {
      onSwiper,mouseover,mouseout,gsdata,
      modules: [Autoplay, Pagination, Navigation,Lazy],
      imagKeydown,//gsdata,bannerurl,
    };
  }
}
</script>

<style >
#swipercar{
  width: 6.8rem;
  height:2.8rem;
  margin: 0 auto;
  /*background: #52E923;*/
}
#swiperpic{

}
/*=======================*/
.swiper {
  width: 100%;
  height: 100%;
  border-radius: 0.1rem;
}

.swiper-slide {
  text-align: center;
  font-size: 0.18rem;
  background: #fff;
  /*border-radius: 0.01rem;*/
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  /*border-radius: 0.08rem;*/
}

 .swiper-button-prev  {
   color: #0527af;
   /*background: #0527af;*/

  }
 .swiper-button-next {
   color: #0527af;
}

 .swiper-pagination-bullet-active{
   background: #0527af;
 }
.swiper-lazy-preloader-white {
  --swiper-preloader-color: #0527af;
}
</style>
