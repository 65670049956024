<template>
	<div id="gswebhead">
		<!-- <h1>123456</h1> -->
<!--		<div id="gscompanylogopic"> <img alt="company logo" src="../../assets/weblogo.jpg"> </div>-->
    <!--         <img alt="company logo" src="../../assets/weblogo.jpg">  </a>-->
    <div id="gscompanylogopic">
       <a href="http://www.goldenseal.cn/">
         <h1>金钤科技</h1>
       </a>
    </div>
		<div id="headright">
			<div class="gstimer" @click="changeColor" >
				<!--  -->
				    <div>{{gshour}}</div>
					<div>:</div>
					<div>{{gsminute}}</div>
					<div>:</div>
					<div>{{ gssecond }}</div>
			</div>
			<div class="gsoption">
				<!--  -->
				<div class="effect" @click="effecfun(1)">
					<input type="checkbox" value="flashnavgatioh" name="flashnav" v-model="flashnav" class="checkdot">
					特效
					<!-- <span class="animaltype"></span> -->
				</div>
				<div class="effect" @click="effecfun(2)" >
					<input  type="checkbox" value="language" name="changboxlangname" v-model="changboxlangname" class="checkdot">
          {{webCurrlanguage}}
<!--					中/EN-->
					<!-- <span class="langvagetype"></span>  -->
				 </div>
			</div>
		</div>
	</div>

	<div>
		<!-- 	<div id="lampadario" class="labelOn label">
		    <label for="switch"></label>
		  </div> -->
<!--    {{typeof ($store.state.displaylanguage)}}-->
<!--    {{langvagechecked}}-->
	</div>
</template>

<script>
import {ref, reactive, onBeforeMount, onMounted} from 'vue'
import {useStore} from "vuex";

export default{



	    setup(){

		  let maintitle=ref('金钤科技');
		  let gstimer=ref(0);
		  let gshour=ref(0);;let gsminute=ref(0);;let gssecond=ref(0);;
		  let texttimercolor=ref(0);
		  let flashnav=ref([]);
		  let webCurrlanguage=ref('English');
		  const changboxlangname=ref([]);

        const store=useStore();

        onMounted(()=>{
          flashnav.value[0]='flashnavgatioh';  //默认checkbox 选中
          changboxlangname.value[0]='language'
        })

		  function changeColor(){

			  texttimercolor="red";
			  // console.log(texttimercolor);
		  }
		  function effecfun(keydowntemp){

		    if(keydowntemp=='2'){

          //maintitle=store.state.languageType;
          if(changboxlangname.value[0]=='language'){

            //console.log(maintitle);   //英文

            store.commit('resetLanguageType');
            // webCurrlanguage.value='中文';
            //
            // console.log(store.state.displaylanguage);
          }else {

            //console.log(maintitle);  //中文

            store.commit('setLanguageType');
            // webCurrlanguage.value='English';
            // console.log(store.state.displaylanguage);
          }
          // console.log(changboxlangname.value[0]);
          // langvagechecked.value='';
          // console.log(typeof( )) ;

        }
        if(keydowntemp=='1') {
          if(flashnav.value[0]=='flashnavgatioh'){
            store.commit('resetAnimalType');
          }else {

            store.commit('setAnimalType');
            // console.log(store.state.displaylanguage);
          }
          // console.log(keydowntemp);
        }
			   // console.log(keydowntemp);
		  }

		  setInterval(()=>{

		      gstimer.value= formatDate();

		      // console.log(timerarr);
		      // console.log(gstimer.value);
		    },500);


		//-------------------------------------
		    function  formatDate() {
		      let nowDate;
		      let date = new Date();
		      let year = date.getFullYear(); // 年
		      let month = date.getMonth() + 1; // 月
		      let day = date.getDate(); // 日
		      let week = date.getDay(); // 星期
		      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
			  let Enweek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
		      let hour = date.getHours(); // 时
		      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
		      let minute = date.getMinutes(); // 分
		      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
		      let second = date.getSeconds(); // 秒
		      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
		      // nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
		      // nowDate = year+'/'+month+'/'+day+'  '+ hour+':'+minute+':'+second+'  '+ weekArr[week];

		      nowDate =  hour+':'+minute+':'+second;

		              gshour.value=hour;
		              gsminute.value=minute;
		              gssecond.value=second;

		       return nowDate;

		      // console.log(nowDate);
		    }

		    //--------------------------------------------------
		     return{maintitle,gstimer,gshour,gsminute,gssecond,changeColor,texttimercolor,effecfun,changboxlangname,flashnav,webCurrlanguage};

		}
	}
</script>

<style scoped>
	@import url("../../../public/commcss/led.css");

	#gswebhead{
		width: 10rem;
		height: 0.618rem;
			/* 变成弹性盒第一步	   */
		display: flex;
		 /* 水平居中 space-around;      */
		justify-content: space-between;
	}
	#gscompanylogopic{
		/* margin: 0.1rem; */

		width: 1rem;
		margin-left: 1.68rem;
		height: 0.618rem;
		/* background-color: #ff0000; */
	}
  #gscompanylogopic a{
    /*background-color: #52E923;*/
    width: 1rem;
    height: 0.618rem;
    font-size: 0.1rem;

     display: inline-block;
     text-decoration: none;
     background-image: url("../../assets/weblogo.jpg");
     /*background-size: 36% 36%;*/
    background-size: 1rem 0.618rem;
    background-repeat: no-repeat;

     /*text-indent: 1.2rem;*/
     /*white-space: nowrap;*/
     /*overflow: hidden;*/
   /* 第二种方式   当CSS 不能下载时，字符一样可以出来*/
    height: 0rem;
    padding-top: 0.618rem;
    overflow: hidden;




   }

	/*#gscompanylogopic img{*/
	/*	width: 100%;*/
	/*	height: 100%;*/
	/*}*/
	#headright{
		margin-right: 1.78rem;
		width: 1.60rem;
    /*width: 2.5rem;*/
		height: 0.618rem;
		/* background-color: #00B0E8; */
	}
	#headright .gstimer {
		/* 变成弹性盒第一步	   */
		/*      margin-top: 0.0618rem;*/
    margin-top: 0.10rem;
    /*text-align: center;*/
		     display: flex;
			 justify-content: space-around;
		     /* height: 38%; */
			 height: 0.23rem;
			 width:100%;

			 font-family: 'Goldseal_Digital';

			  font-size: 0.16rem;
			  font-weight:800;/* //bold ;//bolder ; */
			 /* background-color: #ff0000; */
			 /* transform: translate(-50%, -50%); */
			 /* color: #0527af;*//*#daf6ff;#0aafe6*/
			 text-shadow: 0 0 0.01rem #3437f8, 0 0 0.03rem #3437f8;

		}

	#headright .gsoption{
		     font-family:'阿里巴巴普惠体 B';
		     height:0.26rem;
			 /*width:100%;*/
       white-space: nowrap;
			/* padding-top: 0.00rem; */
			 font-weight:600;
			 font-size: 0.1rem;
		   display: flex;
			 justify-content: space-around;
			  /*background-color: #007700;*/
	}
	#headright .effect{
		display: flex;
		justify-content: space-between;
		/* text-align: center; */
		align-items: center;
		/* background-color: #00CC66; */
	}
	#headright  .animaltype {
		 /* display: block; */
		 /* margin-left:0.5rem; */
		 /* margin-top: 0rem; */

		/* display: flex;
		justify-content: space-between; */
		/* position: absolute;
		top:0rem;
		left:0rem; */

		/* padding-left: 0.2rem; */
		/* margin-top: 0.06rem; */

	}
     #headright.langvagetype{

    }

	#headright .checkdot{
		margin-right: 0.06rem;
    /*checked:“checked”;*/
		/* background-color:#0527af ; */
		/* margin-left: 0.1rem; */
		/* display: inline-block; */
		/* display: block; */
		/* padding-top: 0.6rem; */
		/* margin-top: 0.00rem; */
		/* margin-left:-0.16rem; */
		/* padding-left: 0rem;margin-left: -0.16rem; */

	}
	/* ============================================================================== */
	input[type=checkbox] {
	            width: 0.10rem;
	            height: 0.10rem;
	            margin-top: 0;
	            background-color: #fff;
	            border: 0.01rem solid #0527af;
	            border-radius: 0.06rem;
	            color: #0527af;
	            text-align: center;
	            line-height: 0.15rem;
	            -webkit-appearance:none;
	            -moz-appearance:none;
	            -ms-appearance:none;
	            -o-appearance:none;
	            appearance:none;
	            outline: none;

	        }

	        input[type=checkbox]:hover {
	            border-color: #3437f8;
	        }

	        input[type=checkbox]:checked {
	            color: #0527af;
	            background-color: #0527af;
				box-shadow: 0 0 0.03rem #0527af, 0 0 0.06rem #0527af;
	            border: 0.01rem solid #0527af;
	        }

	        input[type=checkbox]:after {
	            content: " ";
	        }
</style>
