<template>
  <imagbanner :propstitle="subtitle" :propsimgurl="bannerimgurl">    </imagbanner>



  <div class="newbox">
    <div class="totaltitle">
      <ul>
        <li   v-for="(newstotaltitle,i) in newstypetitle" :key="i" @click="newstitlelistkey(i)" :class="{liactive:i==newstitleindex}">{{newstotaltitle}}</li>
      </ul>
    </div>
  </div>


  <el-row    >
    <el-col    :span="6" :offset="4" >
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 新闻标题 副标题 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>
  </el-row>


  <div class="newbox">

    <div class="newsitem">
      <div class="newstext" v-for="(titleitem,i) in gsnewsimformation.newstitle" :key="i" @click="readnewskey(i)"
           v-if="Newstotal!=0"
      >

        <div class="newtitle"> {{titleitem}}</div>
        <div class="secondnewtitle">
          <div class="newsubtitle"> {{gsnewsimformation.newsubtitle[i]}}  </div>
          <div class="newdate"> {{gsnewsimformation.newdate[i]}}  </div>
        </div>
      </div>
      <div v-if="Newstotal==0">
        <div v-if="queryPages.serch!=''">
          <h2>搜索后……</h2>
        </div>
        <h2>没有新闻数据 ^L^</h2>
      </div>
    </div>
  </div>



<!-- :gutter="00"  <el-button @click="editpicOrderdialogVisible = false">取消</el-button>-->

  <!--  -------------------------------------------------------- -->
  <el-row    >



    <el-col      :span="5" :offset="10">
    <el-pagination background layout="total,  prev, pager, next,"
                   v-model:currentPage="queryPages.pagenumber"
                   :page-size="queryPages.pagesize"
                   :page-sizes="[5, 10, 20, 30]"
                   :total="Newstotal"
                   hide-on-single-page
                   @size-change="handleSizePagesChange"
                   @current-change="handleCurrentPageChange"
                   v-if="Newstotal!=0"
    />
    </el-col>



  </el-row>
  <!-- -------------------------------------------------------- -->

  <backtop v-show="backtopflag">  </backtop>

</template>

<script>
// import backtop from "@/components/backtop/backtop";
import imagbanner from '@/components/imgbanner/imgbanner.vue'
import backtop from "@/components/backtop/backtop";

import {onMounted, reactive, ref} from "vue";
import {getnewsList, getnews,} from "@/axios/webindex";


import {useRouter} from "vue-router";
import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue';

export default {
  name: 'news',

  components: {

    imagbanner,
    backtop,
  },
  setup(props){
    let subtitle=ref('消息传达');
    let bannerimgurl=ref('');
    let backtopflag=ref(0);
    let newstypetitle=ref([]);
    let newstitleindex=ref(0);

    let emptySerchCount=ref(0);

    const gsrouter=useRouter();

    const gsnewsimformation=reactive({
      // newstypetitle:[],
      // newstypeurl:[],
      newstitle:  [],     //标题
      newsauthor: [],       //作者
      newdate:    [],      //日期
      newsubtitle:[],       //副标题
      newdetails: [],       //新闻内容
    });

    let queryPages=reactive({
      newstype:'1',
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let Newstotal=ref(5);


    onMounted(()=>{
      //=================================================================
      //加载 产品介绍 图片和数据 存入缓存中
      getnewsList("index.php/index/newscenter").then(res=>{
        // console.log(res);
        subtitle.value=res.data.data[0].title;
        bannerimgurl.value=res.data.data[0].bannerimg;
        newstypetitle.value=res.data.data[0].newstypetitle;

      }).catch(err=>{
        console.log(err);
      });
      //==================================================================
      // queryPages
      queryPages.pagenumber= window.sessionStorage.getItem('pagenumber');
      queryPages.pagesize= window.sessionStorage.getItem('pagesize');
      queryPages.newstype= window.sessionStorage.getItem('newstype');
      queryPages.serch= window.sessionStorage.getItem('search',queryPages.serch)
      // Newstotal.value= window.sessionStorage.getItem('newstotal');
      // console.log(queryPages.newstype);
      // console.log('============',queryPages.newstype);
      // console.log('-------------',queryPages.pagesize);
      // console.log('-------------->',queryPages.pagenumber);
      if(queryPages.serch===null)queryPages.serch='';

       if(queryPages.newstype===null)
      {
        newstitleindex.value=0;
        queryPages.newstype='1';
      }else{
         newstitleindex.value=queryPages.newstype-1;
       }
      initnewsdata();
      //=================================================================

    })
    //==================================================================
    //监听滚动屏像素
    window.addEventListener('scroll',()=>{
      // console.log('   ---');
      // 页面滚动距顶部距离
      let scrollTop =
          // window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      // console.log(scrollTop)

      if(scrollTop>300){   //大于380个像素时出现返回顶部按键
        backtopflag.value=true;//backtop enable
      }else {
        backtopflag.value=false;
      }

    }),true
    //==================================================================

    function initnewsdata(){

      getnews(queryPages).then(res=>{
        // console.log(res);

        queryPages.pagenumber=res.data.data.current_page;
        queryPages.pagesize=res.data.data.per_page;

        let type = Object.prototype.toString.call(res.data.data.data);
        let arr = [];
        if (type === "[object Array]"){
            // console.log('是一个数组');
          // ProductstableData.value=res.data.data.data;
          arr=res.data.data.data;
        }
        if (type === "[object Object]"){
          // console.log('是一个对象');

          for (let i in res.data.data.data) {
            arr.push(res.data.data.data[i]);
          }
          // ProductstableData.value=arr;
        }

          let row,arryleth;
          // arryleth=res.data.data.data.length;
        arryleth=arr.length;
          // console.log(arryleth,'============');
        gsnewsimformation.newstitle.length=0;
        gsnewsimformation.newsauthor.length=0;
        gsnewsimformation.newdate.length=0;
        gsnewsimformation.newsubtitle.length=0;
        gsnewsimformation.newdetails.length=0;
          for (row=0;row<arryleth;row++){
            // gsnewsimformation.newstitle[row]=res.data.data.data[row].newstile;
            // gsnewsimformation.newsauthor[row]=res.data.data.data[row].newsauthor;
            // gsnewsimformation.newdate[row]=res.data.data.data[row].newsdate;
            // gsnewsimformation.newsubtitle[row]=res.data.data.data[row].newssubtile;
            // gsnewsimformation.newdetails[row]=res.data.data.data[row].newsdetails;

             gsnewsimformation.newstitle[row]=arr[row].newstile;
            gsnewsimformation.newsauthor[row]=arr[row].newsauthor;
               gsnewsimformation.newdate[row]=arr[row].newsdate;
           gsnewsimformation.newsubtitle[row]=arr[row].newssubtile;
            gsnewsimformation.newdetails[row]=arr[row].newsdetails;
            // console.log(gsnewsimformation.newstitle[row],'============');
          }
        //Newstotal.value=arryleth;//res.data.data.total;
        Newstotal.value=res.data.data.total;
        window.sessionStorage.setItem('pagenumber',queryPages.pagenumber);
        window.sessionStorage.setItem('pagesize',queryPages.pagesize);
        window.sessionStorage.setItem('newstype',queryPages.newstype);
        // window.sessionStorage.setItem('newstotal',Newstotal.value);
        window.sessionStorage.setItem('search',queryPages.serch);
        // console.log('1',queryPages.newstype);
        // console.log('2',queryPages.pagesize);
        // console.log('3',queryPages.pagenumber);

      }).catch(err=>{
        console.log(err);
      });;
    }

    function newstitlelistkey(keyindex){
      newstitleindex.value= keyindex;
      // console.log(keyindex);
      queryPages.newstype=keyindex+1;
      // gsnewsimformation.newstitle.length=0;
      // gsnewsimformation.newsauthor.length=0;
      // gsnewsimformation.newdate.length=0;
      // gsnewsimformation.newsubtitle.length=0;
      // gsnewsimformation.newdetails.length=0;

      //自动清空搜索框
      if(queryPages.serch!=''){

        if(emptySerchCount.value>newstypetitle.value.length){
          emptySerchCount.value=0;
          queryPages.serch='';
          // console.log('emptySerchCount.value');
        }
        // console.log(emptySerchCount.value);
        emptySerchCount.value++;
      }

      initnewsdata();
    }
    function readnewskey(keyindex){
      // console.log(keyindex);
      gsrouter.push({
        path:'/newdetails',
        query:{
          newstype:queryPages.newstype,
          page:(Newstotal.value),
          currpage:queryPages.pagenumber,
          item:(keyindex),
          newdetail:encodeURIComponent(JSON.stringify(gsnewsimformation)),
        }

      });
    }
    function handleCurrentPageChange(){
      initnewsdata();
    }
    function handleSizePagesChange(){

      initnewsdata();
    }
    function serchKey(){
      emptySerchCount.value=0;
      initnewsdata();
    }
    function serchClearKey(){
      queryPages.serch='';
      initnewsdata();
    }
    //======================================================================================
    return{
      backtopflag,subtitle,bannerimgurl,
      gsnewsimformation,
      queryPages,Newstotal,handleCurrentPageChange,handleSizePagesChange,
      newstypetitle,newstitleindex,newstitlelistkey,readnewskey,
      Search,serchClearKey,serchKey,
    }
  }
}
</script>

<style scoped>

/deep/ .el-pagination__total{
  color: #0527af !important;
}
.row_high{
  margin-top: 0 ;
  margin-bottom: 0;

}
/*.el-row >>> .el-col {*/
  /*margin-top: 0 ;*/
  /*margin-bottom: 0;*/
  /*padding-top: 0 ;*/
  /*padding-bottom: 0 ;*/
  /*margin: 0px;*/
  /*padding: 0px;*/
  /*height: 0.2rem;*/
/*}*/
.totaltitle{
  margin: 0 auto;
  /*height: 0.28rem;*/
}
.totaltitle ul{
  display: flex;
}
.totaltitle li{

  margin: 0rem 0.6rem;

  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:500;
  line-height: 0.12rem;
  user-select:none;
  cursor: pointer;
  white-space: nowrap;
  text-shadow:0.004rem 0.004rem 0.004rem #0527af;
  /*background-color: #0F4B00;*/
}
.totaltitle .liactive{
  padding-bottom: 0.04rem;
  border-bottom: 0.02rem solid #0527af;
  /*box-shadow: 0.004rem 0.004rem 0.004rem #0527af;*/

}

.newbox{
  width: 6.8rem;
  /*height: 3.06rem;*/
  margin: 0.1rem auto;
  display: flex;
  /*user-select: none;*/
}

.el-pagination{
  margin-top: 0.38rem ;
  margin-bottom: 0.18rem ;
}

/*   ===========================             */
.newsitem{
  width: 100%;
  margin: 0 auto;
}

.newsitem .newstext{
  /*width: 100%;*/
  height: 0.38rem;
  display: flex;
  /*float: left;*/
  /*background-color: #0F4000;*/
  /*align-items: center;*/
  margin: 0 auto;
  flex-direction: column ;

}
.newtitle{
  width: 60%;
  /*align-items: center;*/
  /*text-indent: 2em;*/
  text-align: left;
  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:500;
  line-height: 0.2rem;
  /*background-color: #0F4;*/

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;
}
.newsitem  :hover{
  /*background-color: #0F4;*/
  text-decoration: underline;
  color: #3437f8;
  /*font-style: italic;*/
}
/*===========================================*/
.secondnewtitle{
  display: flex;
  flex-direction: row ;
}
.newsubtitle{
  /*text-indent: 2em;*/
  /*padding-left: 0.1rem;*/
  text-align: left;
  width: 40%;

  font-size: 0.068rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 0.1rem;
}
.newdate{
  width: 18%;
  text-align: right;
  line-height: 0.1rem;
  font-size: 0.088rem;
}
.newsitem newsdetails{
  background-color: #0F4B00;
}




</style>
