import { createStore } from 'vuex'
import gswebbannerstore from "@/store/homebannerpic";
import gswebadbannerstore from '@/store/homeadbannerpic'
import gsproducttypetitlestore from '@/store/storeproducttypetitle';

export default createStore({
  state: {
	  displaylanguage:1,
	  displaystye:1,
  },
  getters: {
  },
  mutations: {
	    resetLanguageType(state){ state.displaylanguage=0;},
	    setLanguageType(state){ state.displaylanguage=1;},

		resetAnimalType(state){ state.displaystye=0;},
		setAnimalType(state){ state.displaystye=1;},

  },
  actions: {
  },
  modules: {
      gswebbannerstore,
      gswebadbannerstore,
      gsproducttypetitlestore,
  }
})
