<template>
  <div class="about">

    <imagbanner :propstitle="subtitle" :propsimgurl="bannerimgurl">    </imagbanner>

<!--	   <h1>This is an about page</h1>-->

    <div class="imformation">
      <div class="companypic">
        <img :src="companyimg">
      </div>
      <div class="companytext">
          <p   v-html="companyimformation"> </p>
      </div>
    </div>

    <backtop v-show="backtopflag">  </backtop>
  </div>
</template>
<script>

import imagbanner from '@/components/imgbanner/imgbanner.vue'
import backtop from "@/components/backtop/backtop";

  import {onMounted, ref} from "vue";
  import {getadBannerList} from "@/axios/webindex";


	export default {

	  components: {
      imagbanner,
      backtop,
	  },

	  setup(props){
      let subtitle=ref('公司简介');
      let bannerimgurl=ref('');

      let companyimg=ref(null);
      let companyimformation=ref("");

      let backtopflag=ref(0);

      onMounted(()=>{
        //加载 关于公司 图片和数据 存入缓存中
        getadBannerList("index.php/index/aboutus").then(res=>{
          // console.log(res);
          subtitle.value=res.data.data[0].title;
          bannerimgurl.value=res.data.data[0].bannerimg;

          // companyimg.value=res.data.data[0].img;

          // console.log(subtitle.value);
          // console.log(companyimg.value);


          companyimformation.value=res.data.data[0].aboutus;
          // console.log(companyimformation.value);
        }).catch(err=>{
          console.log(err);
        });
      //==================================================================
        //监听滚动屏像素
        window.addEventListener('scroll',()=>{
          // console.log('   ---');
          // 页面滚动距顶部距离
          let scrollTop =
              // window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop
          // console.log(scrollTop)

          if(scrollTop>380){   //大于380个像素时出现返回顶部按键
            backtopflag.value=true;//backtop enable
          }else {
            backtopflag.value=false;
          }

        }),true
      });
      //==================================================================

      return{
        subtitle,bannerimgurl,companyimg,companyimformation,
        backtopflag,
      }
    }
	}
</script>


<style scoped>
.imformation{
  width: 6.8rem;
  height: 2.06rem;
  margin: 0 auto;
  display: flex;
  user-select: none;
}
.companypic{
  /*flex: 1;*/
  width: 100%;
  height: 100%;
  display: flex;

  align-items :center;
  /*background-color: #52E923;*/
}
.companypic img{
  /*width: 100%;*/
  /*height: 100%;*/

  border-radius: 0.06rem;
}
.companytext {

   font-size: 0.12rem;
  /*font-family:'阿里巴巴普惠体 ';*/
  text-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;
  line-height: 0.18rem;
  /*text-indent: 2em;*/
  padding: 0.06rem 0.08rem;

  text-align:left;
  /*display:inline-block;*/
  /*word-break :break-all ;*/
  /*word-wrap:break-word;*/
}
</style>


