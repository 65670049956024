<template>
<h1>找回密码</h1>

<!-- finish-status="success" simple -->
  <el-steps :active="setpcount"
            align-center
            title="邮箱找回密码"
   >
    <el-step title="第一步 " :icon="Document" description="输入用户名" />
    <el-step title="第二步 " :icon="Upload" description="登录邮箱获取校验码" />
    <el-step title="第三步 " :icon="CircleCheck " description="成功找回用户密码" />
<!--    <el-step title="第四步 " :icon="CircleCheck " description="成功找回用户密码" />-->
  </el-steps>


<!--============================= class="labeitlcolor"   ===========================-->
<!--    class="inputtextcolor" -->
  <div class="firststep" v-if="setpcount==0">
    <el-form
        ref="ruleFormRef"
        :model="userinfor"
        :rules="rules"
        label-width="100px"

    >

      <el-row   >
        <el-col :span="8" :offset="7">
          <el-form-item   label="用户名：" prop="username" >
            <el-input     size="large" :prefix-icon="User"  placeholder="请输入要找回密码的账号" v-model.trim="userinfor.username" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row >
        <el-col :span="8" :offset="7">
          <el-form-item   label="邮箱：" prop="email">
            <el-input    size="large" :prefix-icon="Message " placeholder="请输入用户名绑定的电子邮箱" v-model.trim="userinfor.email"  />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row    :gutter=" 10"     >
        <el-col :span="6" :offset="7">
          <el-form-item   label="验证码：" prop="codeword">
            <el-input    size="large"  :prefix-icon="Orange  " placeholder="请输入验证码" v-model.trim="userinfor.codeword"  />
          </el-form-item>
        </el-col>
        <el-col :span="2" :offset="0">

          <div class="getgscode" @click="refreshLoginCode()">
            <gs-identify :identifyCode="LoginCode" :contentHeight="38" :contentWidth="108"></gs-identify>
          </div>

        </el-col>
      </el-row>
    </el-form>
  </div>
<!--  @keyup.enter.native="loginkey(ruleFormRef)"    ========================================================-->
  <div>
    <el-button type="primary" @click.enter.native="stepnextkey(ruleFormRef)"  size="large">{{ buttonName }}</el-button>
  </div>
</template>

<script>
import { Edit, Picture, Upload, ChatLineSquare,Document,UserFilled,User,Message,Orange,CircleCheck       } from '@element-plus/icons-vue';
import {onMounted,reactive, ref} from "vue";

import GsIdentify from "@/components/gsIdentify";
import { ElMessage } from 'element-plus';
import { findemaildb,usenamefindemaildb} from "@/axios/webindex";

import router from "@/router";


export default {
  name: "findpassword",
  components: {
    GsIdentify,
    // User,Search,
  },
  setup(){

    let setpcount=ref(0);
    let userinfor=reactive({
      username:'',
      password:'',
      email:'',
      DBemail:'',
      codeword:'',
    });
    const ruleFormRef = ref();
    const rules = reactive({
      username: [
        { required: true, message: 'Please input user name', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      password: [
        { required: true, message: 'Please input user password', trigger: 'blur' },
        { min: 6, max: 10, message: 'Length should be 6 to 10', trigger: 'blur' },
      ],
      codeword:[
        { required: true, message: 'Please input Check code ',  trigger: 'blur' },
        { min: 4, max: 4, message: 'Length should be 4  ',      trigger: 'blur' },
      ],
      email:[
        { required: true, message: 'Please input email ', trigger: 'blur' },
        { min: 3, max: 20, message: 'Length should be 3 to 20', trigger: 'blur' },
        { validator:checkEmial, trigger: 'blur'  }
          ]
    })
//  自定义规则  校验邮箱
    function checkEmial (rule,value,callback) {
      //邮箱验证的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {  //合法的邮箱
        return callback();
      } else {
        callback(new Error('请输入合法的邮箱'));
      }
    }

    let buttonName=ref('下一步');

    let LoginCode=ref(0);
    let LoginCodes=ref("0123456789");  //绘制的随机数

    onMounted(()=>{
      userinfor.username= window.sessionStorage.getItem('findusername');
      refreshLoginCode();

      findemail();

    })

    //查找邮箱从数据库
    async function findemail(){

      const {data: res} = await findemaildb(userinfor);

      console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出数据
        userinfor.email=res.data;
        userinfor.DBemail=res.data;
      }
    }

    async  function stepnextkey(formEl){

      if(setpcount.value==0){
        // console.log(valid,'=======================>');
        if (!formEl) return;
        //输入数字长度对了规则里的valid返回为true  否则返回为 false
        // ruleFormRef.value.validate(valid=>{
        //     console.log(valid);
        //if (valid) {}
        // })
        //  return;
        // console.log(formEl,'=======================>222');
        formEl.validate(async valid=> {
          if (valid) {

            if(LoginCode.value!=userinfor.codeword)
            {
              ElMessage({
                showClose: true,
                message: '验证码输入错误',
                type: 'error',
              });

              refreshLoginCode();
              return;
            }

            // console.log(userinfor.username);
            // console.log(userinfor.email);
            //进行用户名和邮箱查询
            const {data: res} = await usenamefindemaildb(userinfor);

            // console.log(res);

            if (res.code == 200) {
              ElMessage({
                showClose: true,
                message: '更改数据成功 : ' + res.msg,
                type: 'success',
              });
              //从服务中取出数据
              setpcount.value=1;
              buttonName.value='返回登录页面';
            } else {

              ElMessage({
                showClose: true,
                message: '更改数据失败 : ' + res.msg,
                type: 'error',
              });
            }

          }
      })

      }

      if(setpcount.value==1){
        window.localStorage.removeItem("gsloginremeber");
        window.localStorage.removeItem('gsloginusername');
        window.localStorage.removeItem('gsloginuserpassword');
          router.push('/website');

      }

      // setpcount.value++;
    }
//=============================================================
    function refreshLoginCode(){
      LoginCode.value = "";
      makeCode(LoginCodes.value, 4);
      // console.log(LoginCode.value+'========================================');
    }

    function  randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    };
    // 随机生成验证码字符串
    function makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        LoginCode.value += data[randomNum(0, data.length - 1)]
      }
    }

//==================================================================
    return{
      Edit, Picture, Upload,ChatLineSquare,Document,UserFilled,User,Message,Orange ,CircleCheck,
      setpcount,userinfor,LoginCode,
      refreshLoginCode,
      stepnextkey,rules,ruleFormRef,
      buttonName,
    }
  }
}
</script>

<style scoped>
.el-steps{
  margin: 0.4rem;
}
.firststep{
  /*margin: 0 auto;*/
  /*left: 50%;*/
}
/deep/.el-dialog__body{
  display: inline-block;
}
</style>
