<template>
<h1>404 页面找丢了</h1>
</template>

<script>
export default {
  name: "nofoundpage"
}
</script>

<style scoped>

</style>



<!--<template>-->
<!--  <div class="about">-->
<!--    <p>Back to top</p>-->

<!--    <div style="height: 999px;">-->

<!--    </div>-->

<!--    <div v-on:click="goTop()">-->
<!--      <img src="../../assets/weblogo.jpg" alt="">-->
<!--    </div>-->


<!--  </div>-->
<!--</template>-->
<!--<script>-->
<!--import {-->
<!--  getCurrentInstance,-->
<!--  ref,-->
<!--  onMounted,-->
<!--  onDestroyed-->
<!--} from 'vue'-->
<!--import {-->
<!--  useRoute,-->
<!--  useRouter-->
<!--} from "vue-router"-->


<!--export default {-->
<!--  name: "nofoundpage",-->

<!--  setup() {-->
<!--    let {-->
<!--      proxy-->
<!--    } = getCurrentInstance();-->

<!--    function handleScroll() {-->
<!--      let scrollTop =-->
<!--          window.pageYOffset ||-->
<!--          document.documentElement.scrollTop ||-->
<!--          document.body.scrollTop;-->
<!--      proxy.scrollTop = scrollTop;-->
<!--    }-->

<!--    function goTop() {-->
<!--      let timer = setInterval(() => {-->
<!--        let ispeed = Math.floor(-proxy.scrollTop / 10);-->
<!--        document.documentElement.scrollTop = document.body.scrollTop =-->
<!--            proxy.scrollTop + ispeed;-->
<!--        if (proxy.scrollTop === 0) {-->
<!--          clearInterval(timer);-->
<!--        }-->
<!--      }, 10);-->
<!--    }-->

<!--    return {-->
<!--      handleScroll,-->
<!--      goTop-->
<!--    }-->

<!--  },-->
<!--  mounted() {-->
<!--    window.addEventListener("scroll", this.handleScroll);-->
<!--  },-->
<!--  destroyed() {-->
<!--    document.removeEventListener("scroll", this.handleScroll);-->
<!--  },-->
<!--}-->
<!--</script>-->

