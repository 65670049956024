<template>
<!--    <h1>左侧导航</h1>-->
<!-- width="200px"  :width="isCollapse ? '64px' : '200px' "           :default-active="$route.path"       首页直接 path      background-color="#0527af"          -->
  <el-aside :width="isCollapse ? '65px' : '200px' " >
    <div class="togglepop" @click="togglekey()">
      <el-icon size="25px" v-if="isCollapse"><Expand /></el-icon>
      <el-icon size="25px"   v-if="!isCollapse"><Fold /></el-icon>
    </div>


      <el-menu
          active-text-color="#3437f8"
          :collapse="isCollapse"
          :collapse-transition="false"
          class="el-menu-vertical-demo"
          :default-active=activePath
          text-color="#0527af"
          :unique-opened="true"
          router
      >
<!--       左则 弹出菜单      :unique-opened="false" -->

<!--        <el-menu-item :index="menu.url" v-for="menu in menuitem" :key="menu.url"> -->
<!--          <el-icon>  <component  :is="menu.icon"> </component>  </el-icon>-->
<!--          <span>{{menu.title}}</span>-->
<!--        </el-menu-item>-->

                  <!--   一级菜单           -->
                  <el-sub-menu :index="item.mid"  v-for="(item,count) in menuitemlist" :key="item.mid">
                        <!--   一级菜单的模板区域          -->
                        <template #title>
                          <!--  图标             -->
                          <el-icon><component :is="menuiconlist[count]"></component></el-icon>
                          <!--   文本            -->
                          <span> {{item.menuname}}</span>
                        </template>
                            <template v-if="item.jumpwebmenugrandson==''">
                              <!--   二级菜单    无三级菜单         -->
                              <el-menu-item :index="'/'+subitem.menupath" v-for="(subitem,subcount ) in item.webmenuSon" :key="subitem.id" @click="menuitemclick('/'+subitem.menupath)">
                                <template #title>
                                  <!--  图标             -->
<!--                                  <el-icon><document /></el-icon>-->
                                  <el-icon><component :is="submenuiconlist[count+subcount]"></component></el-icon>
                                  <!--   文本            -->
                                  <span>{{subitem.menuname}}</span>
                                </template>


                              </el-menu-item>
                            </template>
                        <template v-else>
                          <!--   二级菜单     有三级菜单       -->
                          <el-sub-menu :index="'/'+subitem.menupath" v-for="(subitem,subcount ) in item.webmenuSon" :key="subitem.id" >
                              <template #title>
                                  <!--  图标             -->
                                  <!--                                                                               @click="menuitemclick('/'+subitem.menupath)"   -->
                                  <el-icon><component :is="submenuiconlist[count+subcount]"></component></el-icon>
                                  <!--   文本            -->
                                  <span>{{subitem.menuname}}</span>
                              </template>
                                  <!--   三级菜单           -->
                                  <el-menu-item :index="'/'+thirditem.menupath" v-for="(thirditem,thirdcount ) in item.jumpwebmenugrandson" :key="thirditem.id" @click="menuitemclick('/'+thirditem.menupath)">
                                    <template #title>
                                      <!--  图标             -->
                                      <!--                                  <el-icon><document /></el-icon>-->
<!--                                      <el-icon><component :is="thirdmenuiconlist[thirdcount]"></component></el-icon>-->
                                      <el-icon><component :is= "count==3  ? newsmenuiconlist[thirdcount] : productmenuiconlist[thirdcount]" ></component></el-icon>
                                      <!--   文本    {{}}        -->
                                      <span>{{thirditem.menuname}}</span>
                                    </template>
                                  </el-menu-item>
                            </el-sub-menu>
                        </template>
                  </el-sub-menu>


      </el-menu>

<!-- ===========================================================   -->



    <!--    router  ----------------------------------------------------------------    -->
<!--    <el-menu-->
<!--        active-text-color="#3437f8"-->
<!--        background-color="#0527af"-->
<!--        class="el-menu-vertical-demo"-->
<!--        default-active="1"-->
<!--        text-color="#fff"-->
<!--        @open="handleOpen"-->
<!--        @close="handleClose"-->

<!--    >-->
<!--          &lt;!&ndash;   一级菜单           &ndash;&gt;-->
<!--          <el-sub-menu index="1">-->
<!--            &lt;!&ndash;   一级菜单的模板区域          &ndash;&gt;-->
<!--            <template #title>-->
<!--              &lt;!&ndash;  图标             &ndash;&gt;-->
<!--              <el-icon><document /></el-icon>-->
<!--              &lt;!&ndash;   文本            &ndash;&gt;-->
<!--              <span> 导航一</span>-->
<!--            </template>-->

<!--            &lt;!&ndash;   二级菜单           &ndash;&gt;-->
<!--            <el-menu-item index="1-1">-->
<!--              <template #title>-->
<!--                &lt;!&ndash;  图标             &ndash;&gt;-->
<!--                <el-icon><document /></el-icon>-->
<!--                &lt;!&ndash;   文本            &ndash;&gt;-->
<!--                <span> 导航二</span>-->
<!--              </template>-->
<!--            </el-menu-item>-->
<!--          </el-sub-menu>-->
<!--    </el-menu>-->
    <!--  <el-icon><TrendCharts /></el-icon> ----------------------------------------------------------------    -->




  </el-aside>
</template>

<script>
import {Avatar,Grid,ShoppingBag,Edit,TrendCharts,AlarmClock,Menu, Setting,Document,Notebook,Fold,Expand,
       Platform,Management,Picture,User,MagicStick,PartlyCloudy,ChatSquare,ChatLineSquare,CopyDocument, } from "@element-plus/icons-vue";
import {onMounted, reactive, ref} from "vue";

import {menulist} from "@/axios/webindex";
import { ElMessage } from 'element-plus'
//<el-icon><CopyDocument /></el-icon>
export default {
  name: "navmenu",
  components: {
    Avatar,Grid,ShoppingBag,Edit,TrendCharts,AlarmClock,Menu,Setting,Document,Notebook,Fold,Expand,
    Platform,Management,Picture,User,MagicStick,PartlyCloudy,ChatSquare,ChatLineSquare,CopyDocument,
  },
  setup(){
    let isCollapse=ref(false);
    let menuitem=reactive([
      {title:'首页',url:'/MenuHome',icon:Menu},
      {title:'关于我们',url:'/MenuAboutUs',icon:Notebook},
      {title:'产品方案',url:'/MenuProducts',icon:Document},
    ])
    //<el-icon>< /></el-icon>
    let menuitemlist=ref([]);
    let menuiconlist=ref(['Management','Grid','ShoppingBag','TrendCharts','Edit','Avatar']);
    let submenuiconlist=ref(['Platform','Picture','Notebook','Menu','Document','User','CopyDocument']);
    let thirdmenuiconlist=ref(['Document','Notebook','Menu','Document','Menu']);
    let productmenuiconlist=ref(['AlarmClock','MagicStick','PartlyCloudy','Document','Menu']);
    let newsmenuiconlist=ref(['Document','ChatSquare','Notebook','ChatLineSquare','Menu']);
    let activePath=ref();

    function togglekey(){
      isCollapse.value=!isCollapse.value;
    }

    onMounted(async () => {

     let res = await menulist();
      // console.log(res);
      // console.log(res.data.code);
      // console.log(res.data.msg);
      if(res.data.code==200){
        //获取数据成功
        menuitemlist.value=res.data.data;
        // console.log(menuitemlist.value);
        activePath.value=window.sessionStorage.getItem('activePath')||'/welcome';
      }else{
        ElMessage({
          showClose: true,
          message: '获取菜单数据失败：'+res.data.msg,
          type: 'error',
        });
      }

    })
   // ===============================================
   function menuitemclick(savepath){
        window.sessionStorage.setItem('activePath',savepath);
        activePath.value=savepath;
   }



    return{
      menuitem,
      togglekey,isCollapse,
      menuitemlist,menuiconlist,submenuiconlist,thirdmenuiconlist,productmenuiconlist,newsmenuiconlist,
      menuitemclick,
      activePath,
    }
  }
}
</script>

<style scoped>
.el-aside .el-menu{
  /*background-color: #0527af;*/
  /*   右则边框线 */
  border-right: none;
}
.togglepop{
  color: #0527af;
  height: 40px;
  display: flex;
  justify-content: end;
  margin-right:  0px;
  align-items: center;

}
.togglepop .el-icon :hover{
  background-color: #0527af;
  color: white;
  cursor: pointer;
}
.el-menu-item:hover{

}
.el-menu-item .is-active {
  background-color: #0527af;
  color: white;
  user-select: none
}
</style>
