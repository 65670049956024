export const productinfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '产品名称',
        prop : 'productname',
        // width:180,
    },
    {
        label: '产品型号',
        prop : 'productmodel',
        // width:500,
    },
    {
        label: '产品属性',
        prop : 'productdetails',
        width:130,
    },
    {
        label: '产品图片详情',
        prop : 'proudctimage',
        width:160,
    },
    {
        label: '产品文档详情',
        prop : 'productpdf',
        width:160,
    },
    {
        label: '详情选择',
        prop : 'descriptiontype',
        width:88,
    },
    {
        label: '上架',
        prop : 'status',
        width:80,
    },
    {
        label: '产品操作',
        prop : 'actions',
        // width:130,
    },

]
