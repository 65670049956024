<template>

<div id="imgbox">
   <img :src="propsimgurl">
</div>
  <div class="spacing">

    <span>{{propstitle}}</span>
    <span class="bluethinline"></span>
  </div>




</template>

<script>
export default {
  name: "imgbanner",
  props:{
    propstitle:String,
    propsimgurl:String,
  },

  setup(props){

  }
}



</script>

<style scoped>
#imgbox{
  width: 6.8rem;
  height: 2.06rem;
  margin: 0 auto;
  /*background-color: #333333;*/
  border-radius: 0.06rem;
}
#imgbox img{
    width: 100%;
    height: 100%;
  border-radius: 0.06rem;
}
/*-----------------------------*/
.spacing{
  width: 6.8rem;
  height: 0.26rem;

  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items:center;
  /*background-color: #333333;*/
}
.spacing span{
  user-select: none;
  /*font-size: 0.4em;*/
  margin-left: 0.16rem;
  display: flex;
  line-height: 0.26rem;
  /*background-color: #52E923;*/
  font-size: 0.12rem;
  font-family:'阿里巴巴普惠体 B';
  font-weight:500;
  white-space: nowrap;

  text-shadow:0.01rem 0.01rem 0.01rem #aeb1b3;
}
.bluethinline{
  width: 80%;
  height: 20%;
  display: flex;

  /*align-items:center;*/
  /*margin: 0 auto;*/
  /*height: 50%;*/
  /*line-height: 0.26rem;*/
  /*margin-left: 0.068rem;*/
  /*line-height: 0.26rem;*/
  /*padding-bottom: 0rem;*/
  background-color: #0527af;
  border-radius: 0.02rem;
  box-shadow:0.02rem 0.02rem 0.02rem  #aeb1b3;
}

</style>
