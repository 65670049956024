<template>
  <!--   添加对话框 开始   @close="SubensureDialogClose"   :visible.sync="WangeditorDialogVisible"
      v-if="WangeditorDialogVisible"-->
  <el-dialog
      :model-value="WangeditorDialogVisible"
       destroy-on-close="true"
      :title="title"
      :width="dialogwith"
      @close="NewsEditDialogClose"
      center
  >
    <!--   内容主体区      -->
<!--    <span > {{ context }} </span>-->
<!--  =====================================================  -->
    <div style="border: 1px solid #ccc">
      <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
          :mode="mode"
      />
      <Editor
          style="height: 400px; overflow-y: hidden;"
          v-model="valueHtml"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreated"
      />
    </div>
<!--  =====================================================  -->
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
          <el-button @click=SubensureEditorDialogClose>取消</el-button>
          <el-button type="warning" @click="SubensureEditorDialog" >确认</el-button>
        </span>
    </template>
  </el-dialog>
  <!--  添加确认对话框  结束     -->
</template>

<script>

import '@wangeditor/editor/dist/css/style.css' // 引入 css
import {onBeforeUnmount, ref,reactive, shallowRef, onMounted, onUpdated} from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { getCurrentInstance,} from "vue";
import { ElMessage } from 'element-plus'

import {textnewsimagegone } from "@/axios/webindex";
//import {gstextimagepicwebaddress} from '@/views/webmanage/websiteglobalvar'


export default {
  name: "wandeditordialog",
  components: { Editor, Toolbar },
  props:{
    title:{
      type:String,
      default:'对话框',
    },
    context:{
      type:Array,
      default:'',
    },
    mainid:{
      type:String,
      default:'',
    },
    dialogwith:{
      type:Number,
      default:'90%',
    },
  },
  setup(props, context){
    let loop;
    let InputimageArray=ref([]); //一共上传的图片
    let TempimageArray=ref([]);
    let PageImageArray=ref([]);//页面上的图片
    let DeleImage=reactive({
      ImageArray:[],
    });

    let CloseDialogFlag=ref(false);

    const {proxy} = getCurrentInstance();

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
    // 内容 HTML
    const valueHtml = ref('')
    const toolbarConfig = {};
    //const editorConfig = { placeholder: '请输入内容...' }
    //添加图片新增代码
    const editorConfig={MENU_CONF: {}};
    editorConfig.placeholder='请输入内容...'
    // editorConfig.onChange=(editor)=>{
    //   //当编辑区、内容变化时，即触发
    //   // console.log('content',editor.children);
    //   // console.log('html',editor.getHtml());
    // }

       // console.log('=========================================================');
    //上传图片的配置
        editorConfig.MENU_CONF['uploadImage']={
          //服务器地址
          server:'http://www.goldenseal.cn/website/newsinfo/addtextimages',
          allowedFileTypes: ['image/*'],
          // 将 meta 拼接到 url 参数中，默认 false
          // metaWithUrl: true,
          //文件上传字段名
          fieldName: 'image',
          //客户自已带参数
          meta: {
            mainid:props.mainid, //props.mainid, //props.mainid,  //mainid  可以带参数
            // mainid:1982,
            // token: 'xxx',
            // otherKey: 'yyy'
          },
          headers: {
            Authorization:  window.localStorage.getItem('gstoken'),
            // name:'image',
            // otherKey: 'xxx'
          },

          // 单个文件上传成功之后
           onSuccess(file, res) {          // JS 语法
            // console.log(`${file.name} 上传成功`, res);
            //  InputimageArray.value.push(file.name);
             InputimageArray.value.push(res.data.alt);
             // console.log(res.data.alt) ;
             // console.log( res);
             CloseDialogFlag.value=false;
          },

        }


    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()

    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    onMounted(()=>{
      // console.log(proxy);
      // console.log(props.context);


      valueHtml.value='';

      // valueHtml.value.length=0;
      // props.context
      // for(loop=0;loop<props.context.length;loop++){
      //   valueHtml.value =valueHtml.value+props.context[loop];
      //   // valueHtml.value=valueHtml.value+'<br>';
      // }
      valueHtml.value=props.context;

      editorConfig.MENU_CONF['uploadImage'].meta.mainid=props.mainid;
      // console.log('onMounted 00000  ',props.mainid);
      // console.log(editorConfig.MENU_CONF['uploadImage'].meta.mainid);
      // console.log(valueHtml.value);
      // valueHtml.value =props.context;
      // console.log('----------------->');
    });


    onUpdated(()=>{
      // console.log('===================>');
      //

      valueHtml.value='';

      // valueHtml.value.length=0;
      // props.context
      // for(loop=0;loop<props.context.length;loop++){
      //   valueHtml.value =valueHtml.value+props.context[loop];
      //   // valueHtml.value=valueHtml.value+'<br>';
      // }
      valueHtml.value=props.context;
      editorConfig.MENU_CONF['uploadImage'].meta.mainid=props.mainid;
      // console.log('onUpdated 11111 ',props.mainid);
      // console.log(editorConfig.MENU_CONF['uploadImage'].meta.mainid);
      // console.log('===================>'+valueHtml.value);
    })

    function SubensureEditorDialogClose(){
      proxy.$emit('ensureDialog',0);
    }

    async function NewsImageGoneDB(DataTemp){

      DeleImage.ImageArray=DataTemp;
      // console.log('OnMounted',props.mainid);

      const {data: res} = await textnewsimagegone(DeleImage);

      // console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
      }
    }

    //服务器数据删除
     function NewImageDel() {

      let ArrayMax;
      let DataTemp;
      let indexArray = [];
      let DelArray = [];

      // console.log('Totoal image',InputimageArray.value);
      // console.log('User image',PageImageArray.value);
      ArrayMax = PageImageArray.value.length;       //除了删除的图片，网页面还剩下的总图片。
      //InsetImageMax=InputimageArray.value.length; //一共插入多少张图片

      //从 InputimageArray 中查出没被删除的图片序号
      for (loop = 0; loop < ArrayMax; loop++) {
        DataTemp = InputimageArray.value.indexOf(PageImageArray.value[loop]);
        if (DataTemp != -1) {
          indexArray.push(DataTemp);
        }

      }
      // console.log('indexArray image',indexArray);
      if (indexArray.length > 0) {
        //把没被删除的图片数据从 总插入的图片张数删除，剩下的就是删除的图片数据了。
        for (loop = 0; loop < ArrayMax; loop++) {
          InputimageArray.value.splice(indexArray[loop], 1, '');
        }

        DelArray = InputimageArray.value.filter(function (value) {
          return value != '';
        })
        //进行数据库内的数据删除
        PageImageArray.value.length = 0;
        NewsImageGoneDB(DelArray);
        // console.log('Delete Array',DelArray);
      }

      // console.log(InputimageArray.value);
      // console.log('Delete Array',DelArray);
    }
    //确认提交处理函数
    function SubensureEditorDialog(){
      let ArrayMax=0,InsetImageMax;

      // console.log('------------------------>');
      CloseDialogFlag.value=true;
      // console.log(valueHtml.value.length);
      if(valueHtml.value.length<1000){
        //提交数据
        TempimageArray.value=editorRef.value.getElemsByType('image');
        ArrayMax=TempimageArray.value.length;
        InsetImageMax=InputimageArray.value.length;

        for(loop=0;loop<ArrayMax;loop++){
          PageImageArray.value.push(TempimageArray.value[loop].alt) ;  //取出文件名字
        }
        // console.log(PageImageArray.value);
        if(InsetImageMax>ArrayMax)//有图片被删除 进行数据库内删除
        {
          NewImageDel();
          // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx>');
        }
        // console.log(TempimageArray.value.length);
        // console.log('TempimageArray.value[0].alt');
        // console.log(TempimageArray.value[0].alt);
       // {type: 'image', src: 'http://www.goldenseal.cn/storage/textimages/634d303ba8779.jpg', href: 'http://www.goldenseal.cn/storage/textimages/634d303ba8779.jpg', alt: '634d303ba8779.jpg',

        proxy.$emit('ensureDialog',valueHtml.value);

      }else {
        ElMessage({
          showClose: true,
          message: '字数超过1000个，请重新修改！'+'当前字数为：'+valueHtml.value.length+'  个',
          type: 'error',
        });
      }
      //
    }
    //对话框关闭处理函数
    function NewsEditDialogClose(){
      //====================================
      if(CloseDialogFlag.value==false){

          TempimageArray.value=editorRef.value.getElemsByType('image');
          for(loop=0;loop<TempimageArray.value.length;loop++){
            PageImageArray.value.push(TempimageArray.value[loop].alt) ;  //取出文件名字
          }
          // console.log(TempimageArray.value.length);
          if(PageImageArray.value.length>0){

            //进行服务器图片删除
            // NewImageDel();
            NewsImageGoneDB(PageImageArray.value);

            // console.log('=======================================>');
          }
      }
      // const editor =
      // if (editorRef.value == null) return
      // editorRef.value.destroy()


    }
    return{
      SubensureEditorDialogClose,SubensureEditorDialog,
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      NewsEditDialogClose,
      DeleImage,
    }
  }
}
</script>

<style scoped>

</style>
