<template>
<h1>Hello world !</h1>
</template>

<script>
export default {
  name: "welcome"
}
</script>

<style scoped>

</style>
