<template>
   <h1>产品详情</h1>

   <div v-if="displaytype===0">
<!--  图片描述 -->
<!--     <h2> 图片描述</h2>-->
          <div class="productdescript">

            <div class="productdecriptleft">
              <p v-for="(dscriptitem,i) in descripsleft " :key="i">{{dscriptitem}}</p>
            </div>
            <div class="productdecriptright">
              <p v-for="(dscriptitem,i) in descripsright " :key="i">{{dscriptitem}}</p>
            </div>

          </div>
          <br>
<!--     productsimags-->
          <template v-for="(imags,i) in pictures" :key="i">
            <div class="productsimags" >
                <img :class="[{'topradius':i===0},{'bottomradius':i===(pictures.length-1)}  ]" :src="imags">

            </div>
          </template>

  </div>

   <div v-else>
<!--      <h2>PDF  描述</h2>-->
<!--  PDF 描述  -->
     <el-scrollbar  >
       <!--  v-loading  <p v-for="item in 20" :key="item" class="scrollbar-demo-item">{{ item }}</p>-->

       <div v-loading.fullscreen.lock ="loading" class="pdf-page">
         <template v-for="item in pdfata.pdfPages" :key="item">
           <canvas :id="`pdf-canvas-${item}`" class="pdf-page" />
         </template>
       </div>

     </el-scrollbar>
   </div>


  <backtop v-show="backtopflag">  </backtop>

<!--  ------------------------------------------------ -->
  <el-row :gutter="20 "  type="flex">
    <el-col :span="5" :offset="10">
      <el-pagination  background layout="  prev, pager, next,"
                      v-model:current-page.sync="queryPages.pagenumber"
                      v-model:page-size="queryPages.pagesize"
                      :page-sizes="[5,10,20,30,50]"
                      :total="TotalPags"
                      hide-on-single-page
                      @size-change="handleSizePagesChange"
                      @current-change="handleCurrentPageChange"
                      v-if="TotalPags!=0"
      />

    </el-col>
    <el-col :span="2" :offset="1">
      <el-button           @click="PrevpageKey()"  >返回产品中心</el-button>
    </el-col>
  </el-row>

<!-- ------------------------------------------------- -->
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref,reactive,nextTick} from "vue";
import imagbanner from "@/components/imgbanner/imgbanner";
import backtop from "@/components/backtop/backtop";

import {getproductimages,getproductpdf} from "@/axios/webindex";

import * as PdfJs from 'pdfjs-dist/legacy/build/pdf.js' // 注意导入的写法
import { ElLoading } from 'element-plus'


export default {
  name: "productdetails",
  components: {

    backtop,
  },
  setup(){
    let backtopflag=ref(0);
    let pictures=ref([]);
    let descripsleft=ref([]);
    let descripsright=ref([]);

    let displaytype=ref();
    let productpdfurl=ref('');
    let detailData;


    const gsproductimformation=reactive({
      id:[],
      description:[],     //电气描述
      picimgs:    [],      //图片描述
      pdfs:[],             //pdf 描述
      descriptionsel:[],      //产品描述选择
      productmodel:[],       //产品型号
      productname:[],       //产品名称
    });
    const queryPages=reactive({
      newstype:'1',
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let TotalPags=ref();
    const gsrouter=useRouter();
    const getqueryroute=useRoute();

    // 保存加载的pdf文件流
    let pdfata = reactive({
      pageNum: 0,
      pdfDoc: null,
      pdfPages:0,
      pdfScale:2.0,
    })
    let loading=ref(true);
    let loadingTask=ref(null);
    let pdfpagcount=ref();

    // num 表示渲染第几页
    function renderPage (pdf,num){
      if (pdf == null) return;

      pdf.getPage(num).then((page) => {


        // const canvas = document.getElementById('pdf-canvas'+ num) // 获取页面中的canvas元素
        const canvas = document.getElementById(`pdf-canvas-${num}`);
        // 以下canvas的使用过程
        const ctx = canvas.getContext('2d')
        const dpr = window.devicePixelRatio || 1
        const bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1
        const ratio = dpr / bsr
        const viewport = page.getViewport({ scale: pdfata.pdfScale }) // 设置pdf文件显示比例
        canvas.width = viewport.width * ratio
        canvas.height = viewport.height * ratio
        canvas.style.width = viewport.width + 'px'
        canvas.style.height = viewport.height + 'px'
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0) // 设置当pdf文件处于缩小或放大状态时，可以拖动
        const renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        // 将pdf文件的内容渲染到canvas中
        page.render(renderContext);

        if (num < pdfata.pdfPages)
        {
          renderPage(pdf,num + 1)
        }
        else
        {
          loading.value=false;
          // emit('onRendered')
          // Toast.clear(); // 取消加载loading
        }

      }).catch(function (error) {
        console.log('error：'+ error);
      });

    }

    //===================================================================================
    function  loadFile (url) {
      // 设定pdfjs的 workerSrc 参数
      // NOTE: 这一步要特别注意，网上很多关于pdfjs的使用教程里漏了这一步，会出现workerSrc未定义的错误
      PdfJs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry')

      loadingTask.value = PdfJs.getDocument(url)

      // console.log('-------------------------------------');
      loadingTask.value.promise.then((pdf) => {
        pdfata.pdfDoc = pdf; // 保存加载的pdf文件流
        pdfata.pdfPages = pdfata.pdfDoc.numPages; // 获取pdf文件的总页数

        // console.log(pdfata.pdfPages);
        // console.log(pdfata.pdfDoc);
        nextTick(() => {
          renderPage(pdf,1); // 将pdf文件内容渲染到canvas，
        })
      }).catch(function (error) {
        console.log('error：'+ error);
      });

    }



    onMounted(()=>{
      // getqueryroute.query;
      // console.log(getqueryroute.query.detail.picimgs[getqueryroute.query.page]);

      // console.log(typeof(getqueryroute.query.detail));
      // console.log(JSON.parse(decodeURIComponent(getqueryroute.query.detail)));
      // console.log(typeof(detailData));
      detailData=JSON.parse(decodeURIComponent(getqueryroute.query.detail));
      //console.log((detailData));
      gsproductimformation.description=detailData.description;
      gsproductimformation.picimgs=detailData.picimgs;
      gsproductimformation.pdfs=detailData.pdfs;
      gsproductimformation.descriptionsel=detailData.descriptionsel;
      gsproductimformation.productmodel=detailData.productmodel;
      gsproductimformation.productname=detailData.productname;
      gsproductimformation.id=detailData.id;

      queryPages.pagesize=1;
      queryPages.pagenumber=parseInt(getqueryroute.query.item)+1;
      TotalPags.value=gsproductimformation.productname.length;
      //DisplayPages.PageCount=1;     //5 每页的条数是5 条
      //DisplayPages.TotalPags=gsproductimformation.productname.length;//queryPages.pagesize;//Newtotal.value;    //10 一共是10条数量据  Newtotal.value*10;
      // DisplayPages.Currpagenumber=queryPages.pagenumber+1;//2  当前显示为第二页面

      initdisplay(queryPages.pagenumber-1);

      //==================================================================
      //监听滚动屏像素
      window.addEventListener('scroll',()=>{
        // console.log('   ---');
        // 页面滚动距顶部距离
        let scrollTop =
            // window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop
        // console.log(scrollTop)

        if(scrollTop>380){   //大于380个像素时出现返回顶部按键
          backtopflag.value=true;//backtop enable
        }else {
          backtopflag.value=false;
        }
        if(scrollTop>(1000*pdfpagcount.value)){

          if (pdfpagcount.value < pdfata.pdfPages){
            // console.log('------------>',scrollTop/1000)
            pdfpagcount.value++;
            // renderPage(pdfata.pdfDoc,pdfpagcount.value);

            // console.log(pdfata.pdfDoc);
            // console.log('===========>',pdfpagcount.value)
          }
        }

      }),true

//==================================================================
    });
    //返回产品中心
    function PrevpageKey(){
      gsrouter.push({ path:'/products'});
    }
    function initdisplay(ArrayCount){
      let ArrayTemp=ref([]);
      let loop;
      let product=reactive({
        id:'',
      });    //id
      displaytype.value=parseInt(gsproductimformation.descriptionsel[ArrayCount]);
      // gsproductimformation.description[ArrayCount];
      // descripsleft
      // descripsright
      ArrayTemp.value=gsproductimformation.description[ArrayCount].split(',');
      if(ArrayTemp.value.length>1){

      }else{
        ArrayTemp.value=gsproductimformation.description[ArrayCount].split('，')
      }
       descripsleft.value.length=0;
       descripsright.value.length=0;

      if(ArrayTemp.value.length!=0){
        descripsleft.value.push("名称："+gsproductimformation.productname[ArrayCount]);
        descripsright.value.push("型号："+gsproductimformation.productmodel[ArrayCount]);

        for(loop=0;loop<ArrayTemp.value.length;loop++){
          if((loop&1)===0){
            // console.log(loop,'------------------------');
            descripsleft.value.push(ArrayTemp.value[loop]);
          }else{
            // console.log(loop,'========================');
            descripsright.value.push(ArrayTemp.value[loop]);
          }
        }
        if((loop&1)===1)descripsright.value.push(ArrayTemp.value[loop-1]);
      }

      product.id=gsproductimformation.id[ArrayCount];
      //图片处理
      if(displaytype.value==0){
        //对图片进行下载
        pictures.value.length=0;
        getproudctspic(product);

      }else{
        //pdf 处理
        //对PDF进行下载
        productpdfurl.value='';
        getproudctspdf(product);
      }
      // console.log(gsproductimformation.description[ArrayCount].split(','));

    }
    //获取PDF数据从数据库
    function getproudctspdf(productid)
    {

      // console.log(productid);
      getproductpdf(productid).then(res=>{
        // console.log(res);
        if(res.data.code==200){
          // pictures.value=res.data.data;

          productpdfurl.value= res.data.data.join(',');

          //------------------------------------
          let strTemp=productpdfurl.value;
          strTemp=strTemp.slice(24);
          //console.log(strTemp);
          if(strTemp.length!=0){
            loading.value=true;  pdfpagcount.value=1;
            //strTemp='/gspdf'+strTemp;
            loadFile(strTemp);
          }

        }
      }).catch(err=>{
        console.log(err);
      });
    }
     //获取图片数据从数据库
    function getproudctspic(productid)
    {
      // console.log(productid);
      getproductimages(productid).then(res=>{
        // console.log(res);
        if(res.data.code==200){
          pictures.value=res.data.data;
        }
      }).catch(err=>{
        console.log(err);
      });
    }

    function handleSizePagesChange(){
      initdisplay(queryPages.pagenumber-1);
    }
    function handleCurrentPageChange(){
      initdisplay(queryPages.pagenumber-1);
    }


    return{pictures,descripsleft,descripsright,backtopflag,displaytype,
      gsproductimformation,
      queryPages,TotalPags,handleSizePagesChange,handleCurrentPageChange,PrevpageKey,
      loading,pdfata,loadingTask,
    }

  }
}
</script>

<style scoped>

.el-row {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

h1{
  user-select:none;
}
.productdescript{
    /*height: 0.12rem;*/
  display: flex;
  align-items: center;

}

 .productdescript .productdecriptright{
   /*background-color: #333333;*/
   /*flex-direction:row-reverse;*/
   /*float: left;*/
   width: 50%;
   /*height: 0.14rem;*/
   /*align-items: ;*/
   width: 50%;
 }
.productdecriptright p{
  width: 60%;
  padding: 0.04rem 0rem;
  font-size: 0.14rem;
  text-shadow:0.001rem 0.001rem 0.001rem  #0527af;
  border: 0.01rem solid #0527af;
  float: left;
  margin: 0rem auto;
}
.productdescript .productdecriptleft{
  /*background-color: #333300;*/

  width: 50%;
}
.productdecriptleft p{
  width: 60%;
  padding: 0.04rem 0rem;
  font-size: 0.14rem;
  text-shadow:0.001rem 0.001rem 0.001rem  #0527af;
  border: 0.01rem solid #0527af;

  float: right;
  /*float: right;*/
  margin: 0rem auto;
  /*margin: 0;*/

}
/*=====================================================*/
.productsimags {
  /*margin: 0;*/
  /*padding: 0;*/
  /*margin-top: 0.1rem;*/
  /*border-radius: 0.06rem;*/
}
.productsimags img{
  width: 62%;
  display: block;
  margin: 0rem auto;
  /*margin: 0;*/
  /*padding: 0;*/
  /*margin-top: 0.1rem;*/
  /*border-radius: 0.06rem;*/
}

.topradius{
  border-top-right-radius: 0.06rem;
  border-top-left-radius: 0.06rem;
}
.bottomradius{
  /*border-radius: 0.06rem;*/
  border-bottom-left-radius: 0.06rem;
  border-bottom-right-radius: 0.06rem;
}

</style>
