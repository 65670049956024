<template>
  <h1>传过来的值 是   {{$route.params.id}}</h1>
</template>

<script>
export default {
  name: "sendvalue"
}
</script>

<style scoped>

</style>
