export const newsinfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '新闻日期',
        prop : 'newsdate',
        // width:100,
    },
    {
        label: '新闻标题',
        prop : 'newstile',
        // width:160,
    },
    {
        label: '新闻副标题',
        prop : 'newssubtile',
        // width:160,
    },
    {
        label: '新闻作者',
        prop : 'newsauthor',
        // width:500,
    },
    {
        label: '新闻详情',
        prop : 'newsdetails',
        // width:130,
    },
    // {
    //     label: 'newsimage',
    //     prop : 'newsimage',
    //     width:160,
    // },
    {
        label: '新闻开关',
        prop : 'newsstatus',
        width:88,
    },
    {
        label: '新闻操作',
        prop : 'actions',
        width:200,
    },

]
