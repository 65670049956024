<template>
<h1> 打开的空白页面</h1>
</template>

<script>
export default {
  name: "webbank"
}
</script>

<style scoped>

</style>
