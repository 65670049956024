<template>
  <!-- ----------------------------------------------------- -->
<!--  <h1>PDF</h1>-->
  <div class="buttondiv">
<!--    <el-button @click="prev">上一页</el-button>-->
<!--    <el-button @click="next">下一页</el-button>-->
    <el-button @click="backtotop">回到顶部</el-button>
    <el-button @click="closedialog">关闭</el-button>
<!--    <el-backtop :top="10" :right="100" />-->
  </div>
<!-- ================================================= @scroll="scroll"  height="400px" -->
  <el-scrollbar  >
<!--  v-loading  <p v-for="item in 20" :key="item" class="scrollbar-demo-item">{{ item }}</p>-->

    <div v-loading.fullscreen.lock ="loading" class="pdf-page">
      <template v-for="item in pdfata.pdfPages" :key="item">
        <canvas :id="`pdf-canvas-${item}`" class="pdf-page" />
      </template>
    </div>

  </el-scrollbar>
<!-- ================================================  -->
<!--  <div class="pdf-page">-->
<!--    <template v-for="item in pdfata.pdfPages" :key="item">-->
<!--      <canvas :id="`pdf-canvas-${item}`" class="pdf-page" />-->
<!--    </template>-->
<!--  </div>-->

</template>

<script>
import {onMounted, ref, nextTick, reactive, onUpdated} from "vue";
import * as PdfJs from 'pdfjs-dist/legacy/build/pdf.js' // 注意导入的写法
import { ElLoading } from 'element-plus'

export default {
  name: "pdfreview",
  props:{
    pdfurl:{
      type:String,
      default:' ',
    },

  },

  // const emits = defineEmits(['shutwindow']);
  // emits: ["shutwindow"],

  setup(props, context){
    //setup中使用emits,需要先声明，定义属性数量


    // let  pdfurl = require('../../public/123.pdf');
    // 保存加载的pdf文件流
    let pdfata = reactive({
      pageNum: 0,
      pdfDoc: null,
      pdfPages:0,
      pdfScale:2.0,
    })
    let loadingTask=ref(null);

    let loading=ref(true);

    // num 表示渲染第几页
    function renderPage (pdf,num){
      if (pdf == null) return;

      pdf.getPage(num).then((page) => {


        // const canvas = document.getElementById('pdf-canvas'+ num) // 获取页面中的canvas元素
        const canvas = document.getElementById(`pdf-canvas-${num}`);
        // 以下canvas的使用过程
        const ctx = canvas.getContext('2d')
        const dpr = window.devicePixelRatio || 1
        const bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1
        const ratio = dpr / bsr
        const viewport = page.getViewport({ scale: pdfata.pdfScale }) // 设置pdf文件显示比例
        canvas.width = viewport.width * ratio
        canvas.height = viewport.height * ratio
        canvas.style.width = viewport.width + 'px'
        canvas.style.height = viewport.height + 'px'
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0) // 设置当pdf文件处于缩小或放大状态时，可以拖动
        const renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        // 将pdf文件的内容渲染到canvas中
        page.render(renderContext);

        if (num < pdfata.pdfPages) {

          renderPage(pdf,num + 1)
        } else {
          backtotop();//返回首页

          loading.value=false;
          // emit('onRendered')
          // Toast.clear(); // 取消加载loading
        }

      }).catch(function (error) {
        console.log('error：'+ error);
      });

    }

    //===================================================================================
    function  loadFile (url) {
      // 设定pdfjs的 workerSrc 参数
      // NOTE: 这一步要特别注意，网上很多关于pdfjs的使用教程里漏了这一步，会出现workerSrc未定义的错误
      PdfJs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry')

      loadingTask.value = PdfJs.getDocument(url)

      // console.log('-------------------------------------');
       loadingTask.value.promise.then((pdf) => {
        pdfata.pdfDoc = pdf; // 保存加载的pdf文件流
        pdfata.pdfPages = pdfata.pdfDoc.numPages; // 获取pdf文件的总页数

        // console.log(pdfata.pdfPages);
        nextTick(() => {

          renderPage(pdf,1); // 将pdf文件内容渲染到canvas，
        })
      }).catch(function (error) {
        console.log('error：'+ error);
      });

    }
    onMounted(()=>{

      loading.value=true;
      let strTemp=props.pdfurl.slice(24);
      //strTemp='/gspdf'+strTemp;
      loadFile(strTemp);
      // loadFile(props.pdfurl);  http://www.goldenseal.cn/
      // props.pdfurl.replace(/http:\/\/www.goldenseal.cn\//, "gspdf")
      // console.log(props.pdfurl);
      // console.log('props.pdfurl');


      //监听滚动屏像素
      // window.addEventListener('scroll',()=>{
      //   console.log('   ---');
      //   // 页面滚动距顶部距离
      //   let scrollTop =
      //       // window.pageYOffset ||
      //       document.documentElement.scrollTop ||
      //       document.body.scrollTop
      //   // console.log(scrollTop)
      //
      //   if(scrollTop>380){   //大于380个像素时出现返回顶部按键
      //     // backtopflag.value=true;//backtop enable
      //   }else {
      //     // backtopflag.value=false;
      //   }
      //
      // }),true




    });

    onUpdated(()=>{
      loading.value=true;
      let strTemp=props.pdfurl.slice(24);
      strTemp='/gspdf'+strTemp;
      loadFile(strTemp);




      // let strTemp=props.pdfurl.slice(24);
      // console.log('=======================');
      // props.pdfurl.replace(/http:\/\/www.goldenseal.cn\//, "gspdf")
      // console.log(props.pdfurl);
      // console.log(strTemp);
      // strTemp='/gspdf'+strTemp;
      // strTemp='/api'+strTemp;
      // console.log(strTemp);
      // loadFile(strTemp);
    })
   function backtotop(){
     // document.documentElement.scrollTop=0;
     // this.$el.querySelector('#pdf-canvas-1').scrollIntoView();
     document.getElementById('pdf-canvas-1').scrollIntoView();

     // console.log('--------------------');

   }
   function closedialog(){

     // console.log('---==========-----');
     context.emit("shutwindow",false);

   };




    return {
      pdfata,renderPage,
      backtotop,closedialog,
      loading,
    }
  }
}
</script>

<style scoped>
.buttondiv {
  top: 10px;
  right: 10px;
  position: fixed;
  z-index: 999;
}

.pdf-page1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}


</style>













