export const webdatainfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '文件备份日期',
        prop : 'backupdate',
        // width:180,
    },
    {
        label: '数据库备份名称',
        prop : 'backupname',
        // width:200,
    },
    {
        label: '数据库备份大小（Byte）',
        prop : 'backupsize',
        // width:180,
    },
    {
        label: '图片备份名称',
        prop : 'backupzipname',
        // width:200,
    },
    {
        label: '图片备份大小（Byte）',
        prop : 'backupzipsize',
        // width:180,
    },
    {
        label: '操作',
        prop : 'actions',
        // width:130,
    },

]
