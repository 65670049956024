<template>
  <rightmainhead  :fromFather =userinfopath> </rightmainhead>
<!--  <h1>用户信息</h1>-->
  <el-row :gutter="20">

    <el-col  :span="10">
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 邮箱 或 电话号码 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>

    <el-col :span="2" :offset="12">
      <div>
        <el-button type="primary" @click="addUserKey">添加信息</el-button>
      </div>
    </el-col>

  </el-row>
  <!--  消息表格  开始  -->
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格             @cell-click="MessagestableDetailEdit" -->
    <el-table :data="UserdatatableData" border stripe style="width: 100%" >

      <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in usersinfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

<!--          <el-button size="small"     type="primary"     @click="downloadKey(row)"  >下载</el-button>-->
          <el-button size="small"     type="warning"     @click="editUserKey(row)"  >编辑</el-button>
          <el-button size="small"     type="danger"      @click="delUserKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>


        <!--    v-html="row.messagedetail"  {{row.messagedetail}}  -->
<!--        <template v-slot="{row}" v-if="item.prop==='backupsize'">-->
<!--          <div   class="divtext"  >-->
<!--            &lt;!&ndash;            {{HtmldisplayText(row.messagedetail)}}&ndash;&gt;-->
<!--            {{ThousandDisplay(row.backupsize)}}-->

<!--          </div>-->
<!--        </template>-->
        <!--   状态开关       -->
                <template v-slot="{row}" v-if="item.prop==='userstatus'">
                  <el-switch
                      v-model="row.userstatus"
                      active-value="1"
                      inactive-value="0"
                      @change="UserEnableswitchStateKey(row)"></el-switch>
                </template>


      </el-table-column>

    </el-table>

    <!--   分页器  ------------------------------------->
    <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
                 handleCurrentChange 页码值发生改变
                 currentPage  当前显示的第页面数
                 page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="userdatatotal"
                       @size-change="UserdatahandleSizePagesChange"
                       @current-change="UserdatahandleCurrentPageChange"
        />
      </el-col>
    </el-row>
  </el-card>
  <!--  消息表格  结束   -->
  <!--   添加用户对话框 开始  -->
  <el-dialog
      v-model="addUserdialogVisible"
      :title="DialogTitle"
      width="40%"
      @close="handleClose"
  >
    <!--   内容主体区      -->
    <el-form
        ref="addUserFormRef"
        :model="addUserForm"
        :rules="addUserFormRules"
        label-width="20%"
        :size="formSize"
        status-icon
    >
      <template v-if="DialogTitle!=='编辑用户信息'">
<!--        <el-form-item label="用户名"  prop="contact">-->
<!--          <el-input v-model="addUserForm.username" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="用户密码"  prop="password">-->
<!--          <el-input v-model="addUserForm.password" />-->
<!--        </el-form-item>-->
        <el-form-item label="电子邮箱"  prop="useremail">
          <el-input v-model="addUserForm.useremail" />
        </el-form-item>
        <el-form-item label="手机电话"   prop="userphone" >
          <el-input v-model="addUserForm.userphone" />
        </el-form-item>

      </template>
      <template v-if="DialogTitle=='编辑用户信息'">
        <el-form-item label="用户名"  prop="username">
          <el-input v-model="addUserForm.username" />
        </el-form-item>
        <el-form-item label="用户密码"  prop="password">
          <el-input v-model="addUserForm.password" />
        </el-form-item>
        <el-form-item label="电子邮箱"  prop="useremail">
          <el-input v-model="addUserForm.useremail" />
        </el-form-item>
        <el-form-item label="手机电话"   prop="userphone" >
          <el-input v-model="addUserForm.userphone" />
        </el-form-item>

      </template>

      <!--        <el-form-item label="创建时间" prop="create_time">-->
      <!--          <el-input v-model="addUserForm.create_time" />-->
      <!--        </el-form-item>-->
    </el-form>
    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetAddUserFormData">清除</el-button>-->
          <el-button @click="addUserdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addUserCheck">提交</el-button
          >
        </span>
    </template>
  </el-dialog>

  <!--   添加用户对话框   结束   -->

  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDeluserDialogVisible"
             context="确认将数据库恢复 ？ ^L^"
             title="恢复数据库数据"
             @ensureDialog="ensureDeluserDialog"
  ></tipdialog>


</template>

<script>
import Rightmainhead from "@/components/rightareahead/rightareahead";
import Tipdialog from "@/components/rightareahead/tipdialog";

import {usersinfotableoptions} from '@/views/webmanage/rightarea/userstable'

import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue';

import {usersdblist,Addusersdb,Editusersdb,Delusersdb,Updateusersdb} from "@/axios/webindex";
import {onMounted, ref,reactive} from "vue";
import { ElMessage } from 'element-plus';

export default {
  name: "userinfo",
  components: {Rightmainhead,Tipdialog},

  setup() {
    let userinfopath = [
      {  name: '首页',     path: 'AdminHome'     },
      {  name: '用户管理',  path: 'userinfo'    },
      {  name: '用户信息',  path: 'userinfo'    }
    ];
    let queryPages=reactive({
      //============================   修改 类别
      currentusername:'',
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let UserdatatableData=ref([]);
    let userdatatotal=ref();
    let userinfor=reactive({
      currentusername:'',
      userid:'',
      username:'',
      password:'',
      codeword:'',
    });
     let ensureDeluserDialogVisible=ref(false);

     let addUserdialogVisible=ref(false);
     let DialogTitle=ref();
     let addUserForm=reactive({
       mainid:'',
       userid:'',
       username:'',
       password:'',
       useremail:'',
       userphone:'',
       userstatus:'',
     });
     let addUserFormRef=ref();
     let addUserFormRules=reactive({
      username:[
        { required: true, message: 'Please input username', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
       password:[
         { required: true, message: 'Please input password', trigger: 'blur' },
         { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
       ],
       useremail:[
        { required: true, message: 'Please input email ', trigger: 'blur' },
        { min: 3, max: 20, message: 'Length should be 3 to 20', trigger: 'blur' },
        { validator:checkEmial, trigger: 'blur'  }
      ],
      userphone:[
        { required: true, message: 'Please input mobile number', trigger: 'blur' },
        { min: 11, max: 20, message: 'Length should be 11 to 20', trigger: 'blur' },
        { validator:checkMobile, trigger: 'blur'  }
      ],
    });

    //  自定义规则  校验邮箱
    function checkEmial (rule,value,callback) {
      //邮箱验证的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {  //合法的邮箱
        return callback();
      } else {
        callback(new Error('请输入合法的邮箱'));
      }
    }
    //  自定义规则  校验手机号
    function checkMobile (rule,value,callback){
      //手机验证的正则表达式
      const regMobile= /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(value)){  //合法的邮箱
        return callback();
      }else {
        callback(new Error('请输入合法的手机号码！'));
      }
    }

    onMounted(()=>{
       userinfor.currentusername=window.sessionStorage.getItem('username');
      queryPages.currentusername=userinfor.currentusername;
      inituserdata();
    })
    async function inituserdata(){

      let res = await usersdblist(queryPages)
      {
        // console.log(res);
        //console.log(res.data.data.account);

        if (res.data.code == 200) {

          userdatatotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;

          addUserForm.mainid=res.data.data.account.id;
          // console.log('=============================',addUserForm.mainid);
          // console.log(ProductstableData.value);
          // console.log('=============================');
          UserdatatableData.value.length=0;

          // console.log('=============================',res.data.data.data.data.length);
          if(res.data.data.data.data.length==0){
            UserdatatableData.value.push(res.data.data.account);
          }else {
            for (let i in res.data.data.data.data)
            {
              UserdatatableData.value.push(Object.assign(res.data.data.data.data[i],res.data.data.account));
            }
          }


      //    let type = Object.prototype.toString.call(res.data.data.data.data);

      //    if (type === "[object Array]");
        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取备份信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }
    }
    //添加用户
    function addUserKey(){
      DialogTitle.value='添加用户信息';
      addUserdialogVisible.value=true;
      addUserForm.userid='';
      addUserForm.username='';
      addUserForm.password='';
      addUserForm.useremail='';
      addUserForm.userphone='';
    }
    //编辑用户
    function editUserKey(RowTemp){
      DialogTitle.value='编辑用户信息';
      addUserdialogVisible.value=true;
      addUserForm.userid=RowTemp.uid;;
      addUserForm.username=RowTemp.username;
      addUserForm.password=RowTemp.password;
      addUserForm.useremail=RowTemp.useremail;
      addUserForm.userphone=RowTemp.userphone;
    }
    //关闭对话框回调函数
    function handleClose(){
      addUserFormRef.value.resetFields();
    }
    //提交编辑和用户信息
    function addUserCheck(){

      addUserFormRef.value.validate( valid => {
        // console.log(valid);
        if (!valid) return;  //数据不对返回
        //  添加用户的表单请求
        if(DialogTitle.value=='编辑用户信息'){
          userinfor.currentusername=addUserForm.username;
          // console.log('00000000000000000>',addUserForm.username);
          EditUserInfo(addUserForm);
          // console.log('----------------->',addUserForm.username);
        }
        if(DialogTitle.value=='添加用户信息') {
          AddUserInfo(addUserForm);
        }
        addUserdialogVisible.value = false
      })
    }

    //添加用户信息上传数据到服务器
    async function AddUserInfo(DataTemp){
      const {data: res} = await Addusersdb(DataTemp);

      if (res.code!=200) {
        ElMessage({
          showClose: true,
          message: '添加用户信息数据失败 : '+res.msg,
          type: 'error',
        });
      }else {
        ElMessage({
          showClose: true,
          message: '添加用户信息数据成功 : '+res.msg,
          type: 'success',
        });


        inituserdata();
      }
    }
    //编辑用户信息上传数据到服务器
    async function EditUserInfo(DataTemp){
      const {data: res} = await Editusersdb(DataTemp);
      if (res.code!=200) {
        ElMessage({
          showClose: true,
          message: '编辑用户信息数据失败 : '+res.msg,
          type: 'error',
        });
      }else {
        ElMessage({
          showClose: true,
          message: '编辑用户信息数据成功 : '+res.msg,
          type: 'success',
        });
        // console.log(addUserForm.username)

        if(queryPages.currentusername!=userinfor.currentusername)  //更改用户名后进行密码和用户清除
        {
          queryPages.currentusername=userinfor.currentusername;
          window.sessionStorage.setItem('username',queryPages.currentusername);//登录用户名进行传值
          //清除保存记录
          window.localStorage.removeItem('gsloginremeber');
          window.localStorage.removeItem('gsloginusername');
          window.localStorage.removeItem('gsloginuserpassword');
        }

        inituserdata();
      }
    }
    //删除用户信息
    async function ensureDeluserDialog(DataTemp){

        if(DataTemp==1){
          //进行数据删除处理  userinfor.userid
          const {data: res} = await Delusersdb(addUserForm);
          if (res.code!=200) {
            ElMessage({
              showClose: true,
              message: '添加用户信息数据失败 : '+res.msg,
              type: 'error',
            });
          }else {
            ElMessage({
              showClose: true,
              message: '添加用户信息数据成功 : '+res.msg,
              type: 'success',
            });
            inituserdata();
          }
        }
      ensureDeluserDialogVisible.value=false;
    }
    //删除用户信息
    function delUserKey(RowTemp){
      addUserForm.userid=RowTemp.uid;
      // console.log(RowTemp.uid);
      ensureDeluserDialogVisible.value=true;
    }
    //搜索关闭按键
    function serchClearKey(){
      queryPages.serch='';
      inituserdata();
    }
    //搜索按键
    function serchKey(){
      inituserdata();
    }
    //使能状态开关
    async  function UserEnableswitchStateKey(RowTemp){
     addUserForm.userid=RowTemp.uid;
     addUserForm.userstatus=RowTemp.userstatus;
     // console.log(RowTemp.userstatus);
     const {data: res} = await Updateusersdb(addUserForm);
     if (res.code!=200) {
       ElMessage({
         showClose: true,
         message: '添加用户信息数据失败 : '+res.msg,
         type: 'error',
       });
     }else {
       ElMessage({
         showClose: true,
         message: '添加用户信息数据成功 : '+res.msg,
         type: 'success',
       });
       inituserdata();
     }


   }
    function UserdatahandleSizePagesChange(){
      inituserdata();
    }
    function UserdatahandleCurrentPageChange(){
      inituserdata();
    }


    return {
      userinfopath,
      addUserKey,queryPages,Search,
      usersinfotableoptions,UserdatatableData,
      userdatatotal,UserdatahandleSizePagesChange,UserdatahandleCurrentPageChange,
      UserEnableswitchStateKey,
      editUserKey,delUserKey,serchKey,serchClearKey,handleClose,addUserCheck,
      ensureDeluserDialogVisible,ensureDeluserDialog,
      addUserdialogVisible,DialogTitle,addUserForm,
      addUserFormRules,addUserFormRef,
    };
  }
}
</script>

<style scoped>
.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}
/*  上传完图片后出现预览和删除图标 */
/*/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {*/
/*  margin-left: 0.4rem !important;*/
/*}*/

.box-card{
  margin-top: 6px;
}
.imagebox-card{
  margin-top: 6px;
}
.pdfbox-card{
  margin-top: 6px;
}
.el-upload__tip{
  margin-bottom: 6px;
}
.el-input{
  margin: 8px;
}
</style>
