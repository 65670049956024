<template>
  <rightmainhead  :fromFather =webdatainfopath> </rightmainhead>
<!--  <h1>数据库信息</h1>-->
  <el-row :gutter="4">

    <el-col  :span="8">
      <el-input
          v-model.trim="queryPages.serch"
          placeholder="请输入 文件名称 日期 查询 "
          class="input-with-select"
          clearable
          @clear="serchClearKey()"
          @keyup.enter="serchKey()"
      >
        <template #append>
          <el-button :icon="Search" @click="serchKey()" />
        </template>
      </el-input>
    </el-col>
    <el-col :span="4" :offset="8">
      <div>
        <el-button type="primary" @click="uploadBackupKey">上传数据库 文件</el-button>
      </div>
    </el-col>
    <el-col :span="2" :offset="1">
      <div>
        <el-button type="primary" @click="addBackupKey">添加备份</el-button>
      </div>
    </el-col>

  </el-row>
  <!--  消息表格  开始  -->
  <el-card class="box-card">
    <!--    <h1>公司信息</h1>-->
    <!--   表格             @cell-click="MessagestableDetailEdit" -->
    <el-table :data="WebdatatableData" border stripe style="width: 100%" @cell-click="uploadZipfileClickTable" >

      <!--      第一列    -->
      <el-table-column align="center" type="index"></el-table-column>
      <!--      第二列   prop为值 -->
      <el-table-column align="center" :prop="item.prop" :label="item.label"  :width="item.width" show-overflow-tooltip  v-for="(item,index) in webdatainfotableoptions" :key="index">

        <template  v-slot="{ row }"  v-if="item.prop==='actions'">

          <el-button size="small"     type="primary"     @click="downloadKey(row)"  >下载</el-button>
          <el-button size="small"     type="warning"     @click="restoreKey(row)"  >还原</el-button>
          <el-button size="small"     type="danger"      @click="delbackupKey(row)"   >删除</el-button>
          <!--          <el-button size="small" :icon="Setting" type="warning"  @click="setKey(row)" >Set</el-button>-->

        </template>


        <!--    v-html="row.messagedetail"  {{row.messagedetail}}  -->
        <template v-slot="{row}" v-if="item.prop==='backupsize'">
          <div   class="divtext"  >
<!--            {{HtmldisplayText(row.messagedetail)}}-->
            {{ThousandDisplay(row.backupsize)}}
          </div>
        </template>

        <template v-slot="{row}" v-if="item.prop==='backupzipsize'">
          <div   class="divtext"  >
<!--                        {{(row.backupzipsize)}}-->
<!--            {{ThousandDisplay(row.backupzipsize)}}-->
            <template v-if="row.backupzipsize==null">
              还未上传文件
            </template>
            <template v-else>
              {{ThousandDisplay(row.backupzipsize)}}
            </template>
          </div>
        </template>

        <template v-slot="{row}" v-if="item.prop==='backupzipname'">
          <div   class="divtext"  >
            <template v-if="row.backupzipname==null">
                点击上传图片压缩文件
            </template>
            <template v-else>
               {{row.backupzipname}}
            </template>
          </div>
        </template>
        <!--   状态开关       -->
<!--        <template v-slot="{row}" v-if="item.prop==='messagestatus'">-->
<!--          <el-switch-->
<!--              v-model="row.messagestatus"-->
<!--              active-value="1"-->
<!--              inactive-value="0"-->
<!--              @change="MessageEnableswitchStateKey(row)"></el-switch>-->
<!--        </template>-->


      </el-table-column>

    </el-table>

    <!--   分页器  ------------------------------------->
    <!--  sizes, handleSizeChange 每页显示多少条数据触发函数 。
                 handleCurrentChange 页码值发生改变
                 currentPage  当前显示的第页面数
                 page-size   当前一个页面显示多条数据  -->
    <el-row :gutter="20">
      <el-col :span="5" :offset="19">
        <el-pagination background layout="total,  prev, pager, next,"
                       v-model:currentPage="queryPages.pagenumber"
                       :page-size="queryPages.pagesize"
                       :page-sizes="[5, 10, 20, 30]"
                       :total="Webdatatotal"
                       @size-change="WebdatahandleSizePagesChange"
                       @current-change="WebdatahandleCurrentPageChange"
        />
      </el-col>
    </el-row>
  </el-card>
  <!--  消息表格  结束   -->
  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureDelDialogVisible"
             context="确认将数据库删除 ？ ^L^"
             title="删除数据库数据"
             @ensureDialog="ensureDelDialog"
  ></tipdialog>
  <!--  提示对话框组件  -->
  <tipdialog v-model="ensureRestoreDialogVisible"
             context="确认将数据库恢复 ？ ^L^"
             title="恢复数据库数据"
             @ensureDialog="ensureRestoreDialog"
  ></tipdialog>

<!--  -->
  <!--     上传数据备份文件 对话框对话框 开始 @close="addImageDialogCloseFun" -->
  <el-dialog
      v-model="LoadfileDialogVisible"
      title="上传数据备份文件"
      width="50%"
      destroy-on-close="true"
  >
      <!--  :on-remove="BackupfileUploadRemove" :file-list="BackupuploadFileList" :data="BackupfileUploadDate"  :on-preview="homebannerUploadPreview"================================================================= class="hide"  -->
      <el-row>
        <el-col :span="24"  >
          <el-upload
              :class="{hide:hideBackUpload }"
              ref="backupfileuploadref"
              :action="BackupfileuploadURL"
              :headers="gswebsittoken"
              :before-upload="beforVerifyBackupfileUpload"
              :on-success="SuccessBackupfileUpload"

              :on-error ='uploadfileerrorsql'
              :on-change="uploadSqlOnChange"

              name="sql"

              list-type="picture-card"
          >
            <el-button type="primary"  >点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                {{Backupfilename}}
              </div>
              <div class="el-upload__tip">
                sql 文件大小低于 2Mb
              </div>

            </template>
          </el-upload>
        </el-col>
      </el-row>
      <!--  =========================================================   -->

    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetBannerFormData">清除</el-button>-->

<!--          <el-button type="primary" @click="addImageDialogSave">保存数据</el-button>-->
          <el-button @click="LoadfileDialogVisible = false">关闭对话框</el-button>
        </span>
    </template>
  </el-dialog>
    <!--  =========================================================   -->

  <!--     上传图片压缩备份文件 对话框对话框 开始 @close="addImageDialogCloseFun" -->
  <el-dialog
      v-model="LoadZipfileDialogVisible"
      title="上传图片备份文件"
      width="50%"
      destroy-on-close="true"
  >
    <!--  :on-remove="BackupfileUploadRemove" :file-list="BackupuploadFileList" :data="BackupfileUploadDate"  :on-preview="homebannerUploadPreview"================================================================= class="hide"  -->
    <el-row>
      <el-col :span="24"  >
        <el-upload
            :class="{hide:hideZipBackUpload }"
            ref="backupZipfileuploadref"
            :action="BackupzipfileuploadURL"
            :headers="gswebsittoken"
            :before-upload="beforVerifyBackupZipfileUpload"
            :on-success="SuccessBackupZipfileUpload"
            :on-change="uploadZipSqlOnChange"
            :data="zipuploaddata"
            name="zip"

            list-type="picture-card"
        >
          <el-button type="primary"  >点击上传</el-button>
          <template #tip>
            <div class="el-upload__tip">
              {{Backupfilename}}
            </div>
            <div class="el-upload__tip">
              zip 文件大小 低于 200Mb
            </div>

          </template>
        </el-upload>
      </el-col>
    </el-row>
    <!--  =========================================================   -->

    <!--   对话框底部区   -->
    <template #footer>
        <span class="dialog-footer">
<!--          <el-button type="warning" @click="ResetBannerFormData">清除</el-button>-->

          <!--          <el-button type="primary" @click="addImageDialogSave">保存数据</el-button>-->
          <el-button @click="LoadZipfileDialogVisible = false">关闭对话框</el-button>
        </span>
    </template>
  </el-dialog>
  <!--  =========================================================   -->

</template>



<script>

import Rightmainhead from "@/components/rightareahead/rightareahead";
import Tipdialog from "@/components/rightareahead/tipdialog";

import {webdatainfotableoptions} from '@/views/webmanage/rightarea/webdatatable'

import { Search,Edit,Delete,Setting } from '@element-plus/icons-vue';

import { backupdblist,backupdboperate } from "@/axios/webindex";
import {onMounted, ref,reactive} from "vue";
import { ElMessage } from 'element-plus';

import {gsaddbackupfilewebaddress,gsaddbackupzipfilewebaddress} from '@/views/webmanage/websiteglobalvar'

export default {
  name: "webdatainfo",
  components: {Rightmainhead,Tipdialog},

  setup() {
    let webdatainfopath = [
      {  name: '首页',     path: 'AdminHome'     },
      {  name: '数据管理',  path: 'webdatainfo'    },
      {  name: '数据库信息',  path: 'webdatainfo'    }
    ];

    let queryPages=reactive({
      //============================   修改 类别
      serch:'',
      pagenumber:'',  //1
      pagesize:'',    //5
    });
    let WebdatatableData=ref();
    let Webdatatotal=ref(0);

    let ensureDelDialogVisible=ref(false);
    let ensureRestoreDialogVisible=ref(false);

    let backupoperate=reactive({
      type:'',
      name:'gs_webback',
      id:'',
    });
    let  BackupfileuploadURL=ref('gsaddbackupfilewebaddress');
    let gswebsittoken= { Authorization:  window.localStorage.getItem('gstoken'),};
    let LoadfileDialogVisible=ref(false);
    let hideBackUpload=ref(false);
    let backupfileuploadref=ref();
    let Backupfilename=ref();

    let LoadZipfileDialogVisible=ref(false);
    let hideZipBackUpload=ref(false);
    let backupZipfileuploadref=ref();
    let  BackupzipfileuploadURL=ref('gsaddbackupzipfilewebaddress');
    //上传压缩图片 附带的参数
    let zipuploaddata=reactive({
      id: '',
    });
    // let BackupfileUploadDate=reactive({
    //   id:'',
    //   imagename:'',
    //   hometourl:'',
    // });
    // let BackupuploadFileList=ref();

    onMounted(()=>{
      BackupfileuploadURL.value=gsaddbackupfilewebaddress;
      BackupzipfileuploadURL.value=gsaddbackupzipfilewebaddress;
      initBackupData();
    })

    async  function initBackupData(){

      let res = await backupdblist(queryPages)
      {
        // console.log(res);

        if (res.data.code == 200) {

          Webdatatotal.value=res.data.data.total;
          queryPages.pagenumber=res.data.data.current_page;
          queryPages.pagesize=res.data.data.per_page;
          // console.log(ProductstableData.value);
          // console.log('=============================');

          let type = Object.prototype.toString.call(res.data.data.data);

          if (type === "[object Array]"){
              // console.log('是一个数组');
            WebdatatableData.value=res.data.data.data;
            // console.log(WebdatatableData.value);
          }
          if (type === "[object Object]"){
            // console.log('是一个对象');
            let arr = [];   //对象转成数组
            for (let i in res.data.data.data) {
              arr.push(res.data.data.data[i]);
              // console.log(res.data.data.data[i]);
              // ProductstableData.value
            }
            WebdatatableData.value=arr;
          }

        } else {    //数据不成功
          ElMessage({
            showClose: true,
            message: '获取备份信息数据失败：' + res.data.msg,
            type: 'error',
          });

        }
      }

    }
     //添加下载
    async  function downloadKey(RowTemp){
      backupoperate.type='dowonload';
      backupoperate.name=RowTemp.backupname;
      // backupoperate.backupurl=RowTemp.backupurl;

      const {data: res} = await backupdboperate(backupoperate);
      // console.log(res);

      if (res.code != 200) {

        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        //===================================
        // console.log(res.data.length,'-----------------',);
        for(let i=0;i<res.data.length;i++){
          const iframe = document.createElement("iframe");
          iframe.style.display = "none"; // 防止影响页面
          iframe.style.height = 0; // 防止影响页面
          iframe.src = res.data[i];
          // console.log(i,'-----------------');
          document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
          // console.log(i,'==================');
          // 5分钟之后删除
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
        }
        // window.location.href = res.data[1]+'';
        // window.open(res.data[1], '_self');
        //==========================================================

        // const link = document.createElement("a"); //自己创建的a标签
        // link.href =res.data[0];// res.data;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(res.data[0]);//(res.data);

        // window.location.href =backupoperate.backupurl;

        ElMessage({
          showClose: true,
          message: '开始下载数据 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出
        initBackupData();
      }
    };
    //数据删除 确认回调函数
    async  function ensureDelDialog(DataTemp){
       //进行数据删除
       if(DataTemp==1){
         backupoperate.type='del';

         const {data: res} = await backupdboperate(backupoperate);

         // console.log(res);

         if (res.code != 200) {
           ElMessage({
             showClose: true,
             message: '更改数据失败 : ' + res.msg,
             type: 'error',
           });
         } else {
           ElMessage({
             showClose: true,
             message: '更改数据成功 : ' + res.msg,
             type: 'success',
           });
           //从服务中取出
           initBackupData();
         }
        }
      ensureDelDialogVisible.value=false;
    }
    //数据删除
    function delbackupKey(RowTemp){
      ensureDelDialogVisible.value=true;
      backupoperate.id=RowTemp.id;
      backupoperate.name=RowTemp.backupname;
        // console.log('wwwwwwww');
    };
    //数据恢复确认函数回调
    async   function ensureRestoreDialog(DataTemp){

      if(DataTemp==1){
        backupoperate.type='restore';

        const {data: res} = await backupdboperate(backupoperate);

        // console.log(res);

        if (res.code != 200) {
          ElMessage({
            showClose: true,
            message: '恢复数据失败 : ' + res.msg,
            type: 'error',
          });
        } else {
          ElMessage({
            showClose: true,
            message: '恢复数据成功 : ' + res.msg,
            type: 'success',
          });
          //从服务中取出
          initBackupData();
        }
      }
      ensureRestoreDialogVisible.value=false;
    }
    //数据恢复
    function restoreKey(RowTemp) {
      ensureRestoreDialogVisible.value=true;
      backupoperate.id=RowTemp.id;
      backupoperate.name=RowTemp.backupname;
    };
    //添加备份
    async function addBackupKey(){
      backupoperate.type='backup';

      const {data: res} = await backupdboperate(backupoperate);

      // console.log(res);

      if (res.code != 200) {
        ElMessage({
          showClose: true,
          message: '更改数据失败 : ' + res.msg,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '更改数据成功 : ' + res.msg,
          type: 'success',
        });
        //从服务中取出
        initBackupData();
      }
    }
    //数字加逗号分割
    function ThousandDisplay(num){
      // return DataTemp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // console.log(num);

      if(num==null)return '';

      let start = (num + '').split('.')[0].split('').reverse()
      let end = (num + '').split('.')[1]
      let s = '',cur = '',n = start.length
      for(let i=0;i<n;i++){
        let res = start.shift()
        cur += res
        if(cur.length === 3){
          s+= cur
          if(start.length !== 0){
            s+=','
          }
          cur = ''
        }
      }
      s+=cur
      start = s.split('').reverse().join('')

      // console.log(start+'.'+end);
      return start;


    }
    function WebdatahandleSizePagesChange(){
      // console.log('--------------------------->');
      initBackupData();
    }
    function WebdatahandleCurrentPageChange(){
      // console.log('--====================------------->');
      initBackupData();
    }
    //上传图片备份文件
    function uploadZipfileClickTable(row,column,cell,event){
      //产品描述对话框
      if(column.property=='backupzipname'){

        // addProductDialog.id=row.id;
        if(row.backupzipname===null){
          // console.log('===============================>',row.id);
          LoadZipfileDialogVisible.value=true;
          zipuploaddata.id=row.id;
        }else{
          // console.log('---------------------------->',row.backupzipname);
        }
        // addProductdetailsForm.value= row.productdetails.split(',');


        //console.log('===============================>');

      }
    }
    //上传压缩图片备份文件之前调用
    function beforVerifyBackupZipfileUpload(file){
      let filetypetemp;
      // console.log(file.type);
      //
      // console.log(file.name,'----------------------------->', BackupfileuploadURL.value  );
      filetypetemp=file.name.split('.').pop().toLowerCase();
      // console.log('----------dddddddddd---------',filetypetemp);
      //判断数据库备份文件格式
      if(filetypetemp == 'zip'  ) {
        // console.log(_imgType);

      }else{
        ElMessage({
          showClose: true,
          message: '上传zip文件格式不对 ！',
          type: 'error',
        });
        // console.log('------------------------',file.type);
        return false;
      }


      if((file.size/1024/1024)>200){
        ElMessage({
          showClose: true,
          message: '上传文件大于200M ！',
          type: 'error',
        });
        return false;
      }
    }

    //上传 ZIP 文件 改变回调函数
    function uploadZipSqlOnChange(file,fileList){
      // if(file!=null){
      //
      // }
      file.url=require('@/assets/zipjpg.jpg');
      file.uid='';
      Backupfilename.value='您上传的文件名： '+file.name;

      initBackupData();
    }
    function SuccessBackupZipfileUpload(response,file,fileList){
      // console.log('------------------------------》2');
      // if(response.code==200){
      //   BackupuploadFileList.value=require('@/assets/zipjpg.jpg');
      //   // uploadpdfForm.pdfFileList.push(response.data.data);
      //   Backupfilename.value='您上传的文件名： '+file.name;
      //   ElMessage({
      //     showClose: true,
      //     message: '成功上传文件：'+response.msg+"  "+uploadpdfForm.pdfFileList,
      //     type: 'success',
      //   });
      // }
    }
    //上传数据库备份文件
    function uploadBackupKey(){
      LoadfileDialogVisible.value=true;
    }
    //上传数据库备份文件之前调用
    function beforVerifyBackupfileUpload(file){
       let filetypetemp;
      // console.log(file.type);
      //
      // console.log(file.name,'----------------------------->', BackupfileuploadURL.value  );
      filetypetemp=file.name.split('.').pop().toLowerCase();
      // console.log('----------dddddddddd---------',filetypetemp);
     //判断数据库备份文件格式
      if(filetypetemp == 'sql'  ) {
        // console.log(_imgType);

      }else{
        ElMessage({
          showClose: true,
          message: '上传sql文件格式不对 ！',
          type: 'error',
        });
        // console.log('------------------------',file.type);
        return false;
      }


      if((file.size/1024/1024)>2){
        ElMessage({
          showClose: true,
          message: '上传图片大于2M ！',
          type: 'error',
        });
        return false;
      }
    }
    //上传 SQL 文件 改变回调函数
    function uploadSqlOnChange(file,fileList){
      // if(file!=null){
      //
      // }
      file.url=require('@/assets/sqljpg.jpg');
      file.uid='';
      Backupfilename.value='您上传的文件名： '+file.name;

      initBackupData();
    }
    //上传数据库备份文件成功后回调
    function SuccessBackupfileUpload(response,file,fileList){
      // console.log('------------------------------》2');
      // if(response.code==200){
      //   BackupuploadFileList.value=require('@/assets/sqljpg.jpg');
      //   // uploadpdfForm.pdfFileList.push(response.data.data);
      //   Backupfilename.value='您上传的文件名： '+file.name;
      //   ElMessage({
      //     showClose: true,
      //     message: '成功上传文件：'+response.msg+"  "+uploadpdfForm.pdfFileList,
      //     type: 'success',
      //   });
      // }
    }
    function uploadfileerrorsql(){
      // console.log('------------------------------》3');
    }

    return {
      webdatainfopath,
      webdatainfotableoptions,Search,queryPages,downloadKey,restoreKey,delbackupKey,addBackupKey,
      Webdatatotal,WebdatatableData,
      ensureDelDialogVisible,ensureDelDialog,
      WebdatahandleSizePagesChange,WebdatahandleCurrentPageChange,
      ensureRestoreDialogVisible,ensureRestoreDialog,
      ThousandDisplay,
      uploadBackupKey,LoadfileDialogVisible,
      hideBackUpload,beforVerifyBackupfileUpload,SuccessBackupfileUpload,BackupfileuploadURL,
      backupfileuploadref,Backupfilename,gswebsittoken,uploadSqlOnChange,uploadfileerrorsql,
      uploadZipfileClickTable,
      hideZipBackUpload,LoadZipfileDialogVisible,backupZipfileuploadref,beforVerifyBackupZipfileUpload,SuccessBackupZipfileUpload,uploadZipSqlOnChange,
      BackupzipfileuploadURL,zipuploaddata,
    };
  }
}
</script>

<style scoped>
.el-card{
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)!important; ;
}
.el-table{
  margin-top: 1px;
  margin-bottom: 18px;
}
/*  上传完图片后出现预览和删除图标 */
/*/deep/.el-upload-list--picture-card .el-upload-list__item-actions span+span {*/
/*  margin-left: 0.4rem !important;*/
/*}*/

.box-card{
  margin-top: 6px;
}
.imagebox-card{
  margin-top: 6px;
}
.pdfbox-card{
  margin-top: 6px;
}
.el-upload__tip{
  margin-bottom: 6px;
}
.el-input{
  margin: 8px;
}

.imgpreview{
  width: 100%;
  height: 100%;
}

.hide >>> .el-upload--picture-card {
  display: none;
}
</style>
