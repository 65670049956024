export const messagesinfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '消息作者',
        prop : 'messageauthor',
        width:108,
    },
    {
        label: '电子邮箱',
        prop : 'email',
        width:180,
    },
    {
        label: '电话号码',
        prop : 'tel',
        width:180,
    },
    {
        label: '手机号码',
        prop : 'cellphone',
        // width:130,
    },
    {
        label: '消息时间',
        prop : 'messagetime',
        // width:130,
    },
    {
        label: '消息详情',
        prop : 'messagedetail',
        // width:130,
    },
    {
        label: '消息状态',
        prop : 'messagestatus',
        width:88,
    },
    {
        label: '操作',
        prop : 'actions',
        // width:130,
    },

]
