<template>
  <div class="home" id="home-box"  >

        <swipercar>   </swipercar>

    <div  >
    <template v-for="(subtitle,count) in gsadpicimformation.producttypetitle " :key="count">
<!--      <div>{{subtitle}}</div>-->
<!--      {{count}}-->
        <verticalbanner  :propsadbinnertitle="subtitle" :propsadbinnerpics="gsadpicimformation.picimgs"
                         :propsadbinnerpicurl="gsadpicimformation.picimgurl"  :keycount="count" @click="homepickeydown(count)"
                         @getpage="findwhichpage"
        >   </verticalbanner>

    </template>
    </div>
<!--        <verticalbanner :propsadbinner="gsadpicimformation"  >   </verticalbanner>-->

<!--    store.state.gswebadbannerstore.adbannertitle-->

<!--        <verticalbanner>   </verticalbanner>-->
<!--        <verticalbanner>   </verticalbanner>-->

    <backtop v-show="backtopflag">  </backtop>
  </div>
</template>

<script>

 import swipercar from '@/components/header/swipercarousel.vue';
 import Verticalbanner from "@/components/header/verticalbanner";
 import backtop from "@/components/backtop/backtop";
// ===============================================================
// import bscroll from 'better-scroll'

 import {ref,reactive,onMounted,toRefs,onUnmounted} from "vue";

 import {useStore} from 'vuex';
 import {getadBannerList} from "@/axios/webindex";

 import { useRouter} from "vue-router";

export default {
  name: 'HomeView',

  components: {
    Verticalbanner,
    swipercar,
    backtop,
  },

  setup(props,context) {

    const router = useRouter();
    const store=useStore();

    const gsadpicimformation=reactive({
      producttypetitle:[],
      producttypeurl:[],
      picimgs:[],
      picimgurl:[],
    });

    const gsproductbuffer=reactive( {
      id:[],               //id
      description:[],     //电气描述
      picimgs:    [],      //图片描述
      pdfs:[],             //pdf 描述
      descriptionsel:[],      //产品描述选择
      productmodel:[],       //产品型号
      productname:[],       //产品名称

    } );
    const gsproductimformation=reactive( {
      id:[],               //id
      description:[],     //电气描述
      picimgs:    [],      //图片描述
      pdfs:[],             //pdf 描述
      descriptionsel:[],      //产品描述选择
      productmodel:[],       //产品型号
      productname:[],       //产品名称

    } );
    //获取鼠标点击页面 emit 传过来的页面值
    let currentPage=ref(0);

    // const gsadbannerpicStore=useStore();
    let backtopflag=ref(0);
    //=============================================================

     onMounted(()=>{
       //获取DOM的高度
       // console.log( document.getElementById('home-box').clientHeight);
       //监听滚动屏像素
       window.addEventListener('scroll',()=>{
             // console.log('   ---');
         // 页面滚动距顶部距离
         let scrollTop =
             // window.pageYOffset ||
             document.documentElement.scrollTop ||
             document.body.scrollTop
         // console.log(scrollTop)

           if(scrollTop>380){   //大于380个像素时出现返回顶部按键
             backtopflag.value=true;//backtop enable
           }else {
             backtopflag.value=false;
           }

       }),true
       //===========================================================
       //取出图片和标题
       //加载 adBanner 图片和数据 存入缓存中    //http://www.goldenseal.cn/index.php/index/index/adbanner
       getadBannerList("index.php/index/index/adbanner").then(res=>{
         // console.log(res);
         // console.log(res.data.data.length);
         if(res.data.code==200){
           // console.log('=======================>',res.data.data[0].title);
           // console.log('=======================>',res.data.data[0].product[0].proudctimage);
           store.state.gsproducttypetitlestore.producttypetitle.length=0;

               let row,col;
               for (row=0;row<res.data.data.length;row++)
               {
                 if(res.data.data[row].product.length>0){

                     gsadpicimformation.producttypetitle.push(res.data.data[row].title);    // banner 标题
                     //gsadpicimformation.producttypeurl.push(res.data.data[row][0].url);  // banner url
                   store.state.gsproducttypetitlestore.producttypetitle.push(res.data.data[row].title);

                     // console.log('----------->'+res.data.data[row].length);
                     // console.log('====',res.data.data[row].product.length);
                     // console.log(res.data.data[row].product.length,'======================>');
                     for (col=0;col<res.data.data[row].product.length;col++){
                       gsadpicimformation.picimgs.push(res.data.data[row].product[col].proudctimage);    // banner 图片数据
                       //gsadpicimformation.picimgurl.push(res.data.data[row][col].url);  // banner url
                       // console.log('==============.....>',res.data.data[row].product[col].proudctimage);
                       // console.log(gsadpicimformation.picimgs.length,'---->');
                     }
                   gsadpicimformation.picimgs.push('null');    // banner 分页符
                   gsadpicimformation.picimgurl.push('null');  // banner url
                     //产品信息
                   gsproductbuffer[row]= res.data.data[row].product;
                     // console.log(gsproductbuffer[row],'========>',row);


                 }
                 //  console.log(store.state.gswebbannerstore.bannerimgs);


               }
           // console.log('----------->',(gsproductbuffer[0][0].id));
           // console.log('----------->',(gsproductbuffer[0][0].productname));
           // console.log('----------->',(gsproductbuffer[0].length));
           // console.log('----------->',(gsproductbuffer[1].length));
           // console.log('----------->');
         }

         // console.log(gsadpicimformation.picimgs);
         // console.log(gsadpicimformation.picimgurl);
       }).catch(err=>{
         console.log(err);
       });
       //==============================================================
       // gsadpicimformation
     });
    //-----------------------------------------------------------------



  //------------------------------------------------------------------------

      onMounted(()=>{
      // console.log(data.controlledSwiper);
        // window.addEventListener("scroll", this.handleScroll);
    });

    onUnmounted (()=>{
      // window.removeEventListener('scroll',);
      // document.removeEventListener("scroll", this.handleScroll);
    });
    function findwhichpage(page){
      currentPage.value=page;
      // console.log('page====================================>',page);
    }
    function homepickeydown(index){
      // console.log(index);

      for (let row=0;row<gsproductbuffer[index].length;row++)
      {
        gsproductimformation.description[row]=gsproductbuffer[index][row].productdetails;
        gsproductimformation.picimgs[row]=gsproductbuffer[index][row].proudctimage;
        gsproductimformation.pdfs[row]=gsproductbuffer[index][row].productpdf;
        gsproductimformation.descriptionsel[row]=gsproductbuffer[index][row].descriptiontype;
        gsproductimformation.productmodel[row]=gsproductbuffer[index][row].productmodel;
        gsproductimformation.productname[row]=gsproductbuffer[index][row].productname;
        gsproductimformation.id[row]=gsproductbuffer[index][row].id;
        // console.log(row,'-----------------------');
      }
      window.sessionStorage.setItem('producttype',index+1);

      router.push({
        path:'/productdetails',
        query:{
          item:currentPage.value,
          detail:encodeURIComponent(JSON.stringify(gsproductimformation)),
        }

      });

      // const { href } = router.resolve({
      //   path: '/Webblank',
      //   query: {
      //     article_id: 1,
      //   }
      // });
      // router.push('/Webblank');
      // window.open(href, "_blank");
    }

    //=========================================

    return {
      backtopflag,gsadpicimformation,gsproductimformation,
      homepickeydown,
      findwhichpage,
    };
  }
}

</script>

<style scoped>
.home{
  /*position: absolute;*/
  height: 100%;
  width: 100%;
}
.scrollbox{
  height: 100%;
  width: 100%;

  overflow: hidden;
}

</style>
