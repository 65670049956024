<template>
<h1>Mcu login </h1>
  <h1>Mcu login </h1>
  <h1>Mcu login </h1>
  <h1>Mcu login </h1>
  <h1>Mcu login </h1>
</template>

<script>
export default {
  name: "mculogin"
}
</script>

<style scoped>

</style>
