<template>

  <div class="logincontext">
    <div>
      <vue-particles
          color="#ffffff"
          :particleOpacity="0.6"
          :particlesNumber="40"
          shapeType="circle"
          :particleSize="4"
          linesColor="#ffffff"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.4"
          :linesDistance="180"
          :moveSpeed="3"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push"
          class="cash"
      >
      </vue-particles>
    </div>

    <!--      :size="formSize"-->
    <div class="inputarea" >

      <el-form
          ref="ruleFormRef"
          :model="userinfor"
          :rules="rules"
          label-width="100px"
      >

        <el-row   >
          <el-col  :span="14" :offset="7">
            <!--              <el-form-item  >-->
            <h1 style="color:#ffffff; text-shadow:2px 2px #333333;user-select: none">欢迎登录</h1>

            <!--              </el-form-item>-->
          </el-col>
        </el-row>

        <el-row   >
          <el-col :span="16" :offset="4">
            <el-form-item  class="labeitlcolor"  label="用户名：" prop="username" >
              <el-input class="inputtextcolor"  size="large" :prefix-icon="User"  placeholder="请输入账号" v-model.trim="userinfor.username" />

            </el-form-item>
          </el-col>
        </el-row>
        <!--          <el-icon><User /><el-icon><Lock /></el-icon></el-icon>-->

        <el-row >
          <el-col :span="16" :offset="4">
            <el-form-item class="labeitlcolor" label="密码：" prop="password">
              <el-input   class="inputtextcolor" size="large" :prefix-icon="Lock" placeholder="请输入密码" v-model.trim="userinfor.password"  show-password />
            </el-form-item>
          </el-col>
        </el-row>

        <!--  验证码  @keyup.enter.native="loginkey(ruleFormRef)"  <el-icon><DocumentChecked /></el-icon> :gutter="10"   justify="center"   prop 指定验证规则    "  -->
        <el-row    :gutter=" 10"     >
          <el-col :span="11" :offset="4">
            <el-form-item class="labeitlcolor" label="验证码：" prop="codeword">
              <el-input   class="inputtextcolor" size="large" @blur="inputCodeCheck" :prefix-icon="DocumentChecked" placeholder="请输入验证码" v-model.trim="userinfor.codeword" @keyup.enter.native="loginkey(ruleFormRef)" />
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="0">

            <div class="getgscode" @click="refreshLoginCode()">
              <!--                  <gs-identify :identifyCode="LoginCode" :lineColorMin="0" :lineColorMax="0" :dotColorMax="0"  :contentHeight="32"></gs-identify>-->
              <gs-identify :identifyCode="LoginCode" :contentHeight="38" :contentWidth="108"></gs-identify>
            </div>

          </el-col>
        </el-row>
        <!--  验证码     justify="center" space-between  justify="space-between" -->


        <el-row :gutter=" 100 " justify="center"  >
          <el-form-item>
            <el-col :span="1"  :offset="1"  >
              <div>
                <el-checkbox v-model="remberlogin" label="记住密码" size="large" border />
              </div>
            </el-col>
            <el-col :span="1" :offset="1"  >
              <div>
                <el-button type="primary" @click.enter.native="Findpasswordkey(ruleFormRef)"  size="large">找回记忆</el-button>
              </div>
            </el-col>

            <el-col :span="4" :offset="2"  >
              <div>
                <el-button type="primary" @click.enter.native="loginkey(ruleFormRef)"  size="large">登录</el-button>
              </div>
            </el-col>

          </el-form-item>
        </el-row>

        <!--          <el-row>{{}}</el-row>-->
        <!--          <el-form-item>-->
        <!--            <el-button type="primary" @click="submitForm(ruleFormRef)"-->
        <!--            >登录</el-button>-->
        <!--          </el-form-item>-->

        <!--          <el-button type="sccess" @click="clearkey"  size="large">获取gslongin</el-button>-->
<!--        <el-button type="sccess" @click="savekey"  size="large">存gslongin  MD5 </el-button>-->

      </el-form>
    </div>

  </div>
</template>

<script>

import {ref, reactive, onMounted} from 'vue'
import { userlogin,} from "@/axios/webindex";
import router from "@/router";
import { Check, Close,User,Lock,DocumentChecked } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

import GsIdentify from "@/components/gsIdentify";

import {useStore} from 'vuex';

import moment from "moment";

// import md5 from 'js-md5'


export default {

  name: "webbacklogin",
  components: {
    GsIdentify,
    // User,Search,
  },
  setup(){

    const gsStore=useStore();

    let adjcode=ref(0);
    let remberlogin = ref(false);
    const ruleFormRef = ref();
    // admin 123456    codeword:'',
    let userinfor=reactive({
      username:'jimfore',
      password:'123456',
      codeword:'',
    });
    const rules = reactive({
      username: [
        { required: true, message: 'Please input user name', trigger: 'blur' },
        { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' },
      ],
      password: [
        { required: true, message: 'Please input user password', trigger: 'blur' },
        { min: 6, max: 10, message: 'Length should be 6 to 10', trigger: 'blur' },
      ],
      codeword:[
        { required: true, message: 'Please input Check code ',  trigger: 'blur' },
        { min: 4, max: 4, message: 'Length should be 4  ',      trigger: 'blur' },
      ]
    })


    let LoginCode=ref(0);
    //let LoginCodes=ref("23456789abcdefhjkmnprstuvwxyABCDEFGHJKLMNPQRSTUVWXYZ");  //绘制的随机数
    let LoginCodes=ref("0123456789");  //绘制的随机数
    let daystemp;

    onMounted(()=>{
      refreshLoginCode();

      // window.localStorage.getItem

      // window.sessionStorage.getItem('gslogindaysbefore');
      //一个礼拜没有登录自动清除用户名和密码
      // daystemp=moment().diff(moment(window.sessionStorage.getItem('gslogindaysbefore')));
      // console.log('====================>'+daystemp);
      if(moment().diff(moment(window.localStorage.getItem('gslogindaysbefore')),'days')>'7') // 当前时间和指定时间对比
      {
        // console.log('====================>');
        window.localStorage.removeItem("gsloginremeber");
        window.localStorage.removeItem('gsloginusername');
        window.localStorage.removeItem('gsloginuserpassword');
      }
      remberlogin.value=JSON.parse(window.localStorage.getItem("gsloginremeber"));
      if(remberlogin.value==true){
        userinfor.username=window.localStorage.getItem("gsloginusername");
        userinfor.password=window.localStorage.getItem("gsloginuserpassword");
      }else{
        userinfor.username='';
        userinfor.password='';
      }

    });

    //=============================================================
    function refreshLoginCode(){
      LoginCode.value = "";
      makeCode(LoginCodes.value, 4);
      // console.log(LoginCode.value+'========================================');
    }

    function  randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    };
    // 随机生成验证码字符串
    function makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        LoginCode.value += data[randomNum(0, data.length - 1)]
      }
    }
//==================================================================
    //验证码校验
    function inputCodeCheck(){
      // console.log( LoginCode.value+'input is===>'+userinfor.codeword);
      if(LoginCode.value==userinfor.codeword){
        // adjcode.value=true;
        // console.log(adjcode.value);
      }else
      {
        // adjcode.value=false;
        // ElMessage({
        //   showClose: true,
        //   message: '验证码输入错误',
        //   type: 'error',
        // });
      }
    }
    //=============================================================
    //记住密码处理
    function clearkey(){
      //提示信息清除

      let loginflg= JSON.parse(  window.sessionStorage.getItem('gslogin'));

      // console.log(typeof (loginflg));
      // console.log(loginflg);



      let nowDate=new Date().getDate()-1;
      // let nowDate=new Date();
      let utcDate=moment.utc();

      // let datemoment=moment().startOf('hour').fromNow();
      // let datemoment=moment().startOf('day').fromNow();
      let datemoment=moment().subtract(12, 'days').calendar();
      let backdate=moment().format('YYYY-MM-DD HH:mm:ss');

      // console.log( moment().add(7, "days").format("YYYY-MM-DD 23:59:59"));// 往后推两天
      // datemoment=moment().diff(moment(backdate),'days'); // 当前时间和指定时间对比
      datemoment=moment().diff(moment("2022-06-11 11:27:00"),'hours'); // 当前时间和指定时间按小时 对比

      // console.log(moment().format(' HH:mm:ss'));
      // console.log(date.year);
      // console.log(date.month);
      // console.log(date.day);
      // console.log(backdate);
      // console.log(nowDate);
      // console.log("==>   "+datemoment);
      // let savetimer=window.sessionStorage.getItem('gslogindayssave');
      // console.log(savetimer);
    }
    function savekey(){

      let second=moment.utc().seconds();
      // console.log(second);
// moment转化时间戳
      let time = moment().valueOf();
      // console.log(time);


      // console.log(gsbase64encode(userinfor.username));
      // console.log(gsbase64decode(gsbase64encode(userinfor.username)));
    }
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //找回密码
    function Findpasswordkey(formEl){
      //
      // console.log('============================>');
      window.sessionStorage.setItem('findusername',userinfor.username);//登录用户名进行传值
      // window.sessionStorage.setItem('findpassword',userinfor.password);//登录用户名进行传值
      router.push('/FindPassword');   //跳转到找回密码
    }



    //=====================   登录 按键  ====================================================
    function loginkey(formEl){
      if (!formEl) return;
      //输入数字长度对了规则里的valid返回为true  否则返回为 false
      // ruleFormRef.value.validate(valid=>{
      //     console.log(valid);
      //if (valid) {}
      // })
      //  return;

      formEl.validate(async valid=> {
        if (valid) {

          // if(adjcode.value==false)
          if(LoginCode.value!=userinfor.codeword)
          {
            ElMessage({
              showClose: true,
              message: '验证码输入错误',
              type: 'error',
            });

            refreshLoginCode();adjcode.value=false;
              return;
          }

          //验证成功
          let res= await userlogin(userinfor);
          // userlogin(userinfor).then(res=>{
          //   console.log(res);
          //   console.log(res.data.meta.status);
          // })

          // console.log(res);
          // console.log(res.data.meta.status);
          // console.log(res.data.msg);
          // console.log(res.data.data);
          // if(res.status==200)
          if(res.data.code==200)
          // if(1)
          {
            // console.log('submit!');
            // console.log(res);
            // userinfor.id=res.data.id;
            ElMessage({
              showClose: true,
              message: '登录成功',
              type: 'success',
            });

            setTimeout(() => {
              //代码
            }, 680);

            //登录当天时间记录，7天后清除登录状态
            window.localStorage.setItem('gslogindaysbefore',moment().format('YYYY-MM-DD'));

            //保存登录状态window.sessionStorage.setItem
            window.localStorage.setItem('gstoken',res.data.data.slice(14));//设置指定key的数据（JSON格式）

            // gsStore.dispatch('asyncsetgsuser',{name: userinfor.username});
            // gsStore.dispatch('asyncsetgspassword',{password: userinfor.password})
            // gsStore.state.gsloginuserstore.gsuser.name=userinfor.username;
            // gsStore.state.gsloginuserstore.getters.getgsuser.name;
            // gsStore.state.gsloginuserstore.getters.getgspassword.gspassword;

            window.sessionStorage.setItem('gslogin','true');//设置指定key的数据（JSON格式）
            window.sessionStorage.setItem('username',userinfor.username);//登录用户名进行传值
            //保存用户名和密码到 cookie
            if(remberlogin.value==true){
              // userinfor.password;
              // userinfor.username;
              //保存登录状态window.sessionStorage.setItem
              window.localStorage.setItem('gsloginremeber','true');
              window.localStorage.setItem('gsloginusername',userinfor.username);
              window.localStorage.setItem('gsloginuserpassword',userinfor.password);
            }else
            {
              //清除保存记录
              window.localStorage.removeItem('gsloginremeber');
              window.localStorage.removeItem('gsloginusername');
              window.localStorage.removeItem('gsloginuserpassword');
            }

            // var temp=JSON.parse(window.sessionStorage.getItem("gslogin"));

            // console.log(typeof (temp));
            // console.log(temp);
            //console.log('登录OK');
            router.push('/AdminHome');   //跳转到登录状态
            //保存 token 到 cookie
            //  res.data.meta.token;
            // window.localStorage.setItem(key,value);//设置指定key的数据（JSON格式）
          }else
          {
            //登录失败
            // console.log('submit fail !');
            // alert('submit fail !');
            ElMessage({
              showClose: true,
              message: '登录失败：'+res.data.msg,
              type: 'error',
            });
          }
          //=========================
          // login().then(res=>{
          //
          // })

        } else {
          refreshLoginCode();
          // console.log('error submit!');
          ElMessage({
            showClose: true,
            message: '登录失败',
            type: 'error',
          });
        }
      })
    }
    //=====================   登录 按键  ====================================================
    return {
      remberlogin,userinfor,rules,loginkey,ruleFormRef,inputCodeCheck,
      clearkey,savekey,
      User,Lock,DocumentChecked,
      refreshLoginCode,LoginCode,
      Findpasswordkey,
    }
  }
}
</script>

<style >

.cash{
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #0527af;
}

.labeitlcolor .el-form-item__label {
  font-size: 18px;
  color: #ffffff;
  text-shadow:2px 2px #333333;
  user-select: none
}
.el-checkbox__label {
  color: #ffffff;
}
.inputtextcolor .el-input__inner {
  /*background-color: #333333;*/
  font-size: 28px;
  color: #0527af;
  /*font-weight: bold;*/
}



.inputtextcolor .el-input__inner::placeholder {
  color:  #0527af;
  font-size: 16px;

}
/* 谷歌 */
.inputtextcolor .el-input__inner::-webkit-input-placeholder {
  color:  #0527af;
  font-size: 16px;

}
/* 火狐 */
.inputtextcolor .el-input__inner:-moz-placeholder {
  color:  #0527af;
  font-size: 16px;
}
/*ie*/
.inputtextcolor .el-input__inner:-ms-input-placeholder {
  color:  #0527af;
  font-size: 16px;
}

.el-input .el-input__icon {
  color:  #0527af;


}

.getgscode{
  /*border-radius:150px;*/
  /*background-color: #52E923;*/
}







.logincontext{
  /*align-items: center;*/
}
.inputarea{
  /*background-color: #52E923;*/
  width: 36%;
  /*margin-top: 18%;*/
  /*margin-left: 50%;*/
  /*margin: 14% auto;*/

  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);

  /*flex 布局*/
  /*display: flex;*/
  /*实现垂直居中*/
  /*align-items: center;*/
  /*margin:0 auto;*/
  /*pointer-events: none;*/
}
.submitkey{

}
</style>
