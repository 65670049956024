export const bannerinfotableoptions=[

    // {
    //     label: 'id',
    //     prop : 'id',
    //     width:50,
    // },
    {
        label: '广告栏名称',
        prop : 'bannername',
        width:180,
    },
    {
        label: '广告栏链接',
        prop : 'bannerurl',
        width:500,
    },
    {
        label: '广告图片',
        prop : 'image',
        // width:130,
    },
    {
        label: '图片显示',
        prop : 'status',
        width:88,
    },
    {
        label: '图片操作',
        prop : 'actions',
        // width:130,
    },

]
