<template>
<div  class="bread">
  <el-breadcrumb    :separator-icon="ArrowRightBold" >
<!--    <el-breadcrumb    separator-class="el-icon-arrow-right" >-->
<!--    <el-breadcrumb-item  class="myColor" :to="{ path: '/AdminHome' }">首页</el-breadcrumb-item>-->
<!--    <el-breadcrumb-item class="myColor">公司管理</el-breadcrumb-item>-->
<!--    <el-breadcrumb-item class="myColor">关于公司</el-breadcrumb-item>-->

    <el-breadcrumb-item class="myColor" v-for="(item, index) in fromFather" :key=index :to="{ path: '/' + item.path }">{{item.name}}</el-breadcrumb-item>

  </el-breadcrumb>
</div>
</template>
<!--<el-icon><ArrowRightBold /></el-icon>-->
<script>
import {ArrowRightBold } from "@element-plus/icons-vue";

export default {
  name: "rightmainhead",
  components: {
    ArrowRightBold,
  },
  props: ['fromFather'],

  setup(){

    return{
      ArrowRightBold,
    }
  }
}
</script>

<style scoped >

.myColor >>> .el-breadcrumb__inner{
  color: #0527af;
  /*size: 180px;*/
}
body{
  color: #0527af;
}


.bread {
        background-color: #fff;
        width: 100%;
        height: 40px;
        padding: 0 16px;
        /*border: 1px #EFEFEF solid;*/
        box-sizing: border-box;
        /*box-shadow: 1px 2px 4px #BABABA;*/
        /*margin-bottom: 36px;*/

        user-select: none
  }

.ArrowRightBold:before {
  color: #0527af;
}
.el-breadcrumb__item {
  font-size: 15px;
  color: #0527af;
  /*line-height: 50px;*/
  line-height: 40px;
}
.el-breadcrumb__inner {
  color: #0527af;
  cursor: pointer !important;

}


</style>
